import React from 'react';
import * as S from './TableBody.styled';
import { ImportedData } from './utils';

const TableBody = function TableBody({
  importedData,
} : {
  importedData: ImportedData,
}) {
  const { sheet: s, headers } = importedData;

  const sheet = [...s];

  if (sheet.length > 60) {
    sheet.splice(59, sheet.length - 59);
  }

  return (
    <tbody>
      { sheet.map((entry: any, i: number) => (
        <S.TR key={`tmptablebody${i}`}>
          { headers.map((h, k) => (
            <S.TD key={`tmpsheettd${h}${k}`}>
              <S.TDText>
                {entry[h]}
              </S.TDText>
            </S.TD>
          ))}
        </S.TR>
      ))}
    </tbody>
  );
};

export default TableBody;
