import styled from 'styled-components';
import Color from 'color';
import { topBarColor } from 'pages/styles';
import { lFColor2, mobileRes } from 'pages/components/LFHeader/LFHeader.styled';
import { leftPanelWidth } from '../Layout.styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  width: ${leftPanelWidth};
  box-shadow: 0.5px 0px 1.5px 0px rgba(0,0,0,0.275);
  background: #212137;
  flex: 1;
  z-index: 1000;
  height: 100svh;
  @media(max-width: ${mobileRes}) {
    position: relative;
    top: 0;
    left: 0;
    width: 180px;
  }
`;

export const Cont1 = styled.div`
`;

export const UserProfile = styled.div`
  display: block;
  width: 100%;
  padding: 0px 11px;
`;

export const IconCont1 = styled.div<{ isSelected: boolean }>`
  padding: 17px 15px;
  padding-right: 13px;
  border-radius: 8px;
  position: relative;
  background: ${({ isSelected }) => isSelected ? topBarColor : 'inherit'};
  box-shadow: ${({ isSelected }) => isSelected && `0px 0px 2px 0px ${Color(topBarColor).lighten(0.2).toString()}`};
  margin-top: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  &:hover {
    background: ${({ isSelected }) => !isSelected && Color(lFColor2).lighten(0.4).toString()};
  }
`;

export const IconText = styled.div<{ isShow: boolean }>`
  margin-left: 15px;
  font-size: 11.5px;
  margin-right: 5px;
  display: ${({ isShow }) => isShow ? 'block' : 'none'};
  @media(max-width: ${mobileRes}) {
    display: block;
  }
`;

export const IconText1 = styled(IconText)`
  height: 20px;
`;

export const UserCont = styled.div`
  width: 100%;
`;

export const User = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-top: -7px;
  width: max-content;
`;

export const Role = styled.div`
  font-size: 9px;
  font-weight: 400;
  margin-top: 1px;
  width: max-content;
`;

export const Icons = styled.div`
  padding: 11.5px 12px;
  padding-left: 11px;
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const Icon = styled(IconCont1)`
  padding: 15px;
  padding-right: 17px;
  margin-top: 0;
`;

export const Icons2 = styled.div`
  position: absolute;
  left: 10px;
  bottom: 11px;
  gap: 9px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 22px);
`;

export const Icon2 = styled(Icon)`
  border-radius: 50%;
  width: 52px;
  height: 52px;
`;
