import React, { useState } from 'react';
import Room from '@mui/icons-material/MeetingRoom';
import Settings from '@mui/icons-material/Settings';
import Logs from '@mui/icons-material/Assessment';
import Metrics from '@mui/icons-material/Timeline';
import Walk from '@mui/icons-material/DirectionsWalk';
import Dashboard from '@mui/icons-material/Dashboard';
import Home from '@mui/icons-material/Home';
import * as S from './AdminButtons.styled';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectPermission, selectUsername } from 'redux/global';
import { useHistory, useLocation } from 'react-router-dom';
import { clearMainBG } from 'pages/Navigation/utils';
import Person from '@mui/icons-material/Person';
import { truncate } from 'utils';
import { Roles, rolesMain } from 'pages/Settings/redux';
import LoggedInModal from 'pages/components/LFHeader/modals/LoggedInModal';
import { setModalParts } from 'pages/redux';
import { leftPanelWidth } from '../Layout.styled';
import { useWindowSize } from 'pages/Logs/hooks';
import { mobileRes } from 'pages/components/LFHeader/LFHeader.styled';
import Walkin from '../modals/Walkin';
import BranchesIcon from '@mui/icons-material/AccountTree';
import Branches from 'pages/Logs/modals/Branches';
import Booked from '@mui/icons-material/Bookmark';

const AdminButtons = function AdminButtons() {
  const permission = useAppSelector(selectPermission);
  const history = useHistory();
  const location = useLocation();
  const username = useAppSelector(selectUsername);
  const [isShowIconText, setIsShowIconText] = useState<boolean>(false);
  const roles: Roles[] = [...rolesMain];
  const dispatch = useAppDispatch();
  const { width: wWidth } = useWindowSize();

  if ((permission || 0) === 6) roles.push('Tech team');

  const onEnterFlexBar = () => {
    const flexBar = document.getElementById('flex_bar');
    if (flexBar) {
      flexBar.style.transition = 'all ease-in 100ms';
      flexBar.style.width = '173px';
      setTimeout(() => {
        if (Number(flexBar.style.width?.replace('px', '')) >= 163) {
          setIsShowIconText(true);
        }
      }, 100);
    }
  };

  const onHoverFlexBar = () => {
    const flexBar = document.getElementById('flex_bar');
    if (flexBar) {
      if (flexBar.style.width === leftPanelWidth) {
        onEnterFlexBar();
      }
    }
  };

  const onExitFlexBar = () => {
    const flexBar = document.getElementById('flex_bar');
    if (flexBar && (wWidth || 0) > Number(mobileRes.replace('px', ''))) {
      flexBar.style.transition = 'all ease-out 200ms';
      flexBar.style.width = leftPanelWidth;
      setTimeout(() => {
        setIsShowIconText(false);
      }, 10);
    }
  };

  const onClickWalkIn = () => {
    dispatch(setModalParts({
      component: (
        <Walkin
          onExit={() => dispatch(setModalParts(null))}
        />
      ),
      exitOnBgClick: () => dispatch(setModalParts(null)),
    }));
  };

  const onClickBranches = () => {
    dispatch(setModalParts(null));
    dispatch(setModalParts({
      component: (
        <Branches
          onExit={() => dispatch(setModalParts(null))}
        />
      ),
    }));
  };

  return (
    <S.Container
      id="flex_bar"
      onMouseEnter={() => onEnterFlexBar()}
      onMouseLeave={() => onExitFlexBar()}
      onMouseOver={() => onHoverFlexBar()}
    >
      <S.UserProfile>
        <S.IconCont1
          isSelected={false}
          onClick={() => {
            dispatch(setModalParts({
              component: (
                <LoggedInModal onExit={() => dispatch(setModalParts(null))} />
              ),
              exitOnBgClick: () => dispatch(setModalParts(null)),
            }));
          }}
        >
          <Person fontSize="small" />
          <S.IconText1 isShow={isShowIconText}>
            <S.UserCont>
              <S.User>{truncate((username || ''), 9)}</S.User>
              <S.Role>
                {roles[permission || 0].toUpperCase()}
              </S.Role>
            </S.UserCont>
          </S.IconText1>
        </S.IconCont1>
      </S.UserProfile>
      <S.Icons>
        {
          (permission || 0) >= 4 && (
            <>
              <S.Icon
                isSelected={location.pathname === '/dashboard'}
                onClick={() => {
                  clearMainBG();
                  history.push('/dashboard');
                }}
              >
                <Dashboard fontSize="small" />
                <S.IconText isShow={isShowIconText}>
                  Dashboard
                </S.IconText>
              </S.Icon>
            </>
          )
        }
        {
          (permission || 0) >= 1 && (
            <>
              <S.Icon
                isSelected={location.pathname === '/rooms'}
                onClick={() => {
                  clearMainBG();
                  history.push('/rooms');
                }}
              >
                <Home fontSize="small" />
                <S.IconText isShow={isShowIconText}>
                  Hospitality
                </S.IconText>
              </S.Icon>
              <S.Icon
                isSelected={location.pathname === '/reserve'}
                onClick={() => {
                  clearMainBG();
                  history.push('/reserve');
                }}
              >
                <Room fontSize="small" />
                <S.IconText isShow={isShowIconText}>
                  Book Rooms
                </S.IconText>
              </S.Icon>
              <S.Icon
                isSelected={location.pathname === '/reservations'}
                onClick={() => {
                  clearMainBG();
                  history.push('/reservations');
                }}
              >
                <Booked fontSize="small" />
                <S.IconText isShow={isShowIconText}>
                  Reservations
                </S.IconText>
              </S.Icon>
            </>
          )
        }
        {
          [0, 1, 2, 3, 6].includes(permission || 0) && (
            <>
              <S.Icon
                isSelected={false}
                onClick={() => {
                  clearMainBG();
                  onClickWalkIn();
                }}
              >
                <Walk fontSize="small" />
                <S.IconText isShow={isShowIconText}>
                  Walk-in
                </S.IconText>
              </S.Icon>
              <S.Icon
                isSelected={false}
                onClick={() => onClickBranches()}
              >
                <BranchesIcon fontSize="small" />
                <S.IconText isShow={isShowIconText}>
                  Branches
                </S.IconText>
              </S.Icon>
            </>
          )
        }
        <S.Icon
          isSelected={location.pathname.includes('/logs')}
          onClick={() => {
            clearMainBG();
            history.push('/logs');
          }}
        >
          <Logs fontSize="small" />
          <S.IconText isShow={isShowIconText}>
            Audits
          </S.IconText>
        </S.Icon>
        {
          (permission || 0) >= 4 && (
            <>
              <S.Icon
                isSelected={location.pathname === '/metrics'}
                onClick={() => {
                  clearMainBG();
                  history.push('/metrics');
                }}
              >
                <Metrics fontSize="small" />
                <S.IconText isShow={isShowIconText}>
                  Metrics
                </S.IconText>
              </S.Icon>
            </>
          )
        }
        <S.Icons2>
          {
            (permission || 0) >= 2 && (
              <>
                <S.Icon
                  isSelected={location.pathname.includes('settings')}
                  onClick={() => {
                    clearMainBG();
                    history.push('/settings');
                  }}
                >
                  <Settings fontSize="medium" sx={{ scale: '0.9' }} />
                  <S.IconText isShow={isShowIconText}>
                    Settings
                  </S.IconText>
                </S.Icon>
              </>
            )
          }
        </S.Icons2>
      </S.Icons>
    </S.Container>
  );
};

export default React.memo(AdminButtons);
