import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IResponse } from 'types';
import { callEndpoint } from 'redux/global';
import { RootState } from 'redux/store';

export interface HomepageStatey {
  isStaff: boolean,
  isFirstLoad: boolean,
}

const initialState: HomepageStatey = {
  isStaff: Boolean(localStorage.getItem('isStaff')),
  isFirstLoad: true,
};

export const counterSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    setIsStaff: (state, action: PayloadAction<boolean>) => {
      state.isStaff = action.payload;
      if (action.payload) {
        localStorage.setItem('isStaff', 'Yes');
      } else {
        localStorage.removeItem('isStaff');
      }
    },
    setIsFirstLoad: (state, action: PayloadAction<boolean>) => {
      state.isFirstLoad = action.payload;
    },
  },
});

export const { setIsStaff, setIsFirstLoad } = counterSlice.actions;

export const selectIsStaff = (state: RootState) => state.homepage.isStaff;
export const selectIsFirstLoad = (state: RootState) => state.homepage.isFirstLoad;

export default counterSlice.reducer;

type LogInDetails = {
  email: string,
  password: string
}

export const getLogInBranches = (logInDetails: LogInDetails) => async (dispatch: Function)
  : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'getloginbranches',
      method: 'POST',
      body: { logInDetails }
    }),
  );
  return res;
};
