import React, { useEffect, useState } from 'react';
import * as S from './Branch.styled';
import Close from '@mui/icons-material/Close';
import Bin from '@mui/icons-material/DeleteOutline';
import More from '@mui/icons-material/MoreVert';
import Money from '@mui/icons-material/AttachMoney';
import File from '@mui/icons-material/UploadFile';
import ImportTypes from './ImportTypes/ImportTypes';
import { ModalParts, selectHotelData } from 'pages/redux';
import Person from '@mui/icons-material/Person';
import { useAppSelector } from 'redux/hooks';
import { selectPermission } from 'redux/global';
import { selectBranchFiles } from '../redux';

const Branch = (
  {
    isShow,
    selectedBranch,
    isMoreClosed,
    setIsMoreClosed,
    setIsShowDeleteBranch,
    setSelectedBranch,
    setIsSetDirectAmount,
    setShowPopup,
    setIsLoading,
    setIsShowSelectStaff,
    setIsShowTablesAdded,
  }:{
    isShow: boolean,
    selectedBranch: string,
    isMoreClosed: boolean,
    setIsMoreClosed: Function,
    setIsShowDeleteBranch: Function,
    setSelectedBranch: Function,
    setIsSetDirectAmount: Function,
    // eslint-disable-next-line no-unused-vars
    setShowPopup: (popup: ModalParts | null) => void,
    setIsLoading: Function,
    setIsShowSelectStaff: Function,
    setIsShowTablesAdded: Function,
  }
) => {
  const [isShowActions, setIsShowActions] = useState<boolean>(false);
  const permission = useAppSelector(selectPermission);
  const hotelData = useAppSelector(selectHotelData);
  const { branches } = hotelData || {};
  const branchFiles = useAppSelector(selectBranchFiles);

  let removeAuditTimeout: any;

  const onClickAddAudit = () => {
    const newAuditButton = document.getElementById('newAuditButton');
    if (newAuditButton) {
      if (isShowActions) {
        newAuditButton.style.display = 'flex';
        newAuditButton.style.opacity = '1';
      } else {
        newAuditButton.style.opacity = '0';
        removeAuditTimeout = setTimeout(() => {
          newAuditButton.style.display = 'none';
          setIsShowActions(true);
        }, 251);
      }
    }
  };

  useEffect(() => {
    const branchActions = document.getElementById('branchActions');
    if (branchActions) {
      if (isMoreClosed) {
        branchActions.style.visibility = 'hidden';
        branchActions.style.width = '0px';
      } else {
        branchActions.style.visibility = 'visible';
        branchActions.style.width = '80px';
      }
    }
  }, [isMoreClosed]);

  useEffect(() => {
    setIsShowActions(false);
    setIsShowSelectStaff(false);
    const newAuditButton = document.getElementById('newAuditButton');
    if (newAuditButton) {
      clearTimeout(removeAuditTimeout);
      newAuditButton.style.display = 'flex';
      newAuditButton.style.opacity = '1';
    }
  }, [selectedBranch]);

  const staffInThisBranch = branches?.find((b) => b.name === selectedBranch)?.staff.length || 0;
  const filesInThisBranch = Object.keys(branchFiles?.[selectedBranch] || {}).length;

  return (
    <S.Container isShow={!!selectedBranch && isShow}>
      <S.HeaderPart>
        <S.Header>
          {selectedBranch}
          <S.IconCont0 onClick={() => setIsMoreClosed(!isMoreClosed)}>
            <More fontSize="medium" sx={{ scale: '0.7' }} />
          </S.IconCont0>
          <S.CollapseCont id="branchActions">
            <S.IconCont1 onClick={() => setIsShowDeleteBranch(true)}>
              <Bin fontSize="small" sx={{ scale: '0.9' }} />
            </S.IconCont1>
          </S.CollapseCont>
        </S.Header>
        <S.IconCont2 onClick={() => setSelectedBranch('')}>
          <Close fontSize="medium" sx={{ scale: '0.8' }} />
        </S.IconCont2>
      </S.HeaderPart>
      <S.InfoPart>
        <S.Info onClick={() => setIsShowTablesAdded(true)}>
          {filesInThisBranch}
          {' added tables'}
        </S.Info>
        <S.Hyphen>|</S.Hyphen>
        <S.Info onClick={() => setIsShowSelectStaff(true)}>
          {staffInThisBranch}
          {' added users'}
        </S.Info>
      </S.InfoPart>
      {
        isShowActions ? (
          <S.ActionsCont>
            <S.Actions onClick={() => setIsSetDirectAmount('yes')}>
              <Money fontSize="small" />
              Direct
            </S.Actions>
            <S.Actions
              onClick={() => {
                setShowPopup({
                  component: (
                    <ImportTypes
                      onExit={() => setShowPopup(null)}
                      setIsBranchesLoading={setIsLoading}
                      selectedBranch={selectedBranch}
                    />
                  )
                });
              }}
            >
              <File
                fontSize="small"
                sx={{ scale: '0.85' }}
              />
              Table
            </S.Actions>
            {
              (permission || 0) >= 3 ? (
                <S.Actions onClick={() => setIsShowSelectStaff(true)}>
                  <Person fontSize="small" />
                  Users
                </S.Actions>
              ) : (null)
            }
          </S.ActionsCont>
        ) : (null)
      }
      <S.Button id="newAuditButton" onClick={() => onClickAddAudit()}>
        New Audit
      </S.Button>
    </S.Container>
  );
};

export default Branch;
