import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  Rate,
  DateRestriction,
  getInfo,
  selectGroups,
  selectRates,
  selectRoomTypes,
  selectUsers,
  setGroups, setRates, setRoomTypes, setUsers, verifyPayment,
  RoomType,
  AvailabilityCO,
  Services,
  setServices,
  defaultServices,
  selectServices,
  selectSettingsParts,
  setSettingsParts,
} from './redux';
import { closePaymentModal, useFlutterwave } from 'pages/components/Flutterwave';
import { FlutterwaveConfig } from 'pages/components/Flutterwave/types';
import { selectHotelData } from 'pages/redux';
import { selectUserEmail } from 'pages/Hospitality/redux';
import { getTaxRef } from 'utils';
import Bed from '@mui/icons-material/Hotel';
import House from '@mui/icons-material/Apartment';
import Balcony from '@mui/icons-material/Balcony';
import Accessible from '@mui/icons-material/Accessible';
import AcUnit from '@mui/icons-material/AcUnit';
import Fridge from '@mui/icons-material/Kitchen';
import Family from '@mui/icons-material/FamilyRestroom';
import Tv from '@mui/icons-material/Tv';
import { toast } from 'react-toastify';
import Group from '@mui/icons-material/SwapHoriz';
import { useRooms, useRoomsData } from 'pages/Hospitality/hooks';
import { BookingDetails } from 'types';

export const groupMax = 6;

export const useGroupOptions = () => {
  const groups = useAppSelector(selectGroups);

  const setPerksCall = (perk: string, perks: string[], setPerks: Function) => {
    if (perks.includes(perk)) {
      setPerks(perks.filter((p) => p !== perk));
      return;
    }
    if (perks.length === groupMax) {
      toast(`you can only set ${groupMax} groups`, { type: 'error' });
    } else {
      const newPerks = [...perks];
      newPerks.push(perk);
      setPerks(newPerks);
    }
  };

  const groupOptions0 = [
    {
      name: '2 beds',
      icon: <Bed fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Large bed',
      icon: <Bed fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Split beds',
      icon: <Bed fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: '1 bed',
      icon: <Bed fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Balcony',
      icon: <Balcony fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Large room',
      icon: <House fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Accessible',
      icon: <Accessible fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'A/C unit',
      icon: <AcUnit fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Big TV',
      icon: <Tv fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Fridge',
      icon: <Fridge fontSize="small" />,
      onClick: setPerksCall
    },
    {
      name: 'Family OK',
      icon: <Family fontSize="small" />,
      onClick: setPerksCall
    },
  ];

  const groupOptions = [
    ...groupOptions0,
    ...Array.from(new Set(groups)).map((g) => {
      return {
        name: g,
        icon: <Group fontSize="small" />,
        onClick: setPerksCall
      };
    })
  ];

  return { groupOptions, groups };
};

export const useUpdateSettings = () => {
  const settingsParts = useAppSelector(selectSettingsParts);
  const dispatch = useAppDispatch();

  const updateLocalSettings = (part: Record<string, any>) => {
    dispatch(setSettingsParts({ ...settingsParts, ...part }));
  };

  return { updateLocalSettings };
};

export const useSettings = () => {
  const users = useAppSelector(selectUsers);
  const groups = useAppSelector(selectGroups);
  const roomTypes = useAppSelector(selectRoomTypes);
  const hotelData = useAppSelector(selectHotelData);
  const rates = useAppSelector(selectRates);
  const services = useAppSelector(selectServices);
  const { rooms }: any = useRooms();
  const { getAllBooks, getCurrentBooking } = useRoomsData();

  const getRate = (ratePlanId: string, dt?: Date, ratePlan?: Rate) => {
    const date = dt || new Date();
    const thisRatePlan = ratePlan || rates.find((r) => r.id === ratePlanId);

    if (!thisRatePlan) return 0;

    const calendarRate = Number(thisRatePlan?.dates.find(
      (d) => {
        const endD = new Date(d.end);
        endD.setFullYear(date.getFullYear());
        const startD = new Date(d.start);
        startD.setFullYear(date.getFullYear());

        return +date >= +startD && +date <= +endD;
      }
    )?.rate || 0);
    const weekendRate = [0, 6].includes(date.getDay()) ? Number(thisRatePlan.weekendRate)
      : 0;
    return calendarRate || weekendRate || Number(thisRatePlan.baseRate || 0);
  };

  const getMealNow = () => {
    let breakfast = '';
    let lunch = '';
    let dinner = '';
    if (+new Date().getHours() >= 7 && +new Date().getHours() <= 11) breakfast = 'Breakfast';
    if (+new Date().getHours() >= 12 && +new Date().getHours() <= 16) lunch = 'Lunch';
    if (+new Date().getHours() >= 17 && +new Date().getHours() <= 23) dinner = 'Dinner';

    return dinner || lunch || breakfast;
  };

  const getIsMealServedBefore = (roomId: string) => {
    const book = getCurrentBooking(roomId);

    const startOfTommorow = new Date();
    startOfTommorow.setDate(startOfTommorow.getDate() + 1);
    startOfTommorow.setHours(0, 0, 0, 0);

    const isBookingEndToday = +new Date(book?.endDate || 0) < +startOfTommorow;
    const isMealServedBefore = services.meals.priorIds.includes(`${roomId}${getMealNow().slice(0, 1)}`)
      && isBookingEndToday;

    return isMealServedBefore;
  };

  const getIsMealTime = (roomId: string) => {
    return getCurrentBooking(roomId)?.ratePlan?.mealType.includes(getMealNow() as any)
      && !services.meals.ids.includes(`${roomId}${getMealNow().slice(0, 1)}`)
      && !getIsMealServedBefore(roomId);
  };

  const dateInfoLength = 501;

  const getDateRestrictions = (ratePlan: Rate | undefined) => {
    const dateChanges = [...(ratePlan?.dates || [])];

    type RestrictionBuild = {
      date: Date,
      rate: number,
      isCalendarDate?: boolean
    }
    const restrictionBuilds: RestrictionBuild[] = [];
    let finalBuild: RestrictionBuild | null = null;

    for (let i = 0; i < dateInfoLength; i += 1) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      const calendarDate = dateChanges.find((d) => {
        const startD = new Date(d.start);
        startD.setFullYear(date.getFullYear());
        const endD = new Date(d.end);
        endD.setFullYear(date.getFullYear());
        return (+date >= +startD && +date <= +endD);
      });

      let startD = new Date(calendarDate?.start || 0);
      startD.setFullYear(date.getFullYear());
      if (+startD < +new Date()) startD = new Date();

      const thisRate = calendarDate ? +calendarDate.rate : +(ratePlan?.baseRate || 0);
      const thisDate = calendarDate ? new Date(startD) : new Date(date);

      if (restrictionBuilds.length === 0 ||
        restrictionBuilds[restrictionBuilds.length - 1].rate !== thisRate) {
        restrictionBuilds.push({
          date: new Date(thisDate),
          rate: thisRate,
          isCalendarDate: !!calendarDate
        });
      } else if (i === dateInfoLength - 1) {
        finalBuild = { date, rate: thisRate };
      }
    }
    if (finalBuild) {
      restrictionBuilds.push(finalBuild);
    }

    const restrictions: DateRestriction[] = [];

    if (ratePlan?.weekendRate) {
      const date1 = new Date();
      date1.setHours(0, 0, 0, 0);
      const dateEnd = new Date();
      dateEnd.setDate(dateEnd.getDate() + dateInfoLength);
      restrictions.push({
        start: date1,
        end: dateEnd,
        days: ['su', 'sa'],
        rate: +ratePlan.weekendRate
      });
    }

    restrictionBuilds.forEach((b, i) => {
      if (restrictionBuilds[i + 1]) {
        const nextBuild = restrictionBuilds[i + 1];
        let dayBeforeNextBuildDate = new Date(nextBuild.date);
        dayBeforeNextBuildDate.setDate(dayBeforeNextBuildDate.getDate() - 1);
        if (+dayBeforeNextBuildDate <= +b.date) {
          dayBeforeNextBuildDate = new Date(b.date);
        }
        dayBeforeNextBuildDate.setHours(23, 59, 59, 999);
        const thisStart = new Date(b.date);
        thisStart.setHours(0, 0, 0, 0);
        restrictions.push({
          start: thisStart,
          end: dayBeforeNextBuildDate,
          ...(!b.isCalendarDate ? { days: ['mo', 'tu', 'we', 'th', 'fr'] } : {}),
          rate: b.rate
        });
      } else {
        const thisStart = new Date(b.date);
        thisStart.setHours(0, 0, 0, 0);
        const thisEnd = new Date(+b.date + 1);
        thisEnd.setHours(23, 59, 59, 999);
        restrictions.push({
          start: thisStart,
          end: thisEnd,
          ...(!b.isCalendarDate ? { days: ['mo', 'tu', 'we', 'th', 'fr'] } : {}),
          rate: b.rate
        });
      }
    });
    return restrictions;
  };

  const getRestrictionsDiff = (
    oldRatePlan: Rate,
    newRatePlan: Rate
  ) => {
    const oldRestrictions = getDateRestrictions(oldRatePlan).reverse();
    const newRestrictions = getDateRestrictions(newRatePlan).reverse();

    type RestrictionBuild = {
      date: Date,
      rate: number,
    }
    const restrictionBuilds: RestrictionBuild[] = [];

    for (let i = 0; i <= dateInfoLength; i += 1) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      const earlyDate = new Date(date);
      earlyDate.setHours(0, 0, 0, 0);
      const lateDate = new Date(date);
      lateDate.setHours(23, 59, 59, 999);
      const day = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'][date.getDay()];
      const oldRestr = oldRestrictions.find((oldRestriction) => {
        return +lateDate >= +oldRestriction.start && +earlyDate <= +oldRestriction.end && (
          !oldRestriction.days || oldRestriction.days.includes(day as any));
      });

      const newRestr = newRestrictions.find((newRestriction) => {
        return +lateDate >= +newRestriction.start && +earlyDate <= +newRestriction.end && (
          !newRestriction.days || newRestriction.days.includes(day as any));
      });

      const thisRestr = newRestr?.rate === oldRestr?.rate ? undefined : newRestr;

      if (thisRestr) {
        restrictionBuilds.push({
          date,
          rate: thisRestr.rate,
        });
      }
    }

    const restrictions: DateRestriction[] = [];
    let startD: Date;

    restrictionBuilds.forEach((b, i) => {
      if (i === 0) {
        startD = new Date(b.date);
        if (restrictionBuilds.length === 1) {
          restrictions.push({
            start: startD,
            end: startD,
            rate: b.rate
          });
        }
      } else {
        const lastBuild = restrictionBuilds[i - 1];
        const lastBuildD = new Date(lastBuild.date);
        lastBuildD.setHours(0, 0, 0, 0);
        const thisBuildD = new Date(b.date);
        thisBuildD.setHours(0, 0, 0, 0);
        if (+lastBuildD !== +thisBuildD - (24 * 60 * 60 * 1000) || b.rate !== lastBuild.rate) {
          restrictions.push({
            start: startD,
            end: new Date(lastBuild.date),
            rate: lastBuild.rate
          });
          startD = new Date(b.date);
        }
        if (i === restrictionBuilds.length - 1) {
          restrictions.push({
            start: startD,
            end: new Date(b.date),
            rate: b.rate
          });
        }
      }
    });

    // console.log(restrictionBuilds, oldRestrictions, newRestrictions)
    return restrictions;
  };

  const getIsAvailabilityError = (
    roomId: string,
    date: Date,
    // eslint-disable-next-line no-unused-vars
    getBooks?: (b: BookingDetails[]) => BookingDetails[],
    addBooks?: BookingDetails[]
  ) => {
    const books00 = getAllBooks(roomId.toString());
    const books0 = getBooks?.(books00) || books00;
    const books = [...books0, ...(addBooks || [])]
      .sort((a, b) => +new Date(a.startDate) - +new Date(b.startDate));
    const dStart = new Date(date);
    dStart.setHours(23, 59, 59, 999);
    const dEnd = new Date(date);
    dEnd.setHours(0, 0, 0, 0);

    let isAvailabilityError: boolean | string = false;

    for (let i = 0; i < books.length; i += 1) {
      const book = books[i];
      const bookStart = new Date(book.startDate);
      const bookEnd = new Date(book.endDate);
      if (+dStart >= +bookStart && +dEnd <= +bookEnd) {
        isAvailabilityError = true;
        break;
      }
    }

    return isAvailabilityError;
  };

  const getFullAvailability = (
    roomType: RoomType | undefined,
    addRoomCount?: number,
    removeRoomId?: string,
    addBooks?: BookingDetails[],
    // eslint-disable-next-line no-unused-vars
    getBooks?: (b: BookingDetails[]) => BookingDetails[],
    isRemoveBooks?: boolean
  ) => {
    const availabilityCO: AvailabilityCO[] = [];
    type AvailabilityCOBuild = {
      date: Date,
      availability: number
    }
    const availabilityBuild: AvailabilityCOBuild[] = [];
    const thisRooms = [...(rooms || [])].filter((r) => r.roomTypeId === roomType?.id
      && r.id !== removeRoomId && !r.onHold);

    for (let i = 0; i < dateInfoLength; i += 1) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      if (i > 0) date.setHours(0, 0, 0, 0);
      let availability = addRoomCount || 0;
      thisRooms.forEach((r) => {
        const addBooks1 = addBooks?.filter((book) => book.roomId === r.id);
        if (!getIsAvailabilityError(
          r.id,
          date,
          getBooks,
          isRemoveBooks ? [] : addBooks1
        )) {
          availability += 1;
        }
      });
      if (i === 0 || availabilityBuild[availabilityBuild.length - 1].availability !== availability
        || i === dateInfoLength - 1) {
        availabilityBuild.push({
          date,
          availability
        });
      }
    }
    availabilityBuild.forEach((b, i) => {
      if (availabilityBuild[i + 1]) {
        const nextBuild = availabilityBuild[i + 1];
        let dayBeforeNextBuildDate = new Date(nextBuild.date);
        dayBeforeNextBuildDate.setDate(dayBeforeNextBuildDate.getDate() - 1);
        if (+dayBeforeNextBuildDate <= +b.date) {
          dayBeforeNextBuildDate = new Date(+b.date + 1);
        }
        availabilityCO.push({
          date_from: new Date(b.date).toISOString(),
          date_to: dayBeforeNextBuildDate.toISOString(),
          property_id: hotelData?.coId || '',
          room_type_id: roomType?.coRoomTypeId || '',
          availability: b.availability
        });
      } else {
        availabilityCO.push({
          date_from: new Date(b.date).toISOString(),
          date_to: new Date(+b.date + 1).toISOString(),
          property_id: hotelData?.coId || '',
          room_type_id: roomType?.coRoomTypeId || '',
          availability: b.availability
        });
      }
    });
    return availabilityCO;
  };

  const getAvailability = (
    roomType: RoomType | undefined,
    addBooks: BookingDetails[],
    // eslint-disable-next-line no-unused-vars
    getBooks?: (b: BookingDetails[]) => BookingDetails[],
    isRemoveBooks?: boolean
  ) => {
    type AvailabilityCOBuild = {
      start: Date,
      end: Date,
      availability: number,
    }

    const u = undefined;
    const oldAvailabilities: AvailabilityCOBuild[] = getFullAvailability(roomType).map((b) => {
      return {
        start: new Date(b.date_from),
        end: new Date(b.date_to),
        availability: b.availability,
      };
    });
    const newAvailabilities: AvailabilityCOBuild[] =
      getFullAvailability(roomType, u, u, addBooks, getBooks, isRemoveBooks).map((b) => {
        return {
          start: new Date(b.date_from),
          end: new Date(b.date_to),
          availability: b.availability,
        };
      });

    type AvailabilityBuild = {
      date: Date,
      availability: number,
    }
    const availabilityBuilds: AvailabilityBuild[] = [];

    for (let i = 0; i <= dateInfoLength; i += 1) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      const earlyDate = new Date(date);
      earlyDate.setHours(0, 0, 0, 0);
      const lateDate = new Date(date);
      lateDate.setHours(23, 59, 59, 999);
      const oldAvail = oldAvailabilities.find((oldAvailability) => {
        return +lateDate >= +oldAvailability.start && +earlyDate <= +oldAvailability.end;
      });

      const newAvail = newAvailabilities.find((newAvailability) => {
        // if (date.getDate() >= 27 && date.getDate() <= 31 && date.getMonth() === 4
        // && date.getFullYear() === 2024) {
        //   console.log(date, newAvailability.start, newAvailability.end,
        //     +lateDate >= +newAvailability.start && +earlyDate <= +newAvailability.end
        //   )
        // }
        return +lateDate >= +newAvailability.start && +earlyDate <= +newAvailability.end;
      });

      const thisAvail = newAvail?.availability === oldAvail?.availability ? undefined : newAvail;

      if (thisAvail) {
        availabilityBuilds.push({
          date,
          availability: thisAvail.availability,
        });
      }
    }

    let startD: Date;

    const availabilityCO: AvailabilityCO[] = [];

    availabilityBuilds.forEach((b, i) => {
      if (i === 0) {
        startD = new Date(b.date);
        if (availabilityBuilds.length === 1) {
          availabilityCO.push({
            date_from: new Date(startD).toISOString(),
            date_to: new Date(startD).toISOString(),
            property_id: hotelData?.coId || '',
            room_type_id: roomType?.coRoomTypeId || '',
            availability: b.availability
          });
        }
      } else {
        const lastBuild = availabilityBuilds[i - 1];
        const lastBuildD = new Date(lastBuild.date);
        lastBuildD.setHours(0, 0, 0, 0);
        const thisBuildD = new Date(b.date);
        thisBuildD.setHours(0, 0, 0, 0);
        if (+lastBuildD !== +thisBuildD - (24 * 60 * 60 * 1000)
          || b.availability !== lastBuild.availability) {
          availabilityCO.push({
            date_from: new Date(startD).toISOString(),
            date_to: new Date(lastBuild.date).toISOString(),
            property_id: hotelData?.coId || '',
            room_type_id: roomType?.coRoomTypeId || '',
            availability: lastBuild.availability
          });
          startD = new Date(b.date);
        }
        if (i === availabilityBuilds.length - 1) {
          availabilityCO.push({
            date_from: new Date(startD).toISOString(),
            date_to: new Date(b.date).toISOString(),
            property_id: hotelData?.coId || '',
            room_type_id: roomType?.coRoomTypeId || '',
            availability: b.availability
          });
        }
      }
    });

    // console.log(availabilityBuilds, oldAvailabilities, newAvailabilities);
    return availabilityCO;
  };

  return {
    users,
    roomTypes,
    groups,
    rates,
    services,
    getMealNow,
    getIsMealServedBefore,
    getIsMealTime,
    getRate,
    getDateRestrictions,
    getFullAvailability,
    getAvailability,
    getRestrictionsDiff
  };
};

export const useFetchSettings = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasFetchedUsers, setHasFetchedUsers] = useState(false);
  const hotelData = useAppSelector(selectHotelData);
  const users = useAppSelector(selectUsers);
  const services = useAppSelector(selectServices);
  const dispatch = useAppDispatch();

  const refreshServices = () => {
    const now = new Date();
    const thisMidnight = new Date(now);
    thisMidnight.setHours(23, 59, 59, 999);
    const earlyToday = new Date();
    earlyToday.setHours(0, 0, 0, 0);

    const timeTillMidnight = +thisMidnight - +now;

    setTimeout(() => {
      dispatch(setServices({
        meals: {
          date: earlyToday,
          ids: [],
          priorIds: services.meals.ids
        }
      }));
      setTimeout(() => refreshServices(), 10);
    }, timeTillMidnight || 1);
  };

  useEffect(() => {
    const f = async () => {
      setHasFetchedUsers(true);
      setIsLoading(true);
      const res = await dispatch(getInfo());
      setIsLoading(false);
      if (res.status === 'success') {
        dispatch(setUsers(res.data.users));
        setHasFetchedUsers(false);
        dispatch(setGroups(res.data.groups));
        dispatch(setRoomTypes(res.data.roomTypes));
        dispatch(setRates(res.data.rates));
        const dbServives = res.data.services as Services;
        if (+dbServives.meals.date < +new Date()) {
          dispatch(setServices(defaultServices));
        } else {
          dispatch(setServices(dbServives));
        }
        refreshServices();
        return;
      }
      toast(res.data, { type: 'error' });
    };

    if (!users && hotelData && !hasFetchedUsers) f();
  }, [hotelData]);

  return { isLoading };
};

type PaymentDetails = {
  flutterTitle: string,
  flutterDesc: string,
  paymentDescription: string,
  totalCost: number,
}
export const usePayment = (
  {
    finalFunction,
    onExit,
    paymentDetails,
  } : {
    // eslint-disable-next-line no-unused-vars
    finalFunction: (closePayment: () => void) => void,
    onExit: Function,
    paymentDetails: PaymentDetails,
  }
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleFlutterPayment = useFlutterwave();
  const dispatch = useAppDispatch();
  const hotelData = useAppSelector(selectHotelData);
  const userEmail = useAppSelector(selectUserEmail);
  const {
    totalCost, flutterTitle: title, flutterDesc: description, paymentDescription
  } = paymentDetails;

  const flutterPayment = async (config: FlutterwaveConfig) => {
    handleFlutterPayment({
      flutterWaveConfig: config,
      callback: async (resp: Record<string, any>) => {
        const res0 = await dispatch(verifyPayment(resp));
        if (res0.status === 'success' && res0.data === 'pass') {
          finalFunction(() => closePaymentModal());
        } else {
          toast(`Something went wrong. ERROR: ${res0.status}`, { type: 'error' });
          closePaymentModal(); // this will close the modal programmatically
        }
        setIsLoading(false);
      },
      onClose: () => {
        setIsLoading(false);
        onExit();
      },
    });
  };

  const beginPayment = () => {
    setIsLoading(true);
    const flutterConfig = {
      public_key: process.env.REACT_APP_FW_PUBLIC_KEY || '',
      tx_ref: getTaxRef(
        `${hotelData?.id || 'Error'}_!${title}!_DETAILS:${paymentDescription}`,
        userEmail,
        `N${totalCost.toLocaleString()}`
      ),
      amount: totalCost,
      currency: 'NGN',
      payment_options: 'card',
      customer: {
        email: userEmail,
      },
      // redirect_url: `${process.env.REACT_APP_API}postpayment?amount=${totalCost}`,
      meta: { counsumer_id: '7898', consumer_mac: 'kjs9s8ss7dd' },
      customizations: {
        title,
        description,
        logo: 'https://lodgefirst.com/xyder/lodge.png'
        // logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
      },
    };
    flutterPayment(flutterConfig);
  };
  return { isLoading, beginPayment };
};
