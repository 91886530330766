import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getBranchFiles,
  getLogs,
  selectBranchFiles,
  selectIsLoadingLogs,
  selectLogs,
  setBranchFiles, setIsLoadingLogs, setLogs, setLogsChecked
} from './redux';
import { toast } from 'react-toastify';
import { Branch, selectHotelData, setHotelData } from 'pages/redux';
import {
  LogGroups, LogKey, LogType, logGroupsRaw
} from './Logs';
import { selectToken } from 'redux/global';
import { usePath } from 'pages/hooks';
import { useLocation } from 'react-router-dom';

export type Log = {
  id: string,
  type: string,
  message: string,
  date: string,
  value: string,
  updatedAsOf: string,
  isDelete?: string,
};

export const oneYearAgo = new Date();
oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

export const useLogs = () => {
  const logs = useAppSelector(selectLogs);
  const isLoadingLogs = useAppSelector(selectIsLoadingLogs);
  const branchFiles = useAppSelector(selectBranchFiles);
  const hotelData = useAppSelector(selectHotelData);
  const { branches } = hotelData || {};
  const logGroups: LogGroups = { ...logGroupsRaw };
  const addBranches = branches?.map((b) => b.name);
  logGroups.Branches = [...(addBranches || []), ...logGroups.Branches];
  const dispatch = useAppDispatch();

  const getLogsFromBranchFiles = () => {
    const addLogs: Log[] = [];

    logGroups.Branches?.forEach((b) => {
      if (branchFiles?.[b]) {
        Object.entries(branchFiles[b]).forEach(([, sheet]) => {
          sheet.filter((s) => +new Date(s.date) >= +oneYearAgo).forEach((l) => {
            const newLog: Log = {
              id: `F_${b}_${l.sheetName}_${l.id}`,
              type: b,
              message: `&${l.message}& File entry by |${l.updatedBy}|`,
              date: l.date,
              value: l.value.toString(),
              updatedAsOf: l.updatedAsOf
            };
            addLogs.push(newLog);
          });
        });
      }
    });
    return addLogs;
  };

  const fetchLogs = async () => {
    dispatch(setIsLoadingLogs(true));

    const res = await dispatch(getLogs());

    if (res.status === 'success') {
      dispatch(setLogs([...res.data, ...getLogsFromBranchFiles()]));
      dispatch(setIsLoadingLogs(false));
      return;
    }
    toast(res.data, { type: 'error' });
    dispatch(setIsLoadingLogs(false));
  };

  const fetchBranchFiles = async (noToast?: boolean) => {
    dispatch(setIsLoadingLogs(true));

    const res = await dispatch(getBranchFiles());

    if (res.status === 'success') {
      dispatch(setBranchFiles(JSON.parse(res.data.branchFiles)));
      return;
    }

    dispatch(setIsLoadingLogs(false));
    if (!noToast) toast(res.data, { type: 'error' });
  };

  const deleteLocalLog = (id: string | number) => {
    const newLogs = (logs || []).filter((l) => l.id.toString() !== id.toString());
    dispatch(setLogs(newLogs));
  };

  const deleteLocalLogType = (type: string) => {
    const newLogs = (logs || []).filter((l) => l.type !== type);
    dispatch(setLogs([...newLogs]));
  };

  const updateLocalLog = (log: Log) => {
    const newLogs = [...(logs || []).filter((l: Log) => l.id.toString() !== log.id.toString()),
      log];
    dispatch(setLogs([...newLogs]));
  };

  const updateLocalBranch = (branch: Branch) => {
    const newBranches = [...(branches || []).filter(
      (b) => b.name !== branch?.name
    ), branch];
    if (hotelData) {
      dispatch(setHotelData({ ...hotelData, branches: newBranches }));
    }
  };

  const deleteLocalBranch = (branch: string) => {
    const newBranches = [...(branches || []).filter(
      (b) => b.name !== branch
    )];
    if (hotelData) {
      dispatch(setHotelData({ ...hotelData, branches: newBranches }));
    }
  };

  const deleteLocalBranchFile = (branch: string, sheetName: string) => {
    const newBranchFiles = { ...branchFiles?.[branch] || {} };
    delete newBranchFiles[sheetName];
    dispatch(setBranchFiles({ ...branchFiles, [branch]: newBranchFiles }));
  };

  const deleteLocalBranchFiles = (branch: string) => {
    const newBranchFiles = { ...branchFiles };
    delete newBranchFiles[branch];
    dispatch(setBranchFiles({ ...newBranchFiles }));
  };

  return {
    isLoadingLogs,
    logs,
    logGroups,
    deleteLocalLog,
    deleteLocalLogType,
    updateLocalLog,
    getLogsFromBranchFiles,
    fetchBranchFiles,
    fetchLogs,
    deleteLocalBranchFile,
    deleteLocalBranchFiles,
    updateLocalBranch,
    deleteLocalBranch
  };
};

export const useFetchLogs = () => {
  const logs = useAppSelector(selectLogs);
  const branchFiles = useAppSelector(selectBranchFiles);
  const hotelData = useAppSelector(selectHotelData);
  const token = useAppSelector(selectToken);
  const { isHomePath } = usePath();
  const { branches } = hotelData || {};
  const logGroups: LogGroups = { ...logGroupsRaw };
  const addBranches = branches?.map((b) => b.name);
  logGroups.Branches = [...(addBranches || []), ...logGroups.Branches];
  const dispatch = useAppDispatch();
  const {
    fetchBranchFiles,
    getLogsFromBranchFiles,
    fetchLogs
  } = useLogs();
  const location = useLocation();

  useEffect(() => {
    if (branchFiles && !logs) {
      fetchLogs();
    } else if (branchFiles && logs) {
      const newLogs = [...(logs || [])].filter(
        (l) => !l.id.toString().includes('F_')
      );
      const logsFromBranchFiles = getLogsFromBranchFiles();
      dispatch(setLogs([...newLogs, ...logsFromBranchFiles]));
    }
  }, [branchFiles]);

  useEffect(() => {
    if (!branchFiles && !logs && token && hotelData?.id && !isHomePath) {
      fetchBranchFiles();
    }
  }, [hotelData, location]);
};

export const useLogsSelector = ({
  triggerAllItems0,
  triggerAllItems1,
}:{
  triggerAllItems0?: Function,
  triggerAllItems1?: Function,
}) => {
  const dispatch = useAppDispatch();
  const { logGroups } = useLogs();

  const onClickShowAll = (checked1: string[]) => {
    if (checked1.includes('Show all')) {
      dispatch(setLogsChecked([...[]]));
    } else {
      let allItems: string[] = ['Show all'];
      Object.entries(logGroups).forEach(([k, v]) => {
        const allItemsOfThisGroup = [...v, k];
        allItems = [...allItems, ...allItemsOfThisGroup];
      });
      dispatch(setLogsChecked([...allItems]));
      triggerAllItems0?.();
    }
  };

  const onClickAuditKey = (key: LogKey, checked1: string[]) => {
    const value = (logGroups as any)[key];
    if (checked1.includes(key)) {
      const newC = checked1.filter((c) => ![...value, key, 'Show all']
        .includes(c as LogType));
      dispatch(setLogsChecked(newC));
    } else {
      let newC = checked1.filter((c) => !value.includes(c as LogType));
      newC = [...checked1, ...value, key];
      dispatch(setLogsChecked(newC));
      triggerAllItems0?.();
    }
  };

  const onClickAudit = (key: LogKey, logType: string, checked1: string[]) => {
    if (checked1.includes(logType)) {
      const newC = checked1.filter((c) => c !== 'Show all' && c !== key && c !== logType);
      dispatch(setLogsChecked(newC));
    } else {
      const newC = [...checked1, logType];
      dispatch(setLogsChecked(newC));
      triggerAllItems1?.(key, logType);
    }
  };
  return {
    onClickShowAll,
    onClickAuditKey,
    onClickAudit,
  };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Record<string, undefined | number>>({
    width: 278,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};
