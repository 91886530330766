import React, { useEffect, useLayoutEffect } from 'react';
import * as S from './Modal.styled';
import { useAppDispatch } from 'redux/hooks';
import { setPauseRunningDate } from 'pages/Hospitality/redux';

const Modal = function Modal({
  component,
  exitOnBgClick,
  noBg,
  skipSideBar,
}: { component: React.JSX.Element, exitOnBgClick?: Function, noBg?: boolean, skipSideBar?: true }) {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const sideBar = document.getElementById('sidebar');
    if (sideBar && !skipSideBar) {
      setTimeout(() => { sideBar.style.zIndex = '1'; }, 1);
    }
    return () => {
      if (sideBar && !skipSideBar) {
        sideBar.style.zIndex = '3';
      }
    };
  }, []);

  useEffect(() => {
    dispatch(setPauseRunningDate(true));
    return () => {
      dispatch(setPauseRunningDate(false));
    };
  }, []);

  useEffect(() => {
    const cont = document.getElementById('content');
    const bg = document.getElementById('bgMODAL_XXY');
    if (cont && bg) {
      cont.style.transition = 'none';
      cont.style.bottom = 'calc(0% - 100svh)';
      cont.style.opacity = '0';
      cont.style.transform = 'translate(50%, 0%)';
      setTimeout(() => {
        bg.style.opacity = '0.85';
        bg.style.top = '0';
        cont.style.transition = 'all ease-out 250ms';
        cont.style.bottom = '50%';
        cont.style.transform = 'translate(50%, 50%)';
        cont.style.opacity = '1';
      }, 10);
    }
  }, []);

  return (
    <>
      <S.Bg
        onClick={() => {
          exitOnBgClick?.();
        }}
        id={noBg ? 'not-used' : 'bgMODAL_XXY'}
      />
      <S.Content id="content">
        {component}
      </S.Content>
    </>
  );
};

export default Modal;
