import Color from 'color';
import styled from 'styled-components';

export const topBarColor = '#1685ec';
export const topBarColor2 = Color('#1685ec').darken(0.5).toString();
export const topBar = 50;
export const topBarHeight = `${topBar}px`;
export const borderColor = '#858585';
export const bgColor = '#EAF0F3';
export const borderColor2 = Color('#EAF0F3').darken(0.15).toString();
export const bgColor2 = Color('lightgrey').lighten(0.1).toString();
export const logRes1 = '925px';
export const auditsPanelMobileHeight = '66px';
export const metricsPanelMobileHeight = auditsPanelMobileHeight;
export const mobileRes2 = '480px';
const redColor = '#eb364b';
export const redColor2 = Color(redColor).lighten(0.1).toString();

export const Button = styled.div<{ bg?: string, hvBg?: string, color?: string, padding?: string, fontSize?: string }>`
  padding: ${({ padding }) => padding || '15px 25px'};
  padding-top: ${({ padding }) => !padding && '11px'}; 
  border-radius: 35px;
  color: ${({ color }) => color || 'white'};
  background: ${({ bg }) => bg || '#a38f18'};
  width: max-content;
  font-size: ${({ fontSize }) => fontSize || '17px'};
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background: ${({ hvBg }) => hvBg || '#edd124'};
  }
`;
