import Color from 'color';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: white;
  height: max-content;
  padding-bottom: 30px;
  overflow: visible;
  box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  -webkit-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  -moz-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
`;

export const chartRes1 = '1255px';
export const chartRes2 = '525px';
export const chartRes3 = '340px';

export const BlockCont0 = styled.div`
  width: 100%;
  height: max-content;
  overflow: visible;
`;

export const FlexCont1 = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  overflow: visible;
  @media(max-width: ${chartRes1}) {
    flex-direction: column;
  }
`;

export const FlexCont = styled.div`
  min-width: 300px;
  overflow: visible;
  @media(max-width: ${chartRes2}) {
    min-width: 0px;
  }
`;

export const FlexCont0 = styled(FlexCont)<{ height: number }>`
  width: max-content;
  height: max-content;
  display: flex;
  overflow: visible;
  max-width: ${({ height }) => height ? `${height * 1.8}px` : '25vw'};
  @media(max-width: ${chartRes1}) {
    width: 100%;
    max-width: 700px;
    margin: auto;
    margin-top: calc(-1.5vw + 10px);
  }
  @media(max-width: ${chartRes2}) {
    flex-direction: column;
    margin-top: 0vw;
  }
`;

export const ChartCont = styled.div<{ height: number, pWidth: number, adjustX1?: boolean }>`
  width: ${({ pWidth }) => pWidth * 0.25 > 250 ? `${pWidth * 0.25}px` : '250px'};
  max-width: calc(100svh - 400px);
  height: ${({ height }) => height ? `${height}px` : '30vw'};
  transform: translateX(15px);
  transform: ${({ adjustX1 }) => adjustX1 && 'translateX(20px)'};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  margin: ${({ adjustX1 }) => adjustX1 && 'auto'};
  @media(max-width: ${chartRes1}) {
    width: 50%;
    max-width: none;
    transform: translateX(3vw);
    margin-top: calc(((${chartRes1} - 100vw + 300px) / 32) * -1);
  }
  @media(max-width: ${chartRes2}) {
    margin-top: 0;
    height: max-content;
    width: 100%;
    transform: translateX(0px);
  }
`;

export const pieHeight1 = 'calc(100svh - 420px)';
export const pieHeight2 = 'calc(100svh - 430px)';

export const ChartCont22 = styled(ChartCont)`
  max-width: calc(100svh - 300px);
  margin-left: -3vw;
  transform: translateX(0px);
  @media(max-width: ${chartRes1}) {
    margin-left: 0px;
    padding-left: 0;
    transform: translateX(-3vw);
    max-width: none;
  }
  @media(max-width: ${chartRes2}) {
    transform: translateX(0px);
    margin-top: calc(-1 * (${pieHeight1} / 5.1));
  }
  @media(max-width: ${chartRes3}) {
    margin-top: calc(-1 * (${pieHeight2} / 5.1));
  }
`;

export const AreaChartCont = styled.div<{ height: number | string }>`
  flex: 1;
  display: flex;
  transform: translate(-8px, 10px);
  height: ${({ height }) => height ? `${height}px` : '30vw'};
  max-height: calc(100svh - 400px);
  @media(max-width: ${chartRes1}) {
    flex: none;
    width: 100%;
    max-width: 750px;
    margin: auto;
    height: calc(100svh - 400px);
    max-height: 500px;
    transform: translate(-14px, 0px);
    margin-top: -8svh;// calc(-1 * (calc(100svh - 400px) / 4.1));
  }
  @media(max-width: ${chartRes2}) {
    margin-top: -5svh;// calc(-1 * (${pieHeight1} / 6.1));
  }
  /* @media(max-width: ${chartRes3}) {
    margin-top: calc(-1 * (${pieHeight2} / 6.1));
  } */
`;

export const ChartCont23 = styled(ChartCont)`
  margin-left: -3vw;
`;

export const ChartCont10 = styled.div<{ height: number, pWidth: number }>`
  width: ${({ pWidth }) => `${pWidth * 0.5}px`};
  display: flex;
  transform: translate(8px, 10px);
  height: ${({ height }) => height ? `${height}px` : '30vw'};
  max-height: calc(100svh - 400px);
  max-width: 35vw;
`;

export const FlexCont2 = styled.div`
  display: flex;
  width: 100%;
  @media(max-width: ${chartRes1}) {
    flex-direction: column;
  }
`;

export const RadarChartCont = styled(FlexCont)<{ height: number }>`
  height: ${({ height }) => height ? `${height}px` : '30vw'};
  transform: translate(10px, -22px);
  max-height: calc(100svh - 400px);
  width: ${({ height }) => `${height * 1.8}px`};
  @media(max-width: ${chartRes1}) {
    width: 100%;
    transform: translate(0px, 0px);
    margin-top: -4svh;
  }
  @media(max-width: ${chartRes2}) {
    margin-top: -8svh;
  }
`;

export const LineChartCont = styled.div<{ height: number }>`
  flex: 1;
  display: flex;
  transform: translate(-20px, -2px);
  height: ${({ height }) => height ? `${height}px` : '30vw'};
  max-height: calc(100svh - 400px);
  @media(max-width: ${chartRes1}) {
    flex: none;
    width: 100%;
    max-width: 750px;
    margin: auto;
    height: calc(100svh - 400px);
    max-height: 500px;
    padding: 20px 0px;
    margin-top: 2vw;
    transform: translate(-22px, -2px);
  }
  @media(max-width: ${chartRes2}) {
    margin-top: calc(-1svh);
  }
`;
