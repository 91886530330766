import Color from 'color';
import { lFColor0 } from 'pages/components/LFHeader/LFHeader.styled';
import styled from 'styled-components';

const panelBorderColor = 'lightgrey';
const textColor = '#525252';

export const Container = styled.div`
  border-radius: 4px;
  background: white;
  width: max-content;
  width: 95vw;
  max-width: 205px;
  height: max-content;
  padding-bottom: 14px;
`;

export const Header = styled.div`
  font-weight: 700;
  font-size: 14px;
  padding: 12px 0;
  padding-left: 14px;
  color: ${Color(textColor).lighten(0.2).toString()};
  border-bottom: 1px solid ${Color(panelBorderColor).lighten(0).toString()};
`;

export const IconCont = styled.div`
  width: max-content;
  height: 80px;
  padding: 0 5px;
  margin: auto;
  margin-top: 25px;
  display: flex;
  gap: 15px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; 
  box-sizing: border-box;
`;

export const DivForKey = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const IconCont2 = styled.div`
  background: ${Color(panelBorderColor).lighten(0.1).toString()};
  outline: 1px solid ${Color(panelBorderColor).darken(0).toString()};
  border-radius: 4px;
  width: 80px;
  height: 100%;
  margin-left: 0px;
  text-align: center;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; 
  box-sizing: border-box;
  text-decoration: none;
  color: ${textColor};
  cursor: pointer;
  &:hover {
    background: ${Color(lFColor0).lighten(0.95).toString()};
    outline: 1px solid ${Color(lFColor0).lighten(0.5).toString()};
  }
`;

export const IconContMain = styled.div`
  width: 80%;
  height: 100%;
  margin: auto;
  position: relative;
`;

export const IconDiv = styled.div<{ biggerX: boolean }>`
  position: absolute;
  bottom: 60%;
  right: 50%;
  transform: translate(50%, 60%) scaleX(${(props) => props.biggerX ? 1.125 : 1});
  color: ${(props) => props.color && props.color};
`;

export const IconDesc = styled.div`
  position: absolute;
  bottom: 40%;
  right: 50%;
  transform: translate(50%, calc(40% + 18px));
  margin: auto;
  margin-top: 2px;
  font-size: 11px;
  font-weight: 700;
`;

export const Input = styled.input`
  display: none;
`;
