import React, { useState } from 'react';
import * as S from './ModalAction.styled';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import Loader from 'pages/components/Loader/Loader';

const ModalAction = ({
  onExit,
  header,
  item,
  info,
  note,
  actionBt,
  onAct,
} : {
  onExit: Function,
  header: string,
  item: string,
  info: string,
  note?: string,
  actionBt?: string,
  onAct: Function,
}) => {
  const [isLoading] = useState<boolean>(false);
  return (
    <>
      {
        isLoading ? <Loader isSticky /> : (
          <S.Container>
            <EscapeButton onClick={() => onExit()} />
            <S.Header>{header}</S.Header>
            <S.Entry>{item}</S.Entry>
            <S.Label>{info}</S.Label>
            {
              note ? (
                <S.Note>
                  <strong>{'NOTE '}</strong>
                  {note}
                </S.Note>
              ) : (null)
            }
            <S.ButtonsCont>
              <S.Button1 onClick={() => onExit()}>
                Cancel
              </S.Button1>
              <S.Button0 onClick={() => onAct()}>
                {actionBt || 'Sumbit'}
              </S.Button0>
            </S.ButtonsCont>
          </S.Container>
        )
      }
    </>
  );
};

export default ModalAction;
