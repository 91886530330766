import { mobileRes } from 'pages/components/LFHeader/LFHeader.styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const leftPanelWidth = '74px';

export const LeftPanel = styled.div`
  z-index: 3;
  flex: 0 1;
  margin-left: ${leftPanelWidth};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  @media(max-width: ${mobileRes}) {
    position: fixed;
    top: 0;
    left: -100%;
    margin-left: 0px;
    z-index: 100000;
    height: 100svh;
  }
`;

export const RightPanel = styled.div`
  flex: 1;
  overflow: hidden;
  z-index: 2;
`;
