import React, { useEffect, useState } from 'react';
import Table from './Table';
import * as S from './TableSelector.styled';
import { ImportedData } from './utils';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import Less from '@mui/icons-material/ExpandLess';
import More from '@mui/icons-material/ExpandMore';
import CCircle from '@mui/icons-material/CheckCircle';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectUsername } from 'redux/global';
import {
  BranchFile,
  BranchFiles,
  LogSheetEntry,
  addBranchFile,
  selectBranchFiles,
  selectLogsChecked,
  setBranchFiles,
  setLogsChecked, setRecentLog
} from 'pages/Logs/redux';
import Loader from 'pages/components/Loader/Loader';
import { useLocation } from 'react-router-dom';
import { useSocketEmit } from 'pages/Navigation/hooks';

type LogHeaderKey = 'Detail' | 'Value' | 'Date'
type LogHeaderValue = {
  info: string,
  isCollapsed: boolean,
  checked: boolean,
}
type LogHeader = Record<LogHeaderKey, LogHeaderValue>

const TableSelector = function TableSelector({
  importedData,
  onExit,
  selectedBranch,
  setImportedData,
} : {
  importedData: ImportedData[],
  onExit: Function,
  selectedBranch: string,
  setImportedData: Function;
}) {
  const defaultLogHeaders: LogHeader = {
    Detail: {
      info: '',
      isCollapsed: false,
      checked: false
    },
    Value: {
      info: '',
      isCollapsed: true,
      checked: false
    },
    Date: {
      info: '',
      isCollapsed: true,
      checked: false
    }
  };

  const username = useAppSelector(selectUsername);
  const [logHeaders, setLogHeaders] = useState<LogHeader>(defaultLogHeaders);
  const [selectedData, setSelectedData] = useState<number>(0);
  const { headers } = importedData[selectedData];
  const logsChecked = useAppSelector(selectLogsChecked);
  const [tableChecked, setTableChecked] = useState<boolean[]>(
    importedData.map((d, i) => i === 0)
  );
  const [checked, setChecked] = useState<Record<LogHeaderKey, boolean[]>>({
    Detail: headers.map(() => false),
    Value: headers.map(() => false),
    Date: headers.map(() => false),
  });
  const [tableName, setTableName] = useState<string>(importedData[selectedData].name);
  const [tableNameError, setTableNameError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUserHasTyped, setIsUserHasTyped] = useState<boolean>(false);
  const [isShowSuccess, setIsShowSuccess] = useState<boolean>(false);
  const branchFiles = useAppSelector(selectBranchFiles);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const socketEmit = useSocketEmit();

  const getIsCheckBoxChosen = (index: number, key: LogHeaderKey) => {
    let checkCount = 0;
    Object.entries(logHeaders).forEach(([k1]) => {
      const k = k1 as LogHeaderKey;
      if (k !== key) {
        checkCount += checked[k][index] ? 1 : 0;
      }
    });
    return checkCount > 0;
  };

  const getFormattedDate = (d: string) => {
    const dArr = d.toLowerCase()
      .replace('am', '').replace('pm', '').trim().split(' ');
    const dateArr = dArr[0].toString().trim().split(/[-/]/);
    const dateArr1 = dArr[0].toString().trim().split(/[-/]/);
    let formattedDate = `${dateArr.join('-')}${dArr[1] ? ` ${dArr[1]}` : ''}`;
    if (dateArr.length === 3) {
      dateArr.sort((a, b) => b.length - a.length);
      if (dateArr[0].length !== dateArr1[0].length) {
        formattedDate = formattedDate.replace(/([0-9]+)\/([0-9]+)/, '$2/$1');
      }
      if (Number.isNaN(new Date(formattedDate).getTime())) {
        formattedDate = `${dateArr1[2]}/${dateArr1[1]}/${dateArr1[0]}${dArr[1] ? ` ${dArr[1]}` : ''}`;
      }
    }
    return formattedDate;
  };

  const onChangeMiddleCheckBox = (index: number, key: LogHeaderKey, header1: string) => {
    if (getIsCheckBoxChosen(index, key)) return;

    const firstSheetEntry = importedData[selectedData].sheet[0][header1];
    const secondSheetEntry = importedData[selectedData].sheet[0][header1];

    if (((Number(firstSheetEntry) === 0 || Number(firstSheetEntry)) ||
      (Number(secondSheetEntry) === 0 || Number(secondSheetEntry)))
      && (key === 'Detail' || key === 'Date')) {
      toast(`${key} selection must be NON-numeric`, { type: 'error' });
      return;
    }

    const formatFSEntry = firstSheetEntry.toString().match(/[0-9](.*)/)?.[0]?.replaceAll(',', '');
    const formatSSEntry = secondSheetEntry.toString().match(/[0-9](.*)/)?.[0]?.replaceAll(',', '');

    if (((!formatFSEntry || !formatSSEntry) ||
      !(Number(formatFSEntry) === 0 || Number(formatFSEntry)) ||
      !(Number(formatSSEntry) === 0 || Number(formatSSEntry))) && key === 'Value') {
      toast('Value selection must be numeric.', { type: 'error' });
      return;
    }

    if ((Number.isNaN(new Date(getFormattedDate(firstSheetEntry.toString())).getTime()) ||
      Number.isNaN(new Date(getFormattedDate(secondSheetEntry.toString())).getTime())) &&
      key === 'Date') {
      toast('Date selection is invalid', { type: 'error' });
      return;
    }

    const newThisChecked = headers.map(() => false);
    newThisChecked[index] = !checked[key][index];
    const newLogHeaders = {
      ...logHeaders,
      [key]: {
        ...logHeaders[key],
        checked: newThisChecked[index]
      }
    };
    setLogHeaders(newLogHeaders);
    const newChecked = { ...checked, [key]: newThisChecked };
    setChecked(newChecked);
  };

  const toggleHeaderCollapse = (key: LogHeaderKey) => {
    const newLogHeaders = { ...logHeaders };
    Object.entries(logHeaders).forEach(([k1]) => {
      const k = k1 as LogHeaderKey;
      newLogHeaders[k] = { ...logHeaders[k], isCollapsed: true };
    });
    newLogHeaders[key] = { ...logHeaders[key], isCollapsed: !logHeaders[key].isCollapsed };
    setLogHeaders(newLogHeaders);
  };

  const onClickTable = (index: number) => {
    if (tableChecked[index]) return;
    setTableChecked(importedData.map((d, i) => i === index ? !tableChecked[index] : false));
    setSelectedData(index);
    setChecked({
      Detail: headers.map(() => false),
      Value: headers.map(() => false),
      Date: headers.map(() => false),
    });
    const newLogHeaders = { ...logHeaders };
    Object.entries(logHeaders).forEach(([k1]) => {
      const k = k1 as LogHeaderKey;
      newLogHeaders[k] = { ...logHeaders[k], checked: false };
    });
    setLogHeaders(newLogHeaders);
  };

  const onSaveTable = async () => {
    if (!tableName) {
      setTableNameError('Table name is needed');
      return;
    }
    const sheetNames = Object.entries(branchFiles?.[selectedBranch] || {}).map(([k]) => k);
    if (sheetNames.includes(tableName)) {
      setTableNameError('A table with this name exists');
      return;
    }
    const pInd = checked.Detail.findIndex((c) => c);
    const vInd = checked.Value.findIndex((c) => c);
    const dInd = checked.Date.findIndex((c) => c);
    const logColumnsChecked = pInd > -1 && vInd > -1 && dInd > -1;

    if (!logColumnsChecked) {
      toast('Select all audit columns to proceed', { type: 'error' });
      return;
    }

    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    const logSheet: LogSheetEntry[] = [];

    importedData[selectedData].sheet.forEach((s, i) => {
      const date = +new Date(getFormattedDate(s[headers[dInd]].toString())) > +new Date() ?
        new Date().toISOString() : getFormattedDate(s[headers[dInd]].toString());
      if (+new Date(date) > +oneYearAgo) {
        logSheet.push({
          id: i + 1,
          message: s[headers[pInd]].toString(),
          value: Number(s[headers[vInd]].toString().match(/[0-9](.*)/)?.[0]?.replaceAll(',', '')) || 0,
          date,
          updatedBy: username || 'Username Error',
          updatedAsOf: new Date().toISOString(),
          sheetName: tableName
        });
      }
    });

    if (!logSheet.length) {
      toast('Could not find any date less than one year ago', { type: 'warning' });
      return;
    }

    const branchFile: BranchFile = {
      [tableName]: logSheet
    };

    const thisBranchFiles: BranchFiles = {
      [selectedBranch]: {
        ...branchFile,
      }
    };

    const newBranchFiles: BranchFiles = {
      ...branchFiles,
      [selectedBranch]: {
        ...branchFiles?.[selectedBranch],
        ...branchFile,
      }
    };

    setIsLoading(true);

    const res = await dispatch(addBranchFile(thisBranchFiles));

    setIsLoading(false);

    if (res.status === 'success') {
      dispatch(setBranchFiles(newBranchFiles));
      socketEmit('update_branchFiles', {});

      const newChecked = [...logsChecked.filter((c: string) => c !== selectedBranch),
        selectedBranch];
      dispatch(setLogsChecked(newChecked));
      const latestId = [...logSheet].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      )[0].id;
      if (location.pathname === '/logs') {
        dispatch(setRecentLog(`F_${selectedBranch}_${tableName}_${latestId}`));
      }

      if (logSheet.length !== importedData[selectedData].sheet.length) {
        const diff = importedData[selectedData].sheet.length - logSheet.length;
        toast(`${diff} entr${diff === 1 ? 'y' : 'ies'} more than one year ago removed`, { type: 'warning' });
        return;
      }
      toast('Successfully added new Table to audits', { type: 'success' });

      if (importedData.length === 1) {
        onExit();
        return;
      }

      setSelectedData(0);
      setImportedData(importedData.filter((d, i) => i !== selectedData));
      const newTableChecked = [...tableChecked];
      newTableChecked.splice(selectedData, 1);
      newTableChecked[0] = true;
      setTableChecked(newTableChecked);
      const newLogHeaders = { ...logHeaders };
      Object.entries(logHeaders).forEach(([k1]) => {
        const k = k1 as LogHeaderKey;
        newLogHeaders[k] = { ...logHeaders[k], checked: false };
      });
      setLogHeaders(newLogHeaders);
      setChecked({
        Detail: headers.map(() => false),
        Value: headers.map(() => false),
        Date: headers.map(() => false),
      });
      setIsShowSuccess(true);
      return;
    }
    toast(res.data, { type: 'error' });
  };

  const onChangeTableName = (value: string) => {
    setTableNameError('');
    const sChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (sChars.test(value)) {
      setTableNameError('Input is not allowed');
      return;
    }
    if (value.length > 25) {
      setTableNameError('Only 25 characters allowed');
      return;
    }
    setIsUserHasTyped(true);
    setTableName(value);
  };

  useEffect(() => {
    if (!isUserHasTyped) {
      setTableName(importedData[selectedData].name);
    }
  }, [selectedData]);

  if (!importedData[selectedData]) return null;

  return (
    <>
      {
        (isLoading || isShowSuccess) ? (
          <>
            {
              isLoading ? <Loader isSticky /> : (
                <S.Cont2 isSingleOption={false}>
                  <EscapeButton onClick={() => onExit()} isNoBorder isNoBg />
                  <S.LabelCont>
                    Do you want to upload another sheet?
                  </S.LabelCont>
                  <S.ButtonCont>
                    <S.OptionNo onClick={() => onExit()}>
                      No
                    </S.OptionNo>
                    <S.MainButton onClick={() => setIsShowSuccess(false)}>
                      Yes
                    </S.MainButton>
                  </S.ButtonCont>
                </S.Cont2>
              )
            }
          </>
        ) : (
          <S.Container isSingleOption={!importedData[0].name}>
            <EscapeButton onClick={() => onExit()} isNoBorder isNoBg />
            <S.BorderContainer>
              <S.FlexCont>
                {
                  importedData[0].name ? (
                    <S.SelectTable>
                      <S.Header>
                        Select table
                      </S.Header>
                      <S.RowsContainer>
                        {
                          importedData.map((d, i) => {
                            return (
                              <S.RowCont
                                key={`tscheckbox_${d.name}_${i}`}
                                onClick={() => onClickTable(i)}
                              >
                                <S.CheckBoxDiv>
                                  <S.CheckBox
                                    type="checkbox"
                                    checked={tableChecked[i]}
                                  />
                                </S.CheckBoxDiv>
                                <S.RowName>{d.name}</S.RowName>
                              </S.RowCont>
                            );
                          })
                        }
                      </S.RowsContainer>
                    </S.SelectTable>
                  ) : (null)
                }
                <S.SelectedTable isAlone={!importedData[0].name}>
                  <S.Header>
                    Select audit columns
                  </S.Header>
                  {
                    Object.entries(logHeaders).map(([k1, v]) => {
                      const k = k1 as LogHeaderKey;
                      const { isCollapsed, checked: hChecked } = v;
                      return (
                        <S.LogHeadersCont key={`prevTabLogHed_${k}`}>
                          <S.LogHeader onClick={() => toggleHeaderCollapse(k)}>
                            <S.Circle>
                              {hChecked && <CCircle fontSize="small" sx={{ scale: '0.84' }} />}
                            </S.Circle>
                            {k}
                            <S.Icon>
                              {
                                isCollapsed ? <More fontSize="small" /> : <Less fontSize="small" />
                              }
                            </S.Icon>
                          </S.LogHeader>
                          <S.RowsContainer2 isCollapsed={isCollapsed}>
                            {
                              headers.map((header: any, i) => {
                                const isChosen = getIsCheckBoxChosen(i, k);

                                return (
                                  <S.RowCont1
                                    key={`tscheckbox_${header}`}
                                    isChosen={isChosen}
                                    onClick={() => onChangeMiddleCheckBox(i, k, header)}
                                  >
                                    <S.CheckBoxDiv>
                                      <S.CheckBox2
                                        type="checkbox"
                                        checked={checked[k][i] || isChosen}
                                      />
                                    </S.CheckBoxDiv>
                                    <S.RowName>{header}</S.RowName>
                                  </S.RowCont1>
                                );
                              })
                            }
                          </S.RowsContainer2>
                        </S.LogHeadersCont>
                      );
                    })
                  }
                </S.SelectedTable>
              </S.FlexCont>
              <S.TablePreview>
                <S.Header2>
                  Table preview
                </S.Header2>
                <Table importedData={importedData[selectedData]} />
              </S.TablePreview>
            </S.BorderContainer>
            <S.MainButtonDiv>
              <S.InputPart>
                <S.Info isError={!!tableNameError}>
                  {tableNameError || 'Enter table name'}
                </S.Info>
                <S.Input
                  isError={!!tableNameError}
                  value={tableName}
                  onChange={(e) => onChangeTableName(e.target.value)}
                />
              </S.InputPart>
              <S.MainButton
                onClick={() => onSaveTable()}
              >
                Save Table
              </S.MainButton>
            </S.MainButtonDiv>
          </S.Container>
        )
      }
    </>
  );
};

export default TableSelector;
