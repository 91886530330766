import Color from 'color';
import {
  bgColor,
  borderColor2, mobileRes2, topBarColor, topBarColor2
} from 'pages/styles';
import styled from 'styled-components';
import { bDAvailableColor, bDAvailableColor2 } from './BookingDatesBar.styled';

export const orange = Color('#d6a615').darken(0).toString();

export const Container = styled.div`
  flex: 1;
  height: max-content;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 3;
`;

export const RoomsCont = styled.div`
  width: 100%;
  z-index: 1;
`;

export const Room = styled.div`
  width: 100%;
  background: white;
  height: 60px;
  display: flex;
  min-width: 950px;
`;

const newColor = Color(orange).lighten(0.995).toString();
const newColor2 = Color(newColor).darken(0.1).toString();
export const availableColor1 = Color(topBarColor).lighten(0.875).toString();
export const Recent = styled.div<{ isRecent: boolean, isHovered: boolean, isHighlight: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${Color('lightgrey').lighten(0.085).toString()};
  border-bottom: ${({ isRecent }) => isRecent && `1px solid ${newColor2}`};
  background: ${({ isRecent }) => isRecent && newColor};
  border-bottom: ${({ isHovered }) => isHovered && '1px solid lightgrey'};
  background: ${({ isHovered }) => isHovered && Color('lightgrey').lighten(0.1).toString()};
  border-bottom: ${({ isHighlight }) => isHighlight &&
    `1px solid ${Color(availableColor1).darken(0.05).toString()}`};
  background: ${({ isHighlight }) => isHighlight && availableColor1};
  z-index: 1;
`;

export const Area = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${Color('lightgrey').lighten(0.085).toString()};
  z-index: 1;
`;

export const NameArea = styled(Recent)`
  flex: 0.85;
  padding-left: 17px;
  padding-right: 10px;
  max-width: 250px;
  position: sticky;
  left: 0;
  background: white;
  background: ${({ isRecent }) => isRecent && newColor};
  border-bottom: ${({ isHovered }) => isHovered && '1px solid lightgrey'};
  background: ${({ isHovered }) => isHovered && Color('lightgrey').lighten(0.1).toString()};
  border-bottom: ${({ isHighlight }) => isHighlight &&
    `1px solid ${Color(availableColor1).darken(0.05).toString()}`};
  background: ${({ isHighlight }) => isHighlight && availableColor1};
  z-index: 2;
  color: ${Color('#525252').darken(0.4).toString()};
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  &:hover {
    color: ${Color(topBarColor).darken(0.3).toString()};
  }
`;

export const SelectCont = styled.div<{ isShow: boolean }>`
  height: 17px;
  width:  ${({ isShow }) => isShow ? '22px' : '0px'};
  overflow: hidden;
`;

export const SelectThis = styled.input`
  border-width: 3px;
  width: 17px;
  height: 17px;
  margin: 0;
  cursor: pointer;
`;

export const RoomName = styled.div`
  font-size: 15px;
  font-weight: 700;
  margin-top: -3px;
  margin: auto 0 auto 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 5px;
`;

export const PriceArea = styled(Recent)`
  padding: 10px 0px;
  max-width: 120px;
  min-width: 100px;
`;

export const Price = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${Color('#525252').lighten(0.3).toString()};
  margin-top: -3px;
  margin: auto 0 auto 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const RateCont = styled.div`
  background: ${Color(bDAvailableColor).lighten(0.03).toString()};
  border: 1px dashed ${bDAvailableColor2};
  width: max-content;
  max-width: 100%;
  height: calc(100% - 7px);
  margin: auto 0px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
`;

export const Rate = styled.div`
  color: ${bDAvailableColor2};
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

export const FloorArea = styled(Recent)`
  padding: 10px 0px;
  max-width: 70px;
`;

export const FloorCont0 = styled.div`
  transform: translateY(5px);
`;

export const FloorCont = styled.div`
  padding: 3px 10px;
  padding-bottom: 3px;
  border-radius: 4px;
  width: max-content;
  margin: auto;
  background: ${Color('#f0f0f0').darken(0.05).toString()};
  @media(max-width: ${mobileRes2}) {
    padding: 4px 10px;
    padding-bottom: 3px;
  }
`;

export const FloorNum = styled.div`
  font-size: 11px;
  font-weight: 600;
  color: ${Color('#8f8f8f').darken(0.075).toString()};
  display: flex;
  align-items: flex-end;
`;

export const Suffix = styled.div`
  font-size: 11px;
  font-weight: 300;
`;

export const Floor = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: ${Color('#ababab').darken(0.075).toString()};;
  text-align: center;
  padding-top: 1px;
  width: 100%;
  margin: auto;
  margin-left: auto;
  width: max-content;
`;

export const InfoArea = styled(Recent)`
  border-bottom: none;
  max-width: 300px;
  min-width: 160px;
`;

export const Reserved = styled.div`
  background: #dce673;
  color: #525252;
  flex: 1;
  border-bottom: 1px solid ${Color('#dce673').darken(0.225).toString()};
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    background: ${Color('#dce673').lighten(0.175).toString()};
  }
`;

export const Meals = styled(Reserved)`
  background: ${Color(orange).lighten(0.35).toString()};
  border-bottom: 1px solid ${Color(orange).lighten(0.15).toString()};
  &:hover {
    background: ${Color(orange).lighten(0.65).toString()};
  }
`;

export const ToServeFlex = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1px;
`;

export const ToServeText = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
`;

export const AlertIcon = styled.div`
  display: flex;
  width: 14px;
  height: 17px;
  display: flex;
  color: ${Color(orange).darken(0.4).toString()};
  flex-direction: center;
  justify-content: center;
  align-items: center;
`;

export const Service = styled.div`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: black;
  margin-top: -1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 40px);
`;

export const ReservedText = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

export const Token = styled.div`
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 2px;
  color: black;
  margin-top: -3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 40px);
`;

export const SoonCheckIn = styled.div`
  background: ${Color(topBarColor2).lighten(0.7).toString()};
  flex: 1;
  border-bottom: 1px solid ${Color(topBarColor2).lighten(0.5).toString()};
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: ${Color(topBarColor2).lighten(2.5).toString()};
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: ${Color(topBarColor2).lighten(1.2).toString()};
  }
`;

export const Cont1 = styled.div`
  display: flex;
`;

export const Countdown = styled.div`
  color: white;
  letter-spacing: 1.5px;
  font-size: 15px;
  font-weight: 700;
  margin-top: -1px;
`;

export const greenColor = '#56d651';
export const availableColor = Color(topBarColor).lighten(0.75).toString();

export const Available = styled.div`
  background: ${availableColor};
  color: #525252;
  flex: 1;
  border-bottom: 1px solid ${Color(topBarColor).lighten(0.65).toString()};
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  gap: 6px;
`;

export const TickIcon = styled.div`
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 17px;
  width: 17px;
  color: white;
  background: ${greenColor};
`;

export const BookArea = styled(Recent)`
  flex-direction: row;
  align-items: center;
  max-width: 180px;
  min-width: 180px;
`;

export const BookButton = styled.div`
  padding: 7px 14px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  background: ${greenColor};
  color: ${Color(greenColor).darken(0.6).toString()};
  margin: auto 0 auto 12px;
  width: max-content;
  &:hover {
    background: ${Color(greenColor).lighten(0.3).toString()};
  }
`;

const logFontColor = Color('lightgrey').darken(0.25).toString();
export const LogArea = styled(Recent)`
  max-width: 60px;
  background: ${Color('lightgrey').lighten(0.125).toString()};
  border-bottom: 1px solid ${Color('lightgrey').darken(0).toString()};
  display: flex;
  padding: 9px 8px;
  background: ${({ isRecent }) => isRecent && Color(newColor).darken(0.05).toString()};
  border-bottom: ${({ isRecent }) => isRecent && `1px solid ${newColor2}`};
`;

export const Log = styled.div<{ isRecent: boolean }>`
  flex: 1;
  border-radius: 3px;
  border: 1.5px solid ${logFontColor};
  color: ${logFontColor};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ isRecent }) => isRecent && orange};
  border-color: ${({ isRecent }) => isRecent && orange};
  &:hover {
    color: ${topBarColor};
    border-color: ${topBarColor};
  }
`;

export const LogIcon = styled.div`
  scale: 0.7;
  margin-top: -2px;
`;

export const LogText = styled.div`
  font-weight: 600;
  margin-top: -8px;
  font-size: 8px;
`;

export const PerksArea = styled(Recent)<{ cursor: string }>`
  max-width: 100%;
  min-width: 200px;
  overflow: hidden;
  cursor: ${({ cursor }) => cursor};
`;

export const PerksArea0 = styled(Area)`
  max-width: 100%;
  border-bottom: none;
  overflow: hidden;
  margin: 0px 13px;
`;

export const PerksArea1 = styled(Area)<{ xPos: number }>`
  max-width: 100%;
  border-bottom: none;
  transform: ${({ xPos }) => `translateX(${xPos}px)`};
`;

export const PerksArea2 = styled(Area)`
  max-width: 100%;
  border-bottom: none;
  overflow-x: auto;
`;

export const PerkOptions = styled.div`
  display: flex;
  gap: 13px;
  margin: auto 0px;
`;

export const MenuArea = styled(Recent)`
  max-width: 25px;
`;

export const MoreIcon = styled.div`
  margin: auto auto auto 5px;
  margin-right: auto;
  color: grey;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 10px;
  cursor: pointer;
  &:hover {
    color: ${orange};
  }
`;

export const NoRoomsCont = styled.div`
  margin: auto;
  margin-bottom: 0px;
  width: max-content;
  max-width: 270px;
  background: white;
  padding: 18px 28px;
  padding-bottom: 20px;
  border-radius: 4px;
  border: 1px solid ${Color(borderColor2).lighten(0.1).toString()};
`;

export const NoRooms = styled.div`
  font-size: 20px;
  margin: auto;
  width: max-content;
  font-weight: 600;
`;

export const NoRoomsInfo = styled.div`
  font-size: 14px;
  color: #525252;
  width: 100%;
  text-align: center;
  margin-top: 2px;
  line-height: 1.3;
`;

export const NoRoomsInfo1 = styled.div`
  font-size: 13px;
  color: #999999;
  width: 100%;
  text-align: center;
  margin-top: 5px;
`;

export const ReloadPage = styled.div`
  color: #1685ec;
  font-size: 13px;
  text-decoration: underline;
  font-weight: 300;
  width: max-content;
  margin: auto;
  margin-top: 25px;
  cursor: pointer;
  &:hover {
    color: blue;
  }
`;

export const PagesCont = styled.div`
  position: sticky;
  margin-top: auto;
  bottom: -1px;
  left: 0;
  min-height: 45px;
  height: 45px;
  max-height: 45px;
  z-index: 2;
  border: 1px solid ${Color(bgColor).darken(0.1).toString()};
  background: ${bgColor};
  min-width: 100%;
`;

export const PagesCont1 = styled.div`
  border-bottom: 0;
  background: white;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: max-content;
  margin: auto;
`;
