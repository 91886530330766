import React, { useLayoutEffect } from 'react';
import * as S from './AuditsSelector.styled';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import { moveTmpPanel } from 'pages/Navigation/utils';
import Search from '@mui/icons-material/Search';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectLogSearch, selectLogsChecked, setLogSearch } from '../redux';
import { useLogs, useLogsSelector } from '../hooks';
import { LogKey } from '../Logs';

export const AuditSelector = ({
  isWeb,
  useEscape,
  isNoSearch
}:{
  isWeb?: boolean,
  useEscape?: boolean,
  isNoSearch?: true
}) => {
  const dispatch = useAppDispatch();
  const checked = useAppSelector(selectLogsChecked);
  const logSearch = useAppSelector(selectLogSearch);
  const { logGroups } = useLogs();
  const { onClickShowAll, onClickAudit, onClickAuditKey } = useLogsSelector({});

  const onAuditsScroll = (e: any, scrollTop?: number) => {
    const scrollT = scrollTop || e.target.scrollTop;
    localStorage.setItem('audits_scroll', (scrollT || 0).toString());
  };

  const scrollAuditsPanel = () => {
    const scrollTop = Number(localStorage.getItem('audits_scroll') || 0);
    document.getElementById('audits')?.scrollTo({ top: scrollTop });
  };

  useLayoutEffect(() => {
    scrollAuditsPanel();
  }, [checked]);

  return (
    <S.AuditsCont isWeb={isWeb}>
      <S.SearchCont>
        {
          !isNoSearch && (
            <>
              <S.IconDiv isActive={!!logSearch}><Search fontSize="medium" /></S.IconDiv>
              <S.Input
                isActive={!!logSearch}
                placeholder="Search audit logs"
                value={logSearch}
                onChange={(e) => {
                  dispatch(setLogSearch(e.target.value));
                }}
              />
            </>
          )
        }
      </S.SearchCont>
      <S.Header>
        Audits
      </S.Header>
      {
        useEscape ? <EscapeButton onClick={() => moveTmpPanel()} /> : (
          <S.EsacpePart>
            <EscapeButton onClick={() => moveTmpPanel()} />
          </S.EsacpePart>
        )
      }
      <S.Audits id="audits" onScroll={onAuditsScroll}>
        <S.AuditCont>
          <S.AuditGroup1
            onClick={() => onClickShowAll(checked)}
          >
            Show all
            <S.Select
              type="checkbox"
              checked={checked.includes('Show all')}
              readOnly
            />
          </S.AuditGroup1>
        </S.AuditCont>
        {
          Object.entries(logGroups).map(([key, value]) => {
            return (
              <S.AuditCont key={`AuditSet_${key}`}>
                <S.AuditGroup
                  onClick={() => onClickAuditKey(key as LogKey, checked)}
                >
                  {key}
                  <S.Select
                    type="checkbox"
                    checked={checked.includes(key)}
                    readOnly
                  />
                </S.AuditGroup>
                {
                  value.map((logType) => {
                    return (
                      <S.AuditName
                        key={`logT_${logType}`}
                        onClick={() => onClickAudit(key as LogKey, logType, checked)}
                      >
                        {logType}
                        <S.Select2
                          type="checkbox"
                          checked={checked.includes(logType)}
                          readOnly
                        />
                      </S.AuditName>
                    );
                  })
                }
              </S.AuditCont>
            );
          })
        }
      </S.Audits>
    </S.AuditsCont>
  );
};
