import styled from 'styled-components';
import {
  borderColor2,
  topBarHeight
} from 'pages/styles';
import Color from 'color';

export const orange = Color('#d6a615').darken(0).toString();

export const mobileRes = '669px';
export const headerRes1 = '542px';
const dark = 'white';
export const lFColor0 = '#7914cc';
export const lFColor = '#490c7b'; // equal to color below
export const lFColor1 = Color('#7914cc').darken(0.4).toString();
export const lFColor2 = '#212137';

export const MobileStyle = styled.div<{ isHome: boolean }>`
  color: ${({ isHome }) => isHome ? 'white' : lFColor2};
`;

export const MobileStyleSpan = styled.div<{ isHome: boolean }>`
  color: ${({ isHome }) => isHome ? 'white' : lFColor};
`;

export const Container = styled.header<{ isHome: boolean }>`
  background: ${({ isHome }) => isHome ? lFColor : 'white'};
  border-bottom: ${({ isHome }) => !isHome && `1.5px solid ${borderColor2}`};
  height: ${topBarHeight};
  width: 100%;
  display: block;
  padding: 15px;
  position: relative;
  z-index: 1;
  margin: 0;
  @media(max-width: ${headerRes1}) {
    padding: 15px 11px;
  }
`;

export const Menu = styled.div`
  color: ${dark};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const NamePart = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 10px;
  overflow: hidden;
  gap: 6px;
`;

export const MenuIcon = styled.div`
  color: ${lFColor2};
  width: 20px;
  height: 20px;
  display: none;
  margin-left: -2px;
  margin-top: -3px;
  cursor: pointer;
  &:hover {
    color: lightgrey;
  }
  @media(max-width: ${mobileRes}) {
    display: block;
  }
`;

export const HotelName = styled(MobileStyle)<{ isHome: boolean }>`
  font-size: 15px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 60px);
  cursor: pointer;
  &:hover {
    color: lightgrey;
  }
  @media(max-width: 323px) {
    font-size: 13px;
  }
`;

export const Smaller = styled.span`
  font-weight: 400;
`;

export const HotelDesc = styled.div`
  font-size: 13px;
  font-weight: 400;
  margin-left: auto;
`;

export const LodgeFirstCont = styled.div`
  font-size: 14px;
  margin-top: 2px;
`;

export const LodgePart = styled.div`
  display: flex;
  align-items: center;
`;

export const Lodge = styled(MobileStyle)`
  font-weight: 600;
  z-index: 2;
`;

export const First = styled(MobileStyleSpan)<{ isHome: boolean }>`
  font-size: 15px;
  font-weight: 700;
  padding: 0px 4px;
  border-radius: 2px;
  z-index: 1;
  margin-left: 2px;
  background:  ${({ isHome }) => isHome ? Color(lFColor).lighten(0.6).toString()
    : Color(lFColor).lighten(2).toString()};
`;

export const LinkCont = styled.div`
  display: flex;
  align-items: center;
  margin-left: 55px;
  margin-top: 2px;
  gap: 10px;
  @media(max-width: 362px) {
    display: none;
  }
`;

export const Link = styled.nav`
  font-size: 13px;
  cursor: pointer;
  &:hover {
    color: ${orange};
  }
`;

export const SignCont = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 10px;
`;

export const Label = styled.nav`
  font-size: 13px;
  cursor: pointer;
  &:hover {
    color: ${orange};
  }
`;

export const Hyphen = styled.div`
  font-size: 14px;
`;
