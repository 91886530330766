export const moveTmpPanelTmp = (removeOnly: boolean, id: string, skipSideBar: boolean) => {
  const tmpPanel = document.getElementById(id);
  const bg = document.getElementById(`${id}_bg`);
  const sideBar = document.getElementById('sidebar');
  if (tmpPanel && bg) {
    const tmpPanelStyle = window.getComputedStyle(tmpPanel);
    const isShowingMenu = tmpPanelStyle.left === '0px';
    if (isShowingMenu) {
      bg.style.display = 'none';
      bg.style.opacity = '0';
      tmpPanel.style.transition = 'all ease-in 250ms';
      tmpPanel.style.left = '0px';
      tmpPanel.style.left = `-${tmpPanelStyle.width}`;
      setTimeout(() => {
        if (sideBar && !skipSideBar) {
          sideBar.style.zIndex = '3';
        }
      }, 1);
      return;
    }

    if (!removeOnly) {
      if (sideBar) {
        sideBar.style.zIndex = '1';
      }
      bg.style.display = 'block';
      bg.style.opacity = '0.55';
      tmpPanel.style.transition = 'all ease-out 250ms';
      tmpPanel.style.left = `-${tmpPanelStyle.width}`;
      tmpPanel.style.left = '0px';
    }
  }
};

export const moveTmpPanel = (id?: string, removeOnly?: boolean, skipSideBar?: boolean) => {
  if (id) {
    moveTmpPanelTmp(removeOnly || false, id, skipSideBar || false);
  } else {
    for (let i = 0; i < 2; i += 1) {
      moveTmpPanelTmp(true, `tmp_panel${i}`, skipSideBar || false);
    }
  }
};

export const moveSideBar = function moveSideBar(removeOnly?: boolean) {
  const sideBar = document.getElementById('sidebar');
  const bg = document.getElementById('main_bg');
  if (sideBar && bg) {
    sideBar.style.zIndex = '3';
    const sideBarStyle = window.getComputedStyle(sideBar);
    const isShowingMenu = sideBarStyle.left === '0px';
    if (isShowingMenu) {
      bg.style.display = 'none';
      bg.style.opacity = '0';
      sideBar.style.transition = 'all ease-in 250ms';
      sideBar.style.left = '0px';
      sideBar.style.left = `-${sideBarStyle.width}`;
      return;
    }
    if (!removeOnly) {
      bg.style.display = 'block';
      bg.style.opacity = '0.55';
      sideBar.style.transition = 'all ease-out 250ms';
      sideBar.style.left = `-${sideBarStyle.width}`;
      sideBar.style.left = '0px';
    }
  }
};

export const clearMainBG = () => {
  moveSideBar(true);
  const bg = document.getElementById('main_bg');
  if (bg) {
    bg.style.display = 'none';
    bg.style.opacity = '0';
  }
};
