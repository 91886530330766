import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Loader from 'pages/components/Loader/Loader';
import { useAppSelector } from 'redux/hooks';
import {
  selectPermission, selectToken,
} from 'redux/global';
import Layout from 'pages/Layout/Layout';

const Hospitality = lazy(() => import('pages/Hospitality/Hospitality'));
const Homepage = lazy(() => import('pages/Homepage/Homepage'));
const ReserveRooms = lazy(() => import('pages/ReserveRooms/ReserveRooms'));
const Reservations = lazy(() => import('pages/Reservations/Reservations'));
const Logs = lazy(() => import('pages/Logs/Logs'));
const Metrics = lazy(() => import('pages/Metrics/Metrics'));
const Settings = lazy(() => import('pages/Settings/Settings'));
const NotFound = lazy(() => import('pages/NotFound/NotFound'));
const Forgot = lazy(() => import('pages/Forgot/Forgot'));
const SetPassword = lazy(() => import('pages/SetPassword/SetPassword'));
const RegisterPassword = lazy(() => import('pages/RegisterPassword/RegisterPassword'));
const Insights = lazy(() => import('pages/Insights/Insights'));
const SignIn = lazy(() => import('pages/SignIn/SignIn'));
const SignUp = lazy(() => import('pages/SignUp/SignUp'));
const AddHotel = lazy(() => import('pages/AddHotel/AddHotel'));
const UnSubscribe = lazy(() => import('pages/UnSubscribe/UnSubscribe'));

(document.body.style as any).zoom = '100.06%';

const Routing = function Routing() {
  const token = useAppSelector(selectToken);
  const permission = useAppSelector(selectPermission);

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Homepage />
            </Route>
            <Route exact path="/pricing">
              <Homepage />
            </Route>
            <Route exact path="/unsubscribe">
              <UnSubscribe />
            </Route>
            <Route exact path="/forgot">
              <Forgot />
            </Route>
            <Route exact path="/forgot/:key/:id">
              <SetPassword />
            </Route>
            <Route exact path="/register/:key/:id">
              <RegisterPassword />
            </Route>
            <Route exact path="/signin">
              <SignIn />
            </Route>
            <Route exact path="/signup">
              <SignUp />
            </Route>
            <Route exact path="/addH/:id">
              <AddHotel />
            </Route>
            {
              token && (
                <Route exact path="/logs">
                  <Logs />
                </Route>
              )
            }
            {
              token && (permission || 0) >= 2 && (
                <Route exact path={['/settings/editroom/:id', '/settings']}>
                  <Route exact path="/settings/editroom/:id">
                    <Settings />
                  </Route>
                  <Route exact path="/settings">
                    <Settings />
                  </Route>
                </Route>
              )
            }
            {
              token && [1, 2, 3, 6].includes(permission || 0) && (
                <Route exact path="/reserve">
                  <ReserveRooms />
                </Route>
              )
            }
            {
              (token && (permission || 0) > 0) && (
                <Route exact path={['/rooms', '/reservations']}>
                  <Route exact path="/rooms">
                    <Hospitality />
                  </Route>
                  <Route exact path="/reservations">
                    <Reservations />
                  </Route>
                </Route>
              )
            }
            {
              (token && (permission || 0) >= 4) && (
                <Route exact path={['/metrics', '/logs/:action', 'dashboard', '*']}>
                  <Switch>
                    <Route exact path="/metrics">
                      <Metrics />
                    </Route>
                    <Route exact path="/dashboard">
                      <Insights />
                    </Route>
                    <Route exact path="/logs/:action">
                      <Logs />
                    </Route>
                    <Route exact path="*">
                      <NotFound />
                    </Route>
                  </Switch>
                </Route>
              )
            }
            <Route exact path="*">
              <NotFound />
            </Route>
          </Switch>
        </Layout>
      </Suspense>
    </Router>
  );
};

export default Routing;
