import React from 'react';
import * as S from './QuickOptions.styled';
import { LogKey, logKeys } from '../Logs';
import { moveTmpPanel } from 'pages/Navigation/utils';

const QuickOptions = ({
  quickLinks,
  checked,
  buttonLabel,
} :
{
  quickLinks: Record<string, any>[],
  checked: string | string[],
  buttonLabel: string,
}) => {
  const QOptionsParts = () => {
    return (
      <>
        {
          quickLinks.map((qL) => {
            return (
              <S.AuditSet
                key={`qL_${qL.name}`}
                isKey={buttonLabel.toLowerCase().includes('metric') ? true :
                  ([...logKeys] as LogKey[]).includes(qL.name as LogKey)}
                onClick={() => {
                  qL.func(checked);
                }}
              >
                <S.AuditName1>{qL.name}</S.AuditName1>
                <S.Select
                  type="checkbox"
                  checked={buttonLabel.toLowerCase().includes('metric') ?
                    checked === qL.name : checked.includes(qL.name)}
                  readOnly
                />
              </S.AuditSet>
            );
          })
        }
        <S.OptionButton
          onClick={() => {
            if (buttonLabel.toLowerCase().includes('metric')) {
              moveTmpPanel('tmp_panel1');
            } else {
              moveTmpPanel('tmp_panel0');
            }
          }}
        >
          {buttonLabel}
        </S.OptionButton>
      </>
    );
  };

  return (
    <>
      {
        !buttonLabel.toLowerCase().includes('metric') ? (
          <S.Container>
            <QOptionsParts />
          </S.Container>
        ) : (
          <S.Container2>
            <QOptionsParts />
          </S.Container2>
        )
      }
    </>
  );
};

export default QuickOptions;
