import { callEndpoint } from 'redux/global';
import { BookingDetails, IResponse, Room } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { Services } from 'pages/Settings/redux';

export const sortOptions = [
  'Last updated', 'Name', 'Base rates', 'Meal service', 'Check-in soon', 'On hold', 'Available rooms'] as const;
export type SortOptions = typeof sortOptions[number];

type BookingDates = {
  start: Date,
  end: Date
}

export type SelectedRoom = {
  id: string,
  rateId: string,
}
export interface AppState {
  rooms: Room[] | null,
  displayRooms: Room[] | null,
  search: string;
  sort: SortOptions;
  userEmail: string;
  password: string;
  isStaySignedIn: boolean;
  isScrollScreen: boolean | null;
  floor: string;
  bookDates: BookingDates;
  selectedRooms: SelectedRoom[];
  pauseRunningDate: boolean;
}

const initialState: AppState = {
  rooms: null,
  displayRooms: null,
  search: '',
  sort: sortOptions[0],
  userEmail: localStorage.getItem('userEmail') || '',
  password: localStorage.getItem('token2') || '',
  isStaySignedIn: Boolean(localStorage.getItem('staySignedIn')),
  isScrollScreen: null,
  floor: 'All floors',
  bookDates: { start: new Date(), end: new Date(+new Date() + (24 * 60 * 60 * 1000)) },
  selectedRooms: [],
  pauseRunningDate: false
};

export const counterSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setRooms: (state, action: PayloadAction<Room[] | null>) => {
      if (action.payload) {
        if (!state.displayRooms) {
          state.displayRooms = [];
        }
        state.displayRooms.forEach((room, i) => {
          // Find similar rooms in displayRooms and payload and replace
          // the displayload data with the payload data so displayRoom maintains it's order
          if (state.displayRooms && state.displayRooms[i] && action.payload) {
            const thisRoom = action.payload.find((r) => r.id === state.displayRooms?.[i].id);
            state.displayRooms[i] = thisRoom || action.payload[0];
          }
        });
        // Some rooms may not be found in rooms anymore
        state.displayRooms = state.displayRooms.filter((r) => r);
      } else {
        state.displayRooms = null;
      }
      state.rooms = action.payload;
    },
    setRoomsNoDisplay: (state, action: PayloadAction<Room[] | null>) => {
      state.rooms = action.payload;
    },
    setDisplayRooms: (state, action: PayloadAction<Room[] | null>) => {
      state.displayRooms = action.payload;
    },
    setDisplayFromRooms: (state, action: PayloadAction<Room[] | null>) => {
      if (action.payload) {
        if (!state.displayRooms) {
          state.displayRooms = [];
        }
        state.displayRooms.forEach((room, i) => {
          // Find similar rooms in displayRooms and payload and replace...
          // ...the displayload data with the payload data so displayRoom maintains it's order
          if (state.displayRooms && state.displayRooms[i] && action.payload) {
            const thisRoom = action.payload.find((r) => r.id === state.displayRooms?.[i].id);
            state.displayRooms[i] = thisRoom || action.payload[0];
          }
        });
      } else {
        state.displayRooms = null;
      }
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSort: (state, action: PayloadAction<SortOptions>) => {
      state.sort = action.payload;
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload;
      localStorage.setItem('userEmail', action.payload);
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
      localStorage.setItem('token2', action.payload);
    },
    setIsStaySignedIn: (state, action: PayloadAction<boolean>) => {
      state.isStaySignedIn = action.payload;
      if (action.payload) {
        localStorage.setItem('staySignedIn', 'Yes');
      } else {
        localStorage.removeItem('staySignedIn');
      }
    },
    setIsScrollScreen: (state, action: PayloadAction<boolean>) => {
      state.isScrollScreen = action.payload;
    },
    setFloor: (state, action: PayloadAction<string>) => {
      state.floor = action.payload;
    },
    setBookingDates: (state, action: PayloadAction<BookingDates>) => {
      state.bookDates = action.payload;
    },
    setSelectedRooms: (state, action: PayloadAction<SelectedRoom[]>) => {
      state.selectedRooms = action.payload;
    },
    setPauseRunningDate: (state, action: PayloadAction<boolean>) => {
      state.pauseRunningDate = action.payload;
    }
  },
});

export const {
  setRooms,
  setRoomsNoDisplay,
  setDisplayRooms,
  setDisplayFromRooms,
  setSearch,
  setSort,
  setUserEmail,
  setPassword,
  setIsStaySignedIn,
  setIsScrollScreen,
  setFloor,
  setBookingDates,
  setSelectedRooms,
  setPauseRunningDate
} = counterSlice.actions;

export const selectRooms = (state: RootState) => state.app.rooms;
export const selectDisplayRooms = (state: RootState) => state.app.displayRooms;
export const selectSearch = (state: RootState) => state.app.search;
export const selectSort = (state: RootState) => state.app.sort;
export const selectUserEmail = (state: RootState) => state.app.userEmail;
export const selectPassword = (state: RootState) => state.app.password;
export const selectIsStaySignedIn = (state: RootState) => state.app.isStaySignedIn;
export const selectIsScrollScreen = (state: RootState) => state.app.isScrollScreen;
export const selectFloor = (state: RootState) => state.app.floor;
export const selectBookingDates = (state: RootState) => state.app.bookDates;
export const selectSelectedRooms = (state: RootState) => state.app.selectedRooms;
export const selectPauseRunningDate = (state: RootState) => state.app.pauseRunningDate;

export default counterSlice.reducer;

export type AddEditApiBody = {
  name: string,
  floor: string,
  onHold: string | null,
  perks: string,
  id?: string | null,
  origName?: string | null,
  roomTypeId: string
};

export const addRoom = (body: AddEditApiBody) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'addroom',
      body,
      method: 'POST',
    }),
  );
  return res;
};

export const getRooms =
() => async (dispatch: Function, getState: Function): Promise<IResponse> => {
  const state = getState();
  const { isStaff } = state.homepage;

  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'rooms',
      body: { isStaff },
      method: 'POST',
    }),
  );
  return res;
};

export type EditBooking = {
  id: string,
  name: string,
  days: string,
  token: string | null,
  refundAmount: string | null,
  email?: string,
  hours: string,
  mins: string,
  secs: string,
  bookDate: Date,
  milliSecs: string,
  isEditingBooking: boolean,
  editBook: BookingDetails,
  rate: string
}

export const editBooking =
(body: EditBooking[]) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'editbooking',
      body: { editDetails: body },
      method: 'PATCH',
    }),
  );
  return res;
};

export type BookRoom = BookingDetails & {
  transfer?: { toName: string, fromName: string, cost: number} };

export const bookRoom =
(body: BookRoom[]) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'book',
      body: { bookingDetails: body },
      method: 'PATCH',
    }),
  );
  return res;
};

export type DeleteBooking = {
  roomId: string,
  id: string,
  updatedAsOf: string,
  rate: string,
  startDate: string,
  skipLog?: true
}

export const deleteBooking =
(body: DeleteBooking) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'deletebooking',
      body: { deleteDetails: body },
      method: 'PATCH',
    }),
  );
  return res;
};

export const editRoom =
(body: AddEditApiBody) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'editroom',
      body,
      method: 'PATCH',
    }),
  );
  return res;
};

export const deleteRooms = (ids: string[]) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'deleterooms',
      body: { ids },
      method: 'DELETE',
    }),
  );
  return res;
};

export type UpdateServices = {
  logMessage: string,
  services: Services,
  updatedAsOf: string,
  roomId: string
}

export const updateServices = (us: UpdateServices) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'updateservices',
      body: { ...us },
      method: 'POST',
    }),
  );
  return res;
};

type EditBookAccess = {
  editBook: BookingDetails,
  roomName: string,
  updatedAsOf: string
}

export const editBookAccess = (eba: EditBookAccess) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'editbookaccess',
      body: { ...eba },
      method: 'PATCH',
    }),
  );
  return res;
};
