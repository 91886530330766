import Color from 'color';
import {
  borderColor,
  logRes1,
  mobileRes2,
  topBarColor,
  topBarColor2,
} from 'pages/styles';
import styled from 'styled-components';

const lFColor = '#490c7b';
const orange = Color('#d6a615').darken(0).toString();

const totalFooterSize = '57px';
const pagesFooterSize = '48px';
const cashFooterRes = '1165px';
const cashFooterSize = '52px';
const mainFooterSize = `${Number(pagesFooterSize.replace('px', '')) +
  Number(totalFooterSize.replace('px', ''))}px`;
export const Container = styled.div<{ isCashGroup: boolean }>`
  position: relative;
  overflow: hidden;
  margin: 7px 6px;
  margin-right: 0px;
  transform: translateX(1px);
  padding: 1px;
  flex: 1;
  padding-bottom: ${({ isCashGroup }) => isCashGroup ? mainFooterSize : pagesFooterSize};
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 6.5px;
  @media(max-width: ${cashFooterRes}) {
    padding-bottom: ${({ isCashGroup }) => isCashGroup && `calc(${mainFooterSize} + ${cashFooterSize})`};
  }
`;

export const AllCont = styled.div`
  border-radius: 3px;
  flex: 0 1;
  max-height: 100%;
  box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  -webkit-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  -moz-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
`;

export const Cont1 = styled.div`
  width: 100%;
  height: max-content;
  max-height: 100%;
  overflow: auto;
  z-index: 1;
  background: white;
  border-radius: 3px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  @media(max-width: ${logRes1}) {
    border: none;
  }
`;

const valueTHWidth = '112px';
const detailTHWidth = 'calc(100vw - 15px)';
export const Table = styled.table<{ isCashGroup: boolean, itemCount: number }>`
  width: 100%;
  min-width: ${({ isCashGroup, itemCount }) => isCashGroup ? `calc(1000px + 60px + ${itemCount * 4}px)`
    : `calc(840px + 60px + ${itemCount * 4}px)`};
  height: max-content;
  font-weight: 100;
  color: #525252;
  table-layout: fixed;
  border-collapse: separate;
  border: none;
  border-spacing: 0;
  @media(max-width: ${mobileRes2}) {
    min-width: ${({ isCashGroup, itemCount }) => isCashGroup
    ? `calc((${detailTHWidth} - ${valueTHWidth}) + 470px + ${itemCount * 4}px)`
    : `calc(${detailTHWidth} + 390px + ${itemCount * 4}px)`};
  }
`;

export const THead = styled.thead`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const TR = styled.tr`
  z-index: 1;
`;

const tableBorderColor = borderColor;
export const TH = styled.th<{ isEmpty: boolean, isCashGroup: boolean }>`
  padding: 15px 30px;
  padding-bottom: 16px;
  text-align: right;
  background: white;
  width: ${({ isCashGroup }) => !isCashGroup ? 'calc(100% / 3.35)' : 'calc(100% / 4.55)'};
  font-weight: 500;
  z-index: 3;
  font-size: 12px;
  border-bottom: ${({ isEmpty }) => !isEmpty && `1px solid ${Color(tableBorderColor).lighten(0.6).toString()}`};
  @media(max-width: ${mobileRes2}) {
    width: ${({ isCashGroup }) => !isCashGroup ? 'calc(100% / 2.1)' : 'calc(100% / 1.65)'};
    padding: 12px 15px;
  }
`;

export const lightPurple = Color('#eadaf0').lighten(0.045).toString();

export const TH2 = styled(TH)`
  width: calc(100% / 1.95);
  background: ${lightPurple};
  border-bottom: ${({ isEmpty }) => !isEmpty && `1px solid ${Color('#eadaf0').darken(0.05).toString()}`};
  @media(max-width: ${mobileRes2}) {
    width: ${({ isCashGroup }) => !isCashGroup ? `calc(${detailTHWidth})`
    : `calc(${detailTHWidth} - ${valueTHWidth})`};
  }
`;

export const TH3 = styled(TH)`
  width: 200px;
  @media(max-width: ${mobileRes2}) {
    width: 163px;
  }
`;

export const TH4 = styled(TH)`
  width: 145px;
  border-left: 1px solid ${Color('lightgrey').lighten(0.065).toString()};
  position: sticky;
  right: 0;
  @media(max-width: ${mobileRes2}) {
    width: ${valueTHWidth};
  }
`;

export const TH7 = styled(TH)<{ itemCount: number }>`
  width: ${({ itemCount }) => `calc(60px + ${itemCount * 4}px)`};
  @media(max-width: ${mobileRes2}) {
    width: ${({ itemCount }) => `calc(35px + ${itemCount * 4}px)`};
  }
`;

export const TR2 = styled.tr`
`;

const hColor = Color(topBarColor).lighten(0.875).toString();
const newColor = Color(orange).lighten(1.05).toString();

export const TD = styled.td<{
  fontWeight?: number, isLast?: boolean, highlighted: number, ind: number, isNew: boolean
}>`
  padding: 28px 30px;
  text-align: right;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  font-size: 12.5px;
  line-height: 1.7;
  overflow: visible;
  border-bottom: 1px solid ${Color(tableBorderColor).lighten(0.6).toString()};
  border: ${({ isLast }) => isLast && 'none'};
  z-index: 1;
  background: ${({ isNew }) => isNew && newColor};
  background: ${({ highlighted, ind }) => highlighted === ind && hColor};
  border-bottom: ${({ highlighted, ind }) => (ind === highlighted - 1 || ind === highlighted) && 'none'};
  border-color: ${({ isNew }) => isNew && Color(newColor).darken(0.075).toString()};
  @media(max-width: ${mobileRes2}) {
    padding: 20px 15px;
    font-size: 11.5px;
    line-height: 1.55;
  }
`;

export const TD1 = styled(TD)`
  background: ${Color('#eadaf0').lighten(0.075).toString()};
  background: ${({ isNew }) => isNew && Color(newColor).darken(0.03).toString()};
  background: ${({ highlighted, ind }) => highlighted === ind && Color(hColor).darken(0.025).toString()};
  border-bottom: 1px solid ${Color('#eadaf0').darken(0.015).toString()};
  border-color: ${({ isNew }) => isNew && Color(newColor).darken(0.125).toString()};
  border-bottom: ${({ highlighted, ind }) => (ind === highlighted - 1 || ind === highlighted) && 'none'};
  @media(max-width: ${mobileRes2}) {
    font-size: 12px;
  }
`;

export const TD2 = styled(TD)`
  border-left: 1px solid ${Color('lightgrey').lighten(0.065).toString()};
  border-left-color: ${({ isNew }) => isNew && Color(newColor).darken(0.075).toString()};
  border-left-color: ${({ highlighted, ind }) => (ind === highlighted) &&
    Color(hColor).darken(0.075).toString()};
  position: sticky;
  right: 0; 
  background: white;
  background: ${({ isNew }) => isNew && newColor};
  background: ${({ highlighted, ind }) => highlighted === ind && hColor};
`;

export const RelCont = styled.div`
  position: relative;
  width: 100%;
`;

export const TD3 = styled(TD)`
  font-size: 11.5px;
  font-weight: 300;
`;

export const Table2 = styled(Table)`
  width: max-content;
  margin-left: auto;
`;

export const THead2 = styled.thead`
  width: 50vw;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TH5 = styled.th`
  width: 30%;
  position: sticky;
  right: 0;
  z-index: 1;
  padding: 0;
`;

export const TH6 = styled.th`
  width: 70%;
  border-left: 1px solid ${Color(tableBorderColor).lighten(0.6).toString()};
`;

export const TH8 = styled(TH5)`
  border-bottom: 1px solid ${Color(tableBorderColor).lighten(0.6).toString()};
`;

export const TH9 = styled(TH6)`
  border-bottom: 1px solid ${Color(tableBorderColor).lighten(0.6).toString()};
`;

export const OtherPages = styled.div`
  width: max-content;
  border-radius: 4px;
  margin-left: auto;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 30px;
  @media(max-width: ${mobileRes2}) {
    font-size: 13px;
    height: 50px;
    padding-left: 15px;
  }
`;

export const OtherPagesTotal = styled.div`
  border-left: 1px solid ${Color(tableBorderColor).lighten(0.6).toString()};
  height: 100%;
  text-align: right;
  width: max-content;
  margin-left: 30px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  @media(max-width: ${mobileRes2}) {
    padding: 0px 15px;
    margin-left: 15px;
  }
`;

export const TD3Entry = styled.div`
  width: max-content;
  padding: 23px 30px;
  padding-left: 0px;
  width: 100%;
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const cashColor = Color('#40b017').darken(0.25).toString();
export const redColor = '#eb364b';
export const CashIn = styled.div<{ isSum?: boolean }>`
  color: ${cashColor};
  font-weight: 700;
  font-size: 13.5px;
  letter-spacing: 0.75px;
  display: inline-block;
  position: ${({ isSum }) => isSum ? 'static' : 'absolute'};
  bottom: 50%;
  right: 30px;
  transform: ${({ isSum }) => !isSum && 'translateY(50%)'};
  padding: 5px 10px;
  padding-right: 0px;
  padding-left: ${({ isSum }) => isSum && '0px'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${({ isSum }) => isSum ? '65vw' : 'calc(100% - 50px)'};
  border-radius: 3px;
  width: max-content;
  margin-left: auto;
  @media(max-width: ${mobileRes2}) {
    font-size: 13px;
    max-width: ${({ isSum }) => isSum ? '65vw' : 'calc(100% - 20px)'};
    right: 15px;
  }
  &:hover {
    text-overflow: clip;
    white-space: normal;
    overflow: visible;
    background: inherit;
    word-break: break-all;
    max-width: none;
    width: max-content;
  }
`;

export const CashOut = styled(CashIn)`
  color: ${redColor};
`;

export const MoreCont = styled.div`
  color: ${Color('grey').lighten(0.5).toString()};
  position: absolute;
  right: 5px;
  bottom: 50%;
  width: max-content;
  height: max-content;
  overflow: visible;
  transform: translateY(50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media(max-width: ${mobileRes2}) {
    right: 1px;
  }
`;

export const RelMoreCont = styled.div`
  position: relative;
  width: max-content;
  height: max-content;
`;

export const MoreIcon0 = styled.div`
  width: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: ${lFColor};
    cursor: pointer;
  }
`;

export const MoreIconsCont = styled.div`
  width: max-content;
  height: 0px;
  pointer-events: none;
  overflow: hidden;
  position: absolute;
  top: -97px;
  right: 0;
  opacity: 0;
  padding: 20px;
  padding-right: 1px;
  padding-bottom: 1px;
  transition: all ease-out 100ms;
`;

export const MoreIconsCont1 = styled.div`
  width: 35px;
  background: white;
  border-radius: 2px;
  border: 1px solid grey;
`;

export const MoreIcon = styled.div`
  color: ${Color('grey').lighten(0.3).toString()};
  width: 100%;
  height: 35px;
  padding-top: 5px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: ${redColor};
    background: ${Color(redColor).lighten(0.7).toString()};
  }
`;

export const MoreIcon1 = styled(MoreIcon)`
  padding-top: 3px;
  padding-bottom: 5px;
  &:hover {
    color: #525252;
    background: ${Color('lightgrey').lighten(0.1).toString()};
  }
`;

export const EditAmountCont = styled(MoreIconsCont)`
  top: -148px;
  width: calc(100vw - 35px);
  min-width: 0;
  max-width: 500px;
  padding-top: 40px;
  padding-bottom: 20px;
  @media(max-width: 400px) {
    padding-left: 0px;
  }
`;

export const EditAmountCont1 = styled.div`
  background: ${Color(lFColor).lighten(0.2).toString()};
  padding: 5px 10px;
  padding-top: 0px;
  width: 100%;
  margin-left: auto;
  max-width: 350px;
  height: 100%;
  box-shadow: 0px 0px 1px 0.5px ${Color('#525252').darken(0.1).toString()};
  -webkit-box-shadow: 0px 0px 1px 0.5px ${Color('#525252').darken(0.1).toString()};
  -moz-box-shadow: 0px 0px 1px 0.5px ${Color('#525252').darken(0.1).toString()};
  border-radius: 2px;
`;

export const EditThisCont = styled.div`
  color: white;
  overflow: hidden;
  display: block;
  z-index: 2;
`;

export const HeaderPart = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 7px;
`;

export const Header3 = styled.div`
  font-size: 13px;
  font-weight: 600;
  width: max-content;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 77px);
`;

export const IconCont2 = styled.div`
  transform: translate(3px, -1px);
  height: 20px;
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  width: 20px;
  &:hover {
    color: ${redColor};
  }
`;

export const AmountPart = styled.div`
  font-size: 12px;
  margin-top: 5px;
  height: 48px;
  display: flex;
  width: calc(100% - 22px);
  position: relative;
`;

export const Info1 = styled.div<{ isError?: boolean }>`
  font-size: 10.5px;
  color: ${({ isError }) => isError ? 'red' : Color('lightgrey').darken(0.3).toString()};
  position: absolute;
  top: 2px;
  padding-left: 9px;
  pointer-events: none;
`;

export const Input1 = styled.input<{ isError: boolean, amount: number }>`
  height: 100%;
  padding: 0px 8px;
  padding-top: 16px;
  border-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0;
  flex: 1;
  border: ${({ isError }) => isError && '2px solid red'};
  font-weight: 700;
  font-size: 18px;
  min-width: 0;
  letter-spacing: 0.75px;
  color: ${({ amount }) => amount < 0 ? 'red' : cashColor};
  &:focus {
    outline: none;
    border: 3px solid ${orange};
    border-color: ${({ isError }) => isError && 'red'};
  }
`;

export const Button = styled.div`
  font-size: 16px;
  height: 100%;
  display: flex;
  padding: 0px 18px;
  justify-content: center;
  align-items: center;
  width: max-content;
  font-weight: 700;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: ${Color(lFColor).lighten(0.5).toString()};
  &:hover {
    background: ${orange};
    cursor: pointer;
  }
`;

export const Username = styled.span`
  padding: 0px 6px;
  margin: 0;
  border: 1px solid ${topBarColor2};
  transform: translateY(-1px);
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  width: max-content;
  font-weight: 600;
  margin: 0px 2px;
  font-size: 11px;
  color: ${topBarColor2};
  @media(max-width: ${mobileRes2}) {
    font-size: 10.5px;
    padding-top: 1px;
  }
`;

export const Bold = styled.span`
  font-weight: 600;
  color: ${Color('#525252').darken(0.15).toString()};
`;

export const OTA = styled.span`
  font-weight: 500;
  color: white;
  padding: 3px 9px;
  padding-bottom: 4px;
  padding-left: 4px;
  white-space: nowrap;
  background: ${Color(topBarColor2).lighten(0.4).toString()};
  font-size: 12px;
  border-radius: 3px;
  gap: 5px;
  width: max-content;
`;

export const Room = styled(Username)`
  padding: 1px 9px;
  font-weight: 600;
  padding-left: 4px;
  transform: translateY(5px);
  border: none;
  background: ${Color(lFColor).lighten(0.6).toString()};
  color: white;
  letter-spacing: 0.25px;
  z-index: 1;
`;

export const RoomText = styled.div`
  transform: translateY(0px);
`;

export const External = styled(Room)`
  background: ${Color(lFColor).lighten(2.3).toString()};
  color: ${Color(lFColor).lighten(0.3).toString()};
  letter-spacing: 0px;
  font-size: 11.5px;
`;

export const ExternalText = styled.div`
  transform: translateY(-1px);
`;

export const TFoot = styled.div<{ isCashGroup: boolean }>`
  height: ${({ isCashGroup }) => isCashGroup ? mainFooterSize : pagesFooterSize};
  position: sticky;
  right: 0;
  bottom: ${({ isCashGroup }) => isCashGroup ? `-${mainFooterSize}` : `-${pagesFooterSize}`};
  width: 100%;
  background: white;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  @media(max-width: ${logRes1}) {
    border-left: none;
  }
  @media(max-width: ${cashFooterRes}) {
    height: ${({ isCashGroup }) => isCashGroup && `calc(${mainFooterSize} + ${cashFooterSize})`};
    bottom: ${({ isCashGroup }) => isCashGroup && `calc(-1 * (${mainFooterSize} + ${cashFooterSize}))`};
  }
`;

export const TotalFooter = styled.div`
  height: ${totalFooterSize};
  padding: 0px 35px;
  padding-left: 30px;
  width: 100%;
  text-align: right;
  border-top: 1px solid ${Color(tableBorderColor).lighten(0.3).toString()};
  border-bottom: 1px solid ${Color(tableBorderColor).lighten(0.75).toString()};
  display: flex;
  align-items: center;
  @media(max-width: ${cashFooterRes}) {
    border-bottom: 1px solid ${Color(tableBorderColor).lighten(0.3).toString()};
  }
  @media(max-width: ${mobileRes2}) {
    padding: 0px 15px;
  }
`;

export const TotalCont = styled.div`
  display: inline-flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
`;

export const TotalCont1 = styled.div`
  border-left: 1px solid ${Color(tableBorderColor).lighten(0.75).toString()};
  height: 100%;
  width: max-content;
  padding-left: 25px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  @media(max-width: ${cashFooterRes}) {
    border-left: 1px solid ${Color(tableBorderColor).lighten(0.3).toString()};
  }
`;

export const Total = styled.div<{ isNegative: boolean }>`
  color: ${({ isNegative }) => isNegative ? redColor : cashColor};
  font-weight: 700;
  font-size: 21px;
  letter-spacing: 0.35px;
  margin-left: auto;
`;

export const TotalText = styled.div`
  color: ${Color(borderColor).darken(0.25).toString()};
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.35px;
`;

export const FormatLogs1 = styled.div`
  flex: 1;
  @media(max-width: ${cashFooterRes}) {
    display: none;
  }
`;

export const logSmallRes = '395px';
export const logSmallRes2 = '378px';
export const logMiniRes = '344px';
export const FormatLogs = styled.div`
  border-radius: 2px;
  width: max-content;
  height: max-content;
  margin-right: auto;
  display: flex;
  gap: 12px;
  @media(max-width: ${logSmallRes}) {
    gap: 7px;
  }
`;

export const FormatSet = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  color: #525252;
  font-size: 11px;
  gap: 5px;
  padding: 6px 9px;
  padding-right: 8px;
  border-radius: 3px;
  background: ${Color('lightgrey').lighten(0.15).toString()};
  cursor: pointer;
  @media(max-width: ${logSmallRes}) {
    font-size: 11px;
  }
  &:hover {
    background: ${Color('lightgrey').lighten(0.07).toString()};
  }
`;

export const ShowCashIn = styled.div`
`;

export const Select = styled.input`
  height: 13px;
  width: 13px;
  margin: 0;
  margin-bottom: 0px;
  pointer-events: none;
`;

export const ShowCashOut = styled(ShowCashIn)`
`;

export const All = styled(ShowCashIn)`
`;

export const CashFooter = styled.div`
  width: 100%;
  height: ${cashFooterSize};
  display: none;
  border-bottom: 1px solid ${Color(tableBorderColor).lighten(0.75).toString()};
  @media(max-width: ${cashFooterRes}) {
    display: block;
  }
`;

export const FormatLogs2 = styled.div`
  display: flex;
  align-items: center;
  padding-left: 30px;
  height: 100%;
  @media(max-width: ${mobileRes2}) {
    padding-left: 15px;
  }
`;

export const PagesFooter = styled.div<{ isCashGroup: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${pagesFooterSize};
  width: 100%;
  margin-left: 0px;
  border-top: ${({ isCashGroup }) => !isCashGroup && `1px solid ${Color(tableBorderColor)
    .lighten(0.2).toString()}`};
`;

export const Pages = styled.div`
  display: flex;
  align-items: center;
`;

export const FormatLogs3 = styled(FormatLogs2)`
  margin-right: auto;
`;

export const PaginationCont = styled.div`
  margin-left: auto;
  margin-right: 30px;
  @media(max-width: ${mobileRes2}) {
    margin-right: 10px;
  }
`;

export const InfoContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: calc(100svh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

export const InfoCont = styled.div`
  background: white;
  padding: 25px 30px;
  border-radius: 5px;
  max-width: calc(100vw - 15px);
  width: max-content;
  margin: auto;
  box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  -webkit-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  -moz-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
`;

export const NoResults = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #525252;
  margin: auto;
`;

export const Info = styled(NoResults)`
  font-size: 14px;
  font-weight: 300;
  margin-top: 3px;
`;
