import React, { useEffect, useState } from 'react';
import * as S from './WillExpire.styled';
import Clear from '@mui/icons-material/Clear';
import Attention from '@mui/icons-material/ReportGmailerrorredSharp';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectHotelData, setModalParts } from 'pages/redux';
import ExtendSub from '../../Settings/components/ExtendSubscription';
import { usePath } from 'pages/hooks';
import { getDayDifferenceText } from 'pages/Metrics/components/MetricsTime';

const WillExpire = () => {
  const [isShow, setIsShow] = useState<boolean>(true);
  const hotelData = useAppSelector(selectHotelData);
  const dispatch = useAppDispatch();
  const { isHomePath } = usePath();

  const dayDiff0 = new Date(hotelData?.expires || '').getTime() - new Date().getTime();
  const dayDiff = Math.trunc(dayDiff0 / (60 * 60 * 24 * 1000));

  useEffect(() => {
    const iBCont = document.getElementById('wE_cont');
    if (iBCont && isShow) {
      iBCont.style.height = '45px';
    } else if (iBCont) {
      iBCont.style.height = '0px';
      iBCont.style.opacity = '0';
      setTimeout(() => { iBCont.style.display = 'none'; }, 251);
    }
  }, [isShow]);

  if (isHomePath || !hotelData?.maxRooms || dayDiff > 7) return null;

  const onClickExtend = () => {
    dispatch(setModalParts({
      component: <ExtendSub onExit={() => { dispatch(setModalParts(null)); }} />,
      exitOnBgClick: () => dispatch(setModalParts(null))
    }));
  };

  return (
    <S.Container id="wE_cont">
      <S.IconCont0>
        <Attention fontSize="medium" />
      </S.IconCont0>
      <S.Label>
        Your subscription will expire in
        {' '}
        {/* {dayDiff ? `${dayDiff} day${dayDiff === 1 ? '' : 's'}` :
          `${hoursDiff} hour${hoursDiff === 1 ? '' : 's'}`} */}
        {getDayDifferenceText(new Date(), new Date(hotelData?.expires || 0))}
        <S.Extend onClick={() => onClickExtend()}>
          Extend
        </S.Extend>
      </S.Label>
      <S.IconCont onClick={() => setIsShow(false)}>
        <Clear fontSize="medium" />
      </S.IconCont>
    </S.Container>
  );
};

export default WillExpire;
