import React, { useState } from 'react';
import * as S from './SelectStaff.styled';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import { User, rolesMain1 } from 'pages/Settings/redux';
import Loader from 'pages/components/Loader/Loader';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  Branch, selectHotelData, setBranches
} from 'pages/redux';
import { useLogs } from '../hooks';
import { useSocketEmit } from 'pages/Navigation/hooks';

const SelectStaff = ({
  selectedBranch,
  onExit,
  users
} : {
  selectedBranch: string,
  onExit: Function,
  users: User[] | null,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const hotelData = useAppSelector(selectHotelData);
  const { branches } = hotelData || {};
  let thisBranch: Branch | undefined = branches?.find((b) => b.name === selectedBranch);
  const [checked, setChecked] = useState<string[]>([...(thisBranch?.staff || [])]);
  const roles = [...rolesMain1];
  const { updateLocalBranch } = useLogs();
  const socketEmit = useSocketEmit();

  const dispatch = useAppDispatch();

  const onSelectChange = (user: User) => {
    if (checked.includes(user.email)) {
      setChecked(checked.filter((c) => c !== user.email));
      return;
    }
    setChecked([...checked, user.email]);
  };

  const onClickSave = async () => {
    if (checked.sort().join('') === [...(thisBranch?.staff || [])].sort().join('')) {
      onExit();
      return;
    }

    thisBranch = { name: thisBranch?.name || '', staff: checked || [] };
    const newBranches = [...(branches || []).filter(
      (b) => b.name !== thisBranch?.name
    ), thisBranch];
    setIsLoading(true);

    const res = await dispatch(setBranches(JSON.stringify(newBranches)));

    setIsLoading(false);

    if (res.status === 'success') {
      socketEmit('update_branch', { branch: thisBranch });
      updateLocalBranch(thisBranch);
      toast(`Sucessfully edited ${selectedBranch} user`, { type: 'success' });
      onExit();
      return;
    }
    toast(res.data, { type: 'error' });
  };

  const staffAdded = users?.reduce((a, b) => a + (checked.includes(b.email) ? 1 : 0), 0);

  return (
    <>
      {
        isLoading ? <Loader skipSideBar isSticky /> : (
          <S.Container>
            <EscapeButton onClick={() => onExit()} isNoBorder />
            <S.Header>
              {`Add staff to ${selectedBranch} branch`}
            </S.Header>
            {
              users && users.length ? (
                <S.FlexCont>
                  <S.StaffCont>
                    {
                      users?.map((user) => {
                        return (
                          <S.Staff key={`staffCont_${user.username}`}>
                            <S.Input
                              type="checkbox"
                              checked={checked.includes(user.email)}
                              onChange={() => onSelectChange(user)}
                            />
                            <S.StaffName>{user.username}</S.StaffName>
                            <S.Role>{`(${roles[Number(user.permission)]})`}</S.Role>
                          </S.Staff>
                        );
                      })
                    }
                  </S.StaffCont>
                </S.FlexCont>
              ) : (
                <S.NoStaffCont>
                  <S.NoStaff>No staff to add</S.NoStaff>
                </S.NoStaffCont>
              )
            }
            <S.ButtonSect>
              <S.Info>
                {staffAdded}
                {' staff added'}
              </S.Info>
              <S.MainButton onClick={() => onClickSave()}>
                Save
              </S.MainButton>
            </S.ButtonSect>
          </S.Container>
        )
      }
    </>
  );
};

export default SelectStaff;
