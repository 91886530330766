import Color from 'color';
import { orange } from 'pages/Hospitality/components/Rooms.styled';
import { redColor } from 'pages/Logs/components/LogTable.styled';
import { lFColor, lFColor0 } from 'pages/components/LFHeader/LFHeader.styled';
import styled from 'styled-components';

export const Container = styled.div`
  width: 95vw;
  max-width: 340px;
  color: #525252;
`;

export const HeaderPart = styled.div`
  width: 100%;
  padding: 12px 10px;
  background: ${lFColor};
  color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const HeaderItems = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 35px;
  height: 50px;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextPart = styled.div`
  margin-left: 10px;
  margin-top: 2px;
`;

export const Customer = styled.div`
  font-size: 11px;
  color: ${Color('lightgrey').darken(0.15).toString()};
  font-weight: 700;
`;

export const Walkin = styled.div`
  font-size: 30px;
  color: ${Color(lFColor0).lighten(0.6).toString()};
  font-weight: 700;
  margin-top: -3px;
`;

export const ActionPart = styled.div`
  padding: 12px 15px;
  padding-top: 35px;
  width: 100%;
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const Label = styled.div`
  font-size: 13px;
  position: relative;
`;

export const Info = styled.div<{ isError?: boolean }>`
  font-size: 10px;
  position: absolute;
  left: 9px;
  bottom: -23px;
  color: ${({ isError }) => isError ? 'red' : Color('lightgrey').darken(0.135).toString()};
  background: white;
  width: calc(100% - 22px);
  border: 0;
  box-shadow: 0;
`;

export const InputArea = styled.textarea<{ isError: boolean }>`
  padding: 0px 8px;
  padding-top: 22px;
  width: 100%;
  height: 125px;
  margin-top: 5px;
  border-radius: 3px;
  resize: none;
  font-size: 14px;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, Poppins;
  border: ${({ isError }) => isError && '1px solid red'};
  &:focus {
    outline: none;
    border: 2px solid #525252;
    border-color: ${({ isError }) => isError && 'red'};
    padding-left: 7px;
  }
`;

export const ButtonsCont = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 10px;
`;

export const Button0 = styled.div`
  padding: 9px 14px;
  background: ${lFColor};
  color: white;
  border-radius: 3px;
  font-weight: 700;
  font-size: 13px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background: ${orange};
    color: white;
  }
`;

export const Button1 = styled(Button0)`
  padding: 9px 0px;
  padding-right: 5px;
  background: none;
  color: ${lFColor};
  &:hover {
    background: none;
    color: ${redColor};
  }
`;
