import React from 'react';
import * as S from './Table1.styled';
import TableBody from './TableBody1';
import { LogSheetEntry } from 'pages/Logs/redux';

const Table1 = function Table1({
  selectedSheet,
} : {
  selectedSheet: LogSheetEntry[],
}) {
  const headers = Object.keys(selectedSheet[0] || {}).filter((k) => k !== 'sheetName');

  const formatKeys: any = {
    updatedBy: 'uploaded by',
    message: 'product',
    updatedAsOf: 'uploaded on'
  };

  return (
    <S.Container id="tableCont">
      <S.TableDiv>
        <S.Table>
          <thead>
            <tr>
              { (headers || []).map((key) => (
                <S.TH key={`tmptableheader_${key}`}>
                  <S.THText>{formatKeys[key] || key}</S.THText>
                </S.TH>
              ))}
            </tr>
          </thead>
          <TableBody selectedSheet={selectedSheet} />
        </S.Table>
      </S.TableDiv>
    </S.Container>
  );
};

export default Table1;
