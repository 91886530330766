import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { callEndpoint } from 'redux/global';
import { RootState } from 'redux/store';
import { IResponse } from 'types';

export type User = {
  email: string,
  username: string,
  permission: string,
}

export type RoomType = {
  name: string,
  id: string,
  adults: number,
  children: number,
  infants: number,
  img: string,
  imgs: string[],
  coRoomTypeId: string
}

export type DateChange = {
  start: string,
  end: string,
  rate: string
}

export type Rate = {
  id: string,
  name: string,
  baseRate: string,
  weekendRate: string,
  dates: DateChange[],
  occupancy: number,
  mealType: ('Breakfast' | 'Lunch' | 'Dinner')[],
  roomTypeId: string,
  coRateId: string
}

export type Services = {
  meals: {
    date: Date,
    ids: string[],
    priorIds: string[]
  }
}

export type SettingsParts = {
  users: User[] | null,
  groups: string[],
  roomTypes: RoomType[],
  rates: Rate[],
  services: Services
}

type SettingsState = {
  tmpRates: Rate[],
  parts: SettingsParts
}

const earlyToday = new Date();
earlyToday.setHours(0, 0, 0, 0);

export const defaultServices = {
  meals: {
    date: earlyToday,
    ids: [],
    priorIds: []
  }
};

const initialState: SettingsState = {
  tmpRates: [],
  parts: {
    users: null,
    groups: [],
    roomTypes: [],
    rates: [],
    services: defaultServices
  }
};

export const counterSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<User[] | null>) => {
      state.parts.users = action.payload;
    },
    setGroups: (state, action: PayloadAction<string[]>) => {
      state.parts.groups = action.payload;
    },
    setRoomTypes: (state, action: PayloadAction<RoomType[]>) => {
      state.parts.roomTypes = action.payload;
    },
    setRates: (state, action: PayloadAction<Rate[]>) => {
      state.parts.rates = action.payload;
    },
    setTmpRates: (state, action: PayloadAction<Rate[]>) => {
      state.tmpRates = action.payload;
    },
    setServices: (state, action: PayloadAction<Services>) => {
      state.parts.services = action.payload;
    },
    setSettingsParts: (state, action: PayloadAction<SettingsParts>) => {
      state.parts = action.payload;
    },
  },
});

export const {
  setUsers, setGroups, setRoomTypes, setRates, setTmpRates, setServices, setSettingsParts
} = counterSlice.actions;

export const selectUsers = (state: RootState) => state.settings.parts.users;
export const selectGroups = (state: RootState) => state.settings.parts.groups;
export const selectRoomTypes = (state: RootState) => state.settings.parts.roomTypes as RoomType[];
export const selectRates = (state: RootState) => state.settings.parts.rates;
export const selectTmpRates = (state: RootState) => state.settings.tmpRates;
export const selectServices = (state: RootState) => state.settings.parts.services;
export const selectSettingsParts = (state: RootState) => state.settings.parts;

export default counterSlice.reducer;

export type Details = {
  phoneNumber: string,
  user: string
}

export const rolesMain1 = ['Worker', 'Front desk', 'Front desk 2', 'Sub Manager', 'Manager', 'Owner', 'Tech team'] as const;
export const rolesMain = ['Worker', 'Front desk', 'Front desk 2', 'Sub Manager', 'Manager', 'Owner'] as const;
export type Roles = typeof rolesMain1[number];

export const getInfo = () => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'info',
      method: 'GET',
    }),
  );
  return res;
};

export const saveNumbers = (body: Details[]) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'savenumbers',
      body: { numbers: body },
      method: 'PATCH',
    }),
  );
  return res;
};

export const setEmailRec =
(emailRec: string | null) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'setemailreceiver',
      body: { emailRec },
      method: 'PATCH',
    }),
  );
  return res;
};

type User2 = {
  path: string
} & User;

export const addStaff = (body: User2) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'addstaff',
      body,
      method: 'POST',
    }),
  );
  return res;
};

export const editStaff = (body: User) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'editstaff',
      body,
      method: 'PATCH',
    }),
  );
  return res;
};

export const deleteStaff = (email: string) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'deletestaff',
      body: { email },
      method: 'DELETE',
    }),
  );
  return res;
};

export const saveDisplayNumber =
(displayNumber: string) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'savedisplaynumber',
      body: { displayNumber },
      method: 'PATCH',
    }),
  );
  return res;
};

export const saveCurrency =
(newCurrency: string) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'savecurrency',
      body: { newCurrency },
      method: 'PATCH',
    }),
  );
  return res;
};

type Subscribe = {
  expires: string,
  maxRooms: string,
  channelExpiry: string | undefined,
  billingDate: string,
}

export const subscribe = (s: Subscribe) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'subscribe',
      body: { ...s },
      method: 'PATCH',
    }),
  );
  return res;
};

export const verifyPayment = (trans: Record<string, any>) => async (dispatch: Function)
  : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'verifypayment',
      method: 'POST',
      body: { trans }
    }),
  );
  return res;
};

type ExtendSub = {
  expires: string,
  channelExpiry: string | undefined,
  billingDate: string,
}

type ExtendSub1 = {
  keyCardExpiry: string
}

export const extendSubscription = (es: ExtendSub | ExtendSub1) => async (dispatch: Function)
  : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'extendsubscription',
      method: 'PATCH',
      body: { ...es }
    }),
  );
  return res;
};

export const upgrade = (maxRooms: string) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'upgrade',
      body: { maxRooms },
      method: 'PATCH',
    }),
  );
  return res;
};

type AddGroup = {
  addGroup: string,
}

export const addGroup = (ag: AddGroup) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'addgroup',
      body: { ag },
      method: 'PATCH',
    }),
  );
  return res;
};

type UpdateRoomTypes = {
  roomTypes: RoomType[],
  roomType?: RoomType,
  isUpdate?: boolean,
  isDelete?: boolean
}
export const updateRoomType = (updateRoomT: UpdateRoomTypes) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'updateroomtypes',
      body: { updateRoomT },
      method: 'PATCH',
    }),
  );
  return res;
};

type UpdateRates = {
  rates: Rate[],
  rate?: Rate,
  isUpdate?: true,
  isDelete?: true,
};

export const updateRates = (ratesBody: UpdateRates) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'updaterates',
      body: { ratesBody },
      method: 'PATCH',
    }),
  );
  return res;
};

type AddPhotos = {
  photos: string[],
  roomTypeName?: string,
}

export const addPhotos = (ap: AddPhotos) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'addphotos',
      body: { ...ap },
      method: 'POST',
      checkLimit: 'restrictions'
    }),
  );
  return res;
};

type DeletePhoto = {
  ids: string[],
  roomTypeName: string,
  isNoLog?: boolean
}

export const deletePhoto = (dp: DeletePhoto) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'deletephoto',
      body: { ...dp },
      method: 'DELETE'
    }),
  );
  return res;
};

export type RoomTypeCO = {
  roomCount: number,
  roomType: RoomType
}

export const addRoomTypeCO = (art: RoomTypeCO) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'addroomtypeco',
      body: { ...art },
      method: 'POST',
      checkLimit: 'both'
    }),
  );
  return res;
};

export const updateRoomTypeCO = (urt: RoomTypeCO) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'updateroomtypeco',
      body: { ...urt },
      method: 'PUT',
      checkLimit: 'availability'
    }),
  );
  return res;
};

export const deleteRoomTypeCO = (coRoomTypeId: string) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'deleteroomtypeco',
      body: { coRoomTypeId },
      method: 'DELETE'
    }),
  );
  return res;
};

export type RatePlanCO = {
  roomType: RoomType | undefined,
  ratePlan: Rate | undefined
}

export const addRatePlanCO = (arp: RatePlanCO) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'addrateplanco',
      body: { ...arp },
      method: 'POST',
      checkLimit: 'restrictions'
    }),
  );
  return res;
};

export const updateRatePlanCO = (urp: RatePlanCO) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'updaterateplanco',
      body: { ...urp },
      method: 'PUT',
      checkLimit: 'restrictions'
    }),
  );
  return res;
};

export type DeleteRatePlanCO = Omit<RatePlanCO, 'roomType'>;

export const deleteRatePlanCO = (drp: DeleteRatePlanCO) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'deleterateplanco',
      body: { ...drp },
      method: 'DELETE',
      checkLimit: 'restrictions'
    }),
  );
  return res;
};

export type DateRestriction = {
  start: Date,
  end: Date,
  days?: ('mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su')[]
  rate: number
};

export type RestrictionCO = {
  property_id: string,
  rate_plan_id: string
  date_from: string,
  date_to: string,
  days?: ('mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su')[]
  rate: number
};

export const updateRestrictionsCO = (values: RestrictionCO[]) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'updaterestrictionsco',
      body: { values },
      method: 'POST'
    }),
  );
  return res;
};

export type AvailabilityCO = {
  property_id: string,
  room_type_id: string
  date_from: string,
  date_to: string,
  availability: number
};

export const updateAvailabilityCO = (values: AvailabilityCO[]) => async (dispatch: Function)
: Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'updateavailabilityco',
      body: { values },
      method: 'POST',
      checkLimit: 'availability'
    }),
  );
  return res;
};
