import { useHistory, useLocation } from 'react-router-dom';
import {
  AvailabilityCO,
  DateRestriction,
  Rate,
  RatePlanCO,
  RestrictionCO,
  RoomType,
  RoomTypeCO, addRatePlanCO, addRoomTypeCO, deleteRatePlanCO, deleteRoomTypeCO,
  updateAvailabilityCO,
  updateRatePlanCO,
  updateRestrictionsCO,
  updateRoomTypeCO,
  DeleteRatePlanCO,
  updateRates,
  setRates,
  updateRoomType,
  setRoomTypes
} from './Settings/redux';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useSettings } from './Settings/hooks';
import {
  handlePropertyCO, selectHotelData, setHotelData, setIsSyncCORooms
} from './redux';
import { selectPermission } from 'redux/global';
import { IResponse } from 'types';
import { selectRooms } from './Hospitality/redux';

export const usePath = () => {
  const homePaths = ['/signin', '/register', '/signup', '/forgot', '/addH', '/pricing', '/unsubscribe'];
  const location = useLocation();
  const history = useHistory();
  const permission = useAppSelector(selectPermission);

  const isHomePath = !!(location.pathname === '/' || homePaths.find((p) => location.pathname.includes(p)));

  const navigateToHome = (perm?: number | null) => {
    const permission1 = (perm || perm === 0) ? perm : permission;
    if (permission1 === 0) {
      history.push('/logs');
      return;
    } if (!permission1) {
      history.push('/');
      return;
    } else if (permission1 <= 3) {
      history.push('/rooms');
      return;
    }
    history.push('/dashboard');
  };

  return { isHomePath, navigateToHome };
};

export type AddRestrictionsCO = {
  roomType: RoomType | undefined,
  ratePlan: Rate | undefined
}

export type UpdateRestrictionsCO = {
  roomType: RoomType | undefined,
  ratePlan: Rate | undefined,
  restrictions: DateRestriction[]
}

export const useCX = () => {
  const dispatch = useAppDispatch();
  const hotelData = useAppSelector(selectHotelData);
  const {
    getDateRestrictions, roomTypes, rates, getFullAvailability
  } = useSettings();
  const rooms = useAppSelector(selectRooms);

  const COUpdateAvailability = async (availabilityCO: AvailabilityCO[]) => {
    const availability: AvailabilityCO[] = availabilityCO.map((a) => {
      const startD = new Date(a.date_from);
      startD.setMinutes(startD.getMinutes() - startD.getTimezoneOffset());
      const endD = new Date(a.date_to);
      endD.setMinutes(endD.getMinutes() - endD.getTimezoneOffset());
      return {
        ...a,
        date_from: startD.toISOString().slice(0, 10),
        date_to: endD.toISOString().slice(0, 10),
      };
    });
    const res = await dispatch(updateAvailabilityCO(availability));
    return res;
  };

  const CONewRestrictions = async (uData: AddRestrictionsCO[]) => {
    let restrictions: RestrictionCO[] = [];
    uData.forEach((d) => {
      const coUpdateRestrictions: RestrictionCO[] = getDateRestrictions(d.ratePlan).map(
        (r) => {
          const startD = new Date(r.start);
          startD.setMinutes(startD.getMinutes() - startD.getTimezoneOffset());
          const endD = new Date(r.end);
          endD.setMinutes(endD.getMinutes() - endD.getTimezoneOffset());
          return {
            property_id: hotelData?.coId || '',
            rate_plan_id: d.ratePlan?.coRateId || '',
            date_from: startD.toISOString().slice(0, 10),
            date_to: endD.toISOString().slice(0, 10),
            ...(r.days ? { days: r.days } : {}),
            rate: r.rate * 100
          };
        }
      );
      restrictions = [...restrictions, ...coUpdateRestrictions];
    });
    const res = await dispatch(updateRestrictionsCO(restrictions));
    return res;
  };

  const COUpdateRestrictions = async (uData: UpdateRestrictionsCO[]) => {
    let restrictions: RestrictionCO[] = [];
    uData.forEach((d) => {
      const restrictions0: RestrictionCO[] = d.restrictions.map(
        (r) => {
          const startD = new Date(r.start);
          startD.setMinutes(startD.getMinutes() - startD.getTimezoneOffset());
          const endD = new Date(r.end);
          endD.setMinutes(endD.getMinutes() - endD.getTimezoneOffset());
          return {
            property_id: hotelData?.coId || '',
            rate_plan_id: d.ratePlan?.coRateId || '',
            date_from: startD.toISOString().slice(0, 10),
            date_to: endD.toISOString().slice(0, 10),
            ...(r.days ? { days: r.days } : {}),
            rate: r.rate * 100
          };
        }
      );
      restrictions = [...restrictions, ...restrictions0];
    });
    const res = await dispatch(updateRestrictionsCO(restrictions));
    return res;
  };

  const COAddRatePlan = async (ratePlanCO: RatePlanCO) => {
    const res = await dispatch(addRatePlanCO(ratePlanCO));
    return res;
  };

  const COUpdateRatePlan = async (ratePlanCO: RatePlanCO) => {
    const res = await dispatch(updateRatePlanCO(ratePlanCO));
    return res;
  };

  const CODeleteRatePlan = async (deleteRatePlan: DeleteRatePlanCO) => {
    const res = await dispatch(deleteRatePlanCO(deleteRatePlan));
    return res;
  };

  const COUpdateRoomType = async (roomType: RoomTypeCO) => {
    const res = await dispatch(updateRoomTypeCO(roomType));
    return res;
  };

  const COAddRoomType = async (roomType: RoomTypeCO) => {
    const res = await dispatch(addRoomTypeCO(roomType));
    return res;
  };

  const CODeleteRoomType = async (roomTypeId: string) => {
    // lacks channel remove. Unsync from channels here
    const res = await dispatch(deleteRoomTypeCO(roomTypeId));
    return res;
  };

  // const COUndoSync = async () => {
  //   // const newRoomTypes: RoomType[] = [...roomTypes];
  //   // let res: IResponse = { status: 'error', data: 'Could not un-sync' };

  //   // for (let i = 0; i < roomTypes.length; i += 1) {
  //   //   if (roomTypes[i].coRoomTypeId) {
  //   //     res = await CODeleteRoomType(roomTypes[i].coRoomTypeId);
  //   //     newRoomTypes[i] = { ...newRoomTypes[i], coRoomTypeId: '' };
  //   //     // lacks channel remove
  //   //     if (res.status !== 'success') {
  //   //       return res;
  //   //     }
  //   //   }
  //   // }

  //   // const updateRoomT = {
  //   //   roomTypes: newRoomTypes,
  //   // };
  //   // res = await dispatch(updateRoomType(updateRoomT));
  //   // if (res.status === 'success') {
  //   //   dispatch(setRoomTypes(newRoomTypes));
  //   //   // socket emit this
  //   // }

  //   // return res;

  //   return undefined;
  // };

  const CORoomSync = async () => {
    const newRoomTypes: RoomType[] = [...roomTypes];
    const newRates: Rate[] = [...rates];
    let res: IResponse = { status: 'error', data: 'Could not sync' };

    const updateRestrictionsData: AddRestrictionsCO[] = [];
    const availabilityCO: AvailabilityCO[][] = [];
    for (let i = 0; i < roomTypes.length; i += 1) {
      const roomType = { ...roomTypes[i] };
      const ratePlans = rates.filter((r) => r.roomTypeId === roomType.id);

      if (ratePlans.length && !roomType.coRoomTypeId) {
        const roomCount = (rooms || []).reduce((a, b) => a
          + (b.roomTypeId === roomType?.id ? 1 : 0), 0) || 1;
        const updateRoomTypeCOData = { roomCount, roomType };

        res = await COAddRoomType(updateRoomTypeCOData);

        if (res.status === 'success') {
          roomType.coRoomTypeId = res.data;
          newRoomTypes[i] = { ...newRoomTypes[i], coRoomTypeId: res.data };
        } else {
          return res;
        }

        for (let j = 0; j < ratePlans.length; j += 1) {
          const ratePlan = ratePlans[j];

          const updateRatePlan = { ratePlan, roomType };

          res = await COAddRatePlan(updateRatePlan);

          if (res.status === 'success') {
            const ind = newRates.findIndex((r) => r.id === ratePlan.id);
            if (ind > -1) {
              newRates[ind] = { ...newRates[ind], coRateId: res.data };
              updateRestrictionsData.push({ roomType, ratePlan: newRates[ind] });
            } else {
              return { status: 'error', data: 'FE Error 6d6' };
            }
          } else {
            return res;
          }
        }

        availabilityCO.push(getFullAvailability(roomType));
      }
    }

    res = await CONewRestrictions(updateRestrictionsData);

    if (res.status !== 'success') {
      return res;
    }

    const availabilityCO1 = availabilityCO.reduce((acc, val) => acc.concat(val), []);
    res = await COUpdateAvailability(availabilityCO1);

    if (res.status !== 'success') {
      return res;
    }

    res = await dispatch(updateRates({ rates: newRates }));

    if (res.status === 'success') {
      dispatch(setRates(newRates));
    }

    const updateRoomT = {
      roomTypes: newRoomTypes,
    };

    res = await dispatch(updateRoomType(updateRoomT));

    if (res.status === 'success') {
      dispatch(setRoomTypes(newRoomTypes));
    }

    return res;
  };

  const isNoCOPermission = !hotelData?.channelExpiry ||
    +new Date(hotelData.channelExpiry) <= +new Date();

  const COSync = async (checkPermission?: true) => {
    if (checkPermission && isNoCOPermission) {
      return { status: 'success', data: 'FE. No permission' };
    }

    const res = await dispatch(handlePropertyCO());

    if (res.status === 'success') {
      dispatch(setIsSyncCORooms(true));
      if (res.data !== 'exists' && hotelData) {
        dispatch(setRoomTypes(roomTypes.map((r) => { return { ...r, coRoomTypeId: '' }; })));
        dispatch(setHotelData({ ...hotelData, coId: res.data }));
      }
    }

    return res;
  };

  if (isNoCOPermission) {
    return { COSync };
  }

  return {
    COUpdateRoomType,
    COAddRoomType,
    CODeleteRoomType,
    COAddRatePlan,
    COUpdateRatePlan,
    CODeleteRatePlan,
    CONewRestrictions,
    COUpdateRestrictions,
    COUpdateAvailability,
    COSync,
    CORoomSync,
  };
};
