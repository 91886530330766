import React, { useCallback } from 'react';
import { greenColor } from './PieChart';
import { TLPieChartData } from './TwoLevelPieChart';
import { convertDate23, formatN } from 'utils';
import { useWindowSize } from 'pages/Logs/hooks';
import { chartRes1 } from 'pages/Metrics/components/Charts.styled';
import * as S from './hooks.styled';
import { useAppSelector } from 'redux/hooks';
import { selectMetricsFlowType } from 'pages/Metrics/redux';
import { cashColor, redColor } from 'pages/Logs/components/LogTable.styled';
import { purpleColor } from './PieChart.styled';
import { useMetrics } from 'pages/Metrics/hooks';
import { selectCurrency, selectMetricTime } from 'pages/redux';
import { logGroupsRaw } from 'pages/Logs/Logs';

export type PieChartLogData1 = {
  name: string,
  value: number,
  ind?: number,
}

export type OtherField = {
  name: string,
  value: number
}

export type PieChartLogData = {
  name: string,
  value: number,
  ind?: number,
  total: number,
  otherFields: OtherField[]
}

export type AreaChartData = {
  date: string,
  dateF: Date
  total: number,
  daily: number
}

export type SimpleLineData = Record<string, string | number | Date>;
export type SimpleLineChart = {
  chartData: SimpleLineData[],
  typeTotalValues: Record<string, number>
};

export const getDayEnd = (date: Date) => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  return date;
};

// For daily area chart
export const getDayStart = (date: Date | number) => {
  date = new Date(date);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const useChartData = () => {
  const {
    checkFilteredLogs, logGroups, flowType, getFlowTypeValue, checkedMetric
  } = useMetrics();
  const metricTime = useAppSelector(selectMetricTime);

  const getSimpleLineData = useCallback((): SimpleLineChart => {
    const endDate = new Date(metricTime?.endDate || new Date());
    const startDate = new Date(metricTime?.startDate || new Date());

    const logTypeValues: Record<string, number> = {};
    checkedMetric.checked.forEach((logType) => {
      logTypeValues[logType] = 0;
    });

    type ChartDataBuild = {
      values: { [x: string]: number },
      date: Date
    }

    const chartDataBuild: ChartDataBuild[] = [];
    const typeTotalValues: Record<string, number> = {};

    const forceLogs = [
      {
        id: 'xxx2',
        type: '',
        message: '',
        date: getDayEnd(startDate).toString(),
        value: '0',
        updatedAsOf: '',
        count: 0,
      },
      {
        id: 'xxx1',
        type: '',
        message: '',
        date: getDayEnd(endDate).toString(),
        value: '0',
        updatedAsOf: '',
        count: 0,
      },
    ];

    const dates: Record<string, number> = {};
    [...checkFilteredLogs, ...forceLogs].forEach((l) => {
      dates[l.id] = +new Date(l.date);
    });

    [...checkFilteredLogs, ...forceLogs].sort((a, b) => +new Date(a.date) - +new Date(b.date))
      .forEach((log) => {
        chartDataBuild.sort((a, b) => +a.date - +b.date);
        const ind = chartDataBuild.findIndex((b) => dates[log.id] <= +b.date);
        if (log.type) {
          typeTotalValues[log.type] = (typeTotalValues[log.type] || 0)
            + getFlowTypeValue(log);
        }

        if (ind > -1) {
          if (log.type) {
            chartDataBuild[ind].values[log.type] += getFlowTypeValue(log);
          }
        } else {
          if (chartDataBuild.length > 0) {
            const tmp = [...chartDataBuild];
            const cDataBuild: ChartDataBuild = { ...tmp[chartDataBuild.length - 1] };
            const cDataBuildTotal = Object.entries(cDataBuild.values)
              .reduce((a, [k, v]) => a + (k === 'total' ? 0 : v), 0);

            chartDataBuild[chartDataBuild.length - 1] = {
              date: new Date(cDataBuild.date),
              values: {
                ...cDataBuild.values,
                total: cDataBuildTotal
              }
            };
          }

          let cDataBuild: ChartDataBuild = { date: new Date(), values: {} };
          if (chartDataBuild.length > 0) {
            const tmp = [...chartDataBuild];
            const values = { ...tmp[chartDataBuild.length - 1].values };
            delete values.total;

            cDataBuild = {
              ...tmp[chartDataBuild.length - 1],
              values
            };
          }

          const newChartBuild: ChartDataBuild = {
            date: getDayEnd(new Date(log.date)),
            values: chartDataBuild.length > 0 ?
              {
                ...cDataBuild.values,
                ...(log.type ? {
                  [log.type]: cDataBuild.values[log.type] + getFlowTypeValue(log)
                } : {})
              }
              :
              {
                ...logTypeValues,
                ...(log.type ? {
                  [log.type]: getFlowTypeValue(log)
                } : {})
              }
          };

          chartDataBuild.push(newChartBuild);
        }
      });

    if (chartDataBuild.length > 0) {
      chartDataBuild[chartDataBuild.length - 1] = {
        ...chartDataBuild[chartDataBuild.length - 1],
        values: {
          ...chartDataBuild[chartDataBuild.length - 1].values,
          total: Object.entries(chartDataBuild[chartDataBuild.length - 1].values)
            .reduce((a, [, v]) => a + v, 0)
        }
      };
    }

    const chartData: SimpleLineData[] = chartDataBuild.map((b) => {
      return {
        name: convertDate23(b.date),
        date: b.date,
        ...b.values
      };
    });

    Object.entries(typeTotalValues).forEach(([k, v]) => {
      if (!v) {
        chartData.forEach((d) => {
          delete d[k];
        });
        delete typeTotalValues[k];
      }
    });

    const max = 8;
    const keysSorted = Object.keys(typeTotalValues).sort(
      (a, b) => typeTotalValues[b] - typeTotalValues[a]
    );

    const initialLen = Object.keys(typeTotalValues).length - 1;
    let keys = keysSorted.filter(([,], i) => i < Math.ceil((max - 1) / 2)
      || initialLen - i < Math.trunc((max - 1) / 2));

    const othersCounts: number[] = [];
    if (keysSorted.length - keys.length >= 1) {
      chartData.forEach((d) => {
        let othersCount = 0;
        Object.entries(d).forEach(([k, v]) => {
          if (!keys.includes(k) && typeof v === 'number' && k !== 'total') {
            othersCount += v;
          }
        });
        othersCounts.push(othersCount);
      });
    } else {
      keys = keysSorted;
    }

    keys.push('total');

    Object.entries(typeTotalValues).forEach(([k]) => {
      if (!keys.includes(k)) {
        chartData.forEach((d, i) => {
          delete d[k];
          if (othersCounts.length) {
            d.Others = othersCounts[i];
          }
        });
        delete typeTotalValues[k];
      }
    });

    if (othersCounts.length) {
      // So others can be found
      typeTotalValues.Others = 0;
    }

    // console.log(chartData);
    // console.log(typeTotalValues, typeTotalValues);

    const keySorted = Object.keys(typeTotalValues).sort();
    const typeTotalValues1: typeof typeTotalValues = {};
    for (let i = 0; i < keySorted.length; i += 1) {
      typeTotalValues1[keySorted[i]] = typeTotalValues[keySorted[i]];
    }

    return { chartData, typeTotalValues: typeTotalValues1 };
  }, [checkFilteredLogs, metricTime, checkedMetric]);

  const getPieChartData = useCallback((charge?: '-' | '+') => {
    let total1 = 0;

    const chartData: PieChartLogData1[] = [];

    checkFilteredLogs.forEach((log) => {
      const ind = chartData.findIndex((d) => d.name === log.type);
      const value = getFlowTypeValue(log) < 0 ? getFlowTypeValue(log) * -1
        : getFlowTypeValue(log);
      const pushChartData = () => {
        total1 += value;
        if (ind > -1) {
          chartData[ind] = { ...chartData[ind], value: chartData[ind].value + value };
        } else {
          chartData.push({ name: log.type, value });
        }
      };
      if ((!charge || (charge === '-' && Number(log.value) < 0) || (charge === '+' && Number(log.value) >= 0))
        && value > 0) {
        pushChartData();
      }
    });

    const pCData0: PieChartLogData[] = chartData.map((d) => {
      return { ...d, total: total1, otherFields: [] };
    });

    const total0 = pCData0.reduce((a, b) => a + b.value, 0);
    const toRemove: string[] = [];

    pCData0.sort((a, b) => a.value - b.value);
    pCData0.forEach((d) => {
      if (Math.trunc((d.value / total0) * 100) < 9) {
        if (pCData0.length - toRemove.length > 7) {
          toRemove.push(d.name);
        }
      }
    });

    let pCData1: PieChartLogData[] = [];
    const others: PieChartLogData[] = [];

    if (toRemove.length > 1) {
      let othersTotal = 0;
      const otherFields: OtherField[] = [];
      toRemove.forEach((t) => {
        const thisValue = pCData0.find((d) => d.name === t)?.value || 0;
        pCData0.splice(pCData0.findIndex((d) => d.name === t), 1);
        othersTotal += thisValue;
        otherFields.push({ name: t, value: thisValue });
      });
      others.push({
        name: 'Others',
        value: othersTotal,
        total: total1,
        otherFields
      });
      pCData1 = [...pCData0];
    } else {
      pCData1 = [...pCData0];
    }

    const total = pCData1.reduce((a, b) => a + (Number(b.value) || 0), 0);
    const pCData11: PieChartLogData[] = total ? pCData1 : [];

    const all: PieChartLogData[] = [];
    Object.entries(logGroups).forEach(([, v]) => {
      const dTmp: PieChartLogData[] = [];
      pCData11.forEach((d) => {
        if (v.includes(d.name)) {
          dTmp.push(d);
        }
      });
      dTmp.sort((a, b) => {
        if (Number(a.value) < Number(b.value)) return 1;
        if (Number(a.value) > Number(b.value)) return -1;
        return 0;
      });
      if (dTmp.length) all.push(...dTmp);
    });

    const pCData22 = [...all];
    pCData22.sort((a, b) => {
      if (Number(a.value) < Number(b.value)) return 1;
      if (Number(a.value) > Number(b.value)) return -1;
      return 0;
    });

    pCData22.forEach((d, i) => {
      let currInd = -1;
      all.find((a, ind) => {
        currInd = ind;
        return a.name === d.name;
      });
      all[currInd].ind = i;
    });

    const pCData: PieChartLogData[] = total ? [...all, ...others] : [];
    pCData.sort((a, b) => a.name > b.name ? 1 : -1);

    return pCData;
  }, [checkFilteredLogs]);

  const getAreaChartData = useCallback((isUseRaw?: true) => {
    const endDate = new Date(metricTime?.endDate || new Date());
    const startDate = new Date(metricTime?.startDate || new Date());

    type AreaChartDataBuild = {
      date: number,
      total: number,
      daily: number
    }

    const areaChartBuild: AreaChartDataBuild[] = [];

    const forceLogs = [
      {
        id: 'xxx1',
        type: '',
        message: '',
        date: getDayEnd(startDate).toString(),
        value: '0',
        updatedAsOf: '',
        count: 0,
      },
      {
        id: 'xxx2',
        type: '',
        message: '',
        date: getDayEnd(endDate).toString(),
        value: '0',
        updatedAsOf: '',
        count: 0,
      },
    ];

    const dates: Record<string, number> = {};

    [...checkFilteredLogs, ...forceLogs].forEach((l) => {
      dates[l.id] = +new Date(l.date);
    });

    [...checkFilteredLogs, ...forceLogs].sort((a, b) => +new Date(a.date) - +new Date(b.date))
      .forEach((log) => {
        areaChartBuild.sort((a, b) => a.date - b.date);
        const ind = areaChartBuild.findIndex((d) => dates[log.id] <= d.date);
        const total0 = log.type ? getFlowTypeValue(log) : 0;
        const total = log.id ? total0 : 0;

        if (ind > -1) {
          areaChartBuild[ind] = {
            ...areaChartBuild[ind],
            total: areaChartBuild[ind].total + total,
            daily: areaChartBuild[ind].daily + total
          };
        } else {
          const priorTotal = areaChartBuild.length > 0 ? areaChartBuild[areaChartBuild.length - 1]
            .total : 0;

          areaChartBuild.push({
            date: +getDayEnd(new Date(log.date)),
            total: total + priorTotal,
            daily: total
          });
        }
      });

    const areaChartData: AreaChartData[] = areaChartBuild.map((d) => {
      return {
        date: isUseRaw ? new Date(d.date || 0).toISOString() : convertDate23(new Date(d.date)),
        dateF: new Date(d.date || 0),
        total: d.total,
        daily: d.daily
      };
    });

    return areaChartData;
  }, [checkFilteredLogs, metricTime, flowType]);

  return { getPieChartData, getAreaChartData, getSimpleLineData };
};

export const useChart = () => {
  const { width: wWidth } = useWindowSize();
  const flowType = useAppSelector(selectMetricsFlowType);
  const currency = useAppSelector(selectCurrency);

  const customLineChartToolTip = (
    props: any,
  ) => {
    const { payload, active } = props;

    if (active && payload?.length) {
      payload.sort((a: any, b: any) => b.value - a.value, 0);
      const { payload: payload22 } = payload[0];
      const { name } = payload22;
      return (
        <S.Container>
          <S.Name4>{name}</S.Name4>
          {
            payload.map((p: any) => {
              const { name: name1, value, stroke } = p;
              return (
                <S.Value4 key={`lCT_${name1}`} flowType={flowType === 'count' ? 'count' : value < 0 ? '-' : '+'}>
                  <S.Circle fill={stroke} />
                  <S.Name6>{`${name1}:`}</S.Name6>
                  <S.Bold>
                    {
                      flowType === 'count' ? <>{value}</> : <>{`${currency}${value.toLocaleString()}`}</>
                    }
                  </S.Bold>
                </S.Value4>
              );
            })
          }
        </S.Container>
      );
    }
    return null;
  };

  const customRadarToolTip = (
    props: any,
  ) => {
    const { payload, active } = props;

    if (active && payload?.length) {
      const { payload: payload2 } = payload[0];
      const {
        name, outFlow, inFlow, count
      } = payload2;

      const colors = { outFlow: redColor, inFlow: greenColor, count: purpleColor };
      const grossCalc = flowType === 'count' ? (inFlow - outFlow) < 0 ? (inFlow - outFlow) * -1 : (inFlow - outFlow)
        : (inFlow + outFlow);
      const isGrossRed = flowType === 'count' && (inFlow - outFlow) < 0;
      // console.log(payload[0]);

      return (
        <S.Container>
          <S.Name4>{name}</S.Name4>
          {
            (inFlow !== undefined &&
              ![...logGroupsRaw.Actions, ...logGroupsRaw.Systems].includes(name)
            ) ? (
              <S.Value3 fill={flowType === 'count' ? '#525252' : cashColor} flowType="+">
                <S.Name3 fill={colors.inFlow}>Inflow:</S.Name3>
                <S.Bold3>
                  {
                    flowType === 'count' ? <>{inFlow}</> : <>{`${currency}${inFlow.toLocaleString()}`}</>
                  }
                </S.Bold3>
              </S.Value3>
              ) : (null)
          }
          {
            (outFlow !== undefined &&
              ![...logGroupsRaw.Actions, ...logGroupsRaw.Systems].includes(name)
            ) ? (
              <S.Value3 fill={flowType === 'count' ? '#525252' : colors.outFlow} flowType="+">
                <S.Name3 fill={colors.outFlow}>Outflow:</S.Name3>
                <S.Bold3>
                  {
                    flowType === 'count' ? <>{outFlow}</> : <>{`${currency}${outFlow.toLocaleString()}`}</>
                  }
                </S.Bold3>
              </S.Value3>
              ) : (null)
          }
          {
            ((outFlow !== undefined && inFlow !== undefined) &&
            ![...logGroupsRaw.Actions, ...logGroupsRaw.Systems].includes(name)
            ) ? (
              <S.Value3
                fill={(grossCalc < 0 || isGrossRed) ? redColor : cashColor}
                flowType="+"
              >
                <S.Name5 fill="#525252">Gross:</S.Name5>
                <S.Bold3>
                  {
                    flowType === 'count' ? <>{grossCalc}</> : <>{`${currency}${grossCalc.toLocaleString()}`}</>
                  }
                </S.Bold3>
              </S.Value3>
              ) : (null)
          }
          {
            count !== undefined ? (
              <S.Value3 fill={flowType === 'count' ? '#525252' : colors.count} flowType="+">
                <S.Name3 fill={colors.count}>All:</S.Name3>
                <S.Bold3>
                  {
                    flowType === 'count' ? <>{count}</> : <>{`${currency}${count.toLocaleString()}`}</>
                  }
                </S.Bold3>
              </S.Value3>
            ) : (null)
          }
        </S.Container>
      );
    }
    return null;
  };

  const customAreaChartToolTip = (
    props: any,
  ) => {
    const { payload, active } = props;

    if (active && payload?.length) {
      const { payload: p2 } = payload[0];
      const { date } = p2;

      return (
        <S.Container>
          <S.Name>{date}</S.Name>
          <S.AreaChartItems>
            {
              payload.map((p: any) => {
                const {
                  fill, dataKey, payload: payload2, name
                } = p;
                const { [dataKey]: value } = payload2;

                return (
                  <S.FlexCont fill={fill}>
                    {`${name}:`}
                    <S.Value flowType={flowType === 'count' ? 'count' : value < 0 ? '-' : '+'}>
                      <S.Bold>
                        {
                          flowType === 'count' ? <>{value}</> : <>{`${currency}${Number(value).toLocaleString()}`}</>
                        }
                      </S.Bold>
                    </S.Value>
                  </S.FlexCont>
                );
              })
            }
          </S.AreaChartItems>
        </S.Container>
      );
    }
    return null;
  };

  const customTwoPieToolTip = (
    props: any,
    {
      charge,
    } : { charge: '-' | '+'}
  ) => {
    const { payload, active } = props;

    if (active && payload?.length) {
      const { name, payload: payload2 } = payload[0];
      const { value } = payload2;
      const { total, otherFields: oF } = payload2;

      const otherFields: OtherField[] = [...(oF || [])];
      otherFields.sort((a, b) => b.value - a.value);
      const otherFields1 = otherFields.splice(6, (otherFields.length - 6) + 1);
      const fType = flowType === 'both' ? charge : flowType;

      return (
        <S.Container>
          <S.FlexCont>
            <i>{`(${Math.round((value / total) * 100)}%)`}</i>
            {
              name === 'Others' || name.includes('Others-#') ? <>{`${name}:`}</> :
              <S.Name>{`${name}:`}</S.Name>
            }
            <S.Value flowType={fType}>
              {
                flowType === 'count' ? (
                  <>
                    <S.Bold>
                      {value}
                    </S.Bold>
                  </>
                ) : (
                  <S.Bold>
                    {`${currency}${(charge === '-' || flowType === '-') ? '-' : ''}${Number(value).toLocaleString()}`}
                  </S.Bold>
                )
              }
            </S.Value>
          </S.FlexCont>
          <S.Value2 flowType={fType}>
            {
              otherFields.map((f: OtherField) => {
                const { value: value1, name: name1 } = f;
                return (
                  <S.FlexCont2 key={`ofield1_${name1}`}>
                    <span style={{ fontSize: '15px' }}>
                      <i>{`(${Math.round((value1 / total) * 100)}%)`}</i>
                    </span>
                    <S.Name>{`${name1}:`}</S.Name>
                    {
                      flowType === 'count' ? (
                        <>
                          <S.Bold2>
                            {value1}
                          </S.Bold2>
                        </>
                      ) : (
                        <S.Bold2>
                          {`${currency}${(charge === '-' || flowType === '-') ? '-' : ''}${Number(value1).toLocaleString()}`}
                        </S.Bold2>
                      )
                    }
                  </S.FlexCont2>
                );
              })
            }
            {otherFields1.length ? (<>{`+ ${otherFields.length} more`}</>) : (null)}
          </S.Value2>
        </S.Container>
      );
    }

    return null;
  };

  const customPieToolTip = (
    props: any,
    {
      charge,
    } : { charge: '-' | '+'}
  ) => {
    const { payload, active } = props;

    if (active && payload?.length) {
      const { name, value, payload: payload2 } = payload[0];
      const { total, otherFields: oF } = payload2;

      const otherFields: OtherField[] = [...oF];
      otherFields.sort((a, b) => b.value - a.value);
      const otherFields1 = otherFields.splice(6, (otherFields.length - 6) + 1);
      const fType = flowType === 'both' ? charge : flowType;

      return (
        <S.Container>
          <S.FlexCont>
            <i>{`(${Math.round((value / total) * 100)}%)`}</i>
            {
              name === 'Others' || name.includes('Others-#') ? <>{`${name}:`}</> :
              <S.Name>{`${name}:`}</S.Name>
            }
            <S.Value flowType={fType}>
              {
                flowType === 'count' ? (
                  <>
                    <S.Bold>
                      {value}
                    </S.Bold>
                  </>
                ) : (
                  <S.Bold>
                    {`${currency}${(charge === '-' || flowType === '-') ? '-' : ''}${Number(value).toLocaleString()}`}
                  </S.Bold>
                )
              }
            </S.Value>
          </S.FlexCont>
          <S.Value2 flowType={fType}>
            {
              otherFields.map((f: OtherField) => {
                const { value: value1, name: name1 } = f;
                return (
                  <S.FlexCont2 key={`of2_${name1}`}>
                    <span style={{ fontSize: '15px' }}>
                      <i>{`(${Math.round((value1 / total) * 100)}%)`}</i>
                    </span>
                    <S.Name>{`${name1}:`}</S.Name>
                    {
                      flowType === 'count' ? (
                        <>
                          <S.Bold2>
                            {value1}
                          </S.Bold2>
                        </>
                      ) : (
                        <S.Bold2>
                          {`${currency}${(charge === '-' || flowType === '-') ? '-' : ''}${Number(value1).toLocaleString()}`}
                        </S.Bold2>
                      )
                    }
                  </S.FlexCont2>
                );
              })
            }
            {otherFields1.length ? (<>{`+ ${otherFields.length} more`}</>) : (null)}
          </S.Value2>
        </S.Container>
      );
    }

    return null;
  };

  const getPieLabel = ({
    props, pieData, chartId, charge
  } : {
    props: any, pieData: PieChartLogData[] | TLPieChartData[],
    chartId: number, charge: '-' | '+'
  }) => {
    const { index, payload } = props;
    const { value } = payload;
    const pD = [...pieData];
    let pieFontSize = 36 - ((wWidth || 1536) * (16.5 / 1536)) < 0.5 ? '0.5px' :
      `calc(${36 - ((wWidth || 1536) * (16.5 / 1536))}px)`;
    if ((wWidth || 0) < Number(chartRes1.replace('px', ''))) {
      pieFontSize = `calc(${pieFontSize} / 1.5)`;
    }

    return (
      <>
        <svg>
          <defs>
            <text
              {...props}
              id={`pieLabel${chartId}${index}`}
              paintOrder="stroke fill"
              stroke="white"
              strokeWidth={2}
              fontSize={pieFontSize}
              fontWeight={700}
            >
              {`${charge === '-' || flowType === '-' ? '-' : ''}${formatN(value)}`}
            </text>
          </defs>
        </svg>
        {
          index === pD.length - 1 ? (
            <>
              {
                pD.sort((a, b) => a.value - b.value).map((d) => {
                  const index2 = pieData.findIndex((d2) => d2.name === d.name);
                  return (
                    <>
                      <use key={`pieLabel${chartId}${index2}`} href={`#pieLabel${chartId}${index2}`} />
                    </>
                  );
                })
              }
            </>
          ) : (null)
        }
      </>
    );
  };

  return {
    getPieLabel,
    customPieToolTip,
    customTwoPieToolTip,
    customAreaChartToolTip,
    customRadarToolTip,
    customLineChartToolTip
  };
};
