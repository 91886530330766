import { callEndpoint } from 'redux/global';
import { IResponse } from 'types';

export const verifyAuth = () => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'verify',
      method: 'GET',
    }),
  );
  return res;
};

export const getHotel = (id: string) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'gethotel',
      method: 'POST',
      body: { id }
    }),
  );
  return res;
};
