import { Rate } from 'pages/Settings/redux';

export type IResponse = {
  status: 'error' | 'success',
  data?: any,
  description?: string,
};

export type CallArgs = {
  api: string,
  noStatus?: boolean,
  method?: string,
  body?: any,
  type?: 'json' | 'blob',
  token?: string,
  VerifyToken?: string,
  isUnAuthed?: boolean;
  noStringify?: boolean;
  noContentType?: boolean;
  contentType?: any;
  checkLimit?: 'restrictions' | 'availability' | 'both'
};

export const lFColor2 = '#212137';
export const houseKeepingTime = (1 * 60 * 1000);

export type BookingDetails = {
  id: string,
  roomId: string,
  bookDate: string,
  startDate: string,
  endDate: string,
  days: string,
  token: string | null,
  number?: string | null,
  email?: string,
  name: string,
  rate: string,
  ratePlan: Rate | undefined,
  source?: string,
  coId?: string,
  hasKeyAccess?: boolean
}

export type Room = {
  id: string,
  name: string,
  description: string,
  floor: string,
  perks: string[],
  books: BookingDetails[],
  onHold: string | null,
  bookToken: string | null,
  roomTypeId: string,
  createdOn: string,
  updatedAsOf: string,
  updatedBy: string,
  deletedAsOf?: string
};
