import React, { useLayoutEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import * as S from './Loader.styled';

const Loader = function Loader(
  { isSticky, skipSideBar, isNoBg }
  :
  { isSticky?: true, skipSideBar?: true, isNoBg?: true }
) {
  useLayoutEffect(() => {
    const sideBar = document.getElementById('sidebar');
    if (!skipSideBar && sideBar) {
      sideBar.style.zIndex = '1';
    }
    return () => {
      if (!skipSideBar && sideBar) {
        sideBar.style.zIndex = '3';
      }
    };
  });

  return (
    <>
      {isNoBg ? null : <S.Bg isSticky={isSticky} />}
      <S.Progress>
        <CircularProgress color="inherit" />
      </S.Progress>
    </>
  );
};

export default Loader;
