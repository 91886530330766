import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as S from './Branches.styled';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  Branch as BranchType,
  ModalParts,
  selectCurrency,
  selectHotelData,
  setBranches
} from 'pages/redux';
import Close from '@mui/icons-material/Close';
import Loader from 'pages/components/Loader/Loader';
import { toast } from 'react-toastify';
import {
  addBranchLog, deleteBranchFiles, deleteLogType, selectLogsChecked,
  setLogsChecked, setRecentLog
} from '../redux';
import Branch from './Branch';
import Calendar from '@mui/icons-material/DateRange';
import DatePicker from 'react-calendar';
import { CalendarModal } from 'pages/Metrics/components/MetricsTime';
import { convertDate23 } from 'utils';
import SelectStaff from './SelectStaff';
import { useLocation } from 'react-router-dom';
import { selectUserEmail } from 'pages/Hospitality/redux';
import { selectPermission } from 'redux/global';
import { useLogs } from '../hooks';
import { User } from 'pages/Settings/redux';
import TablesAdded from './TablesAdded/TablesAdded';
import { useSocketEmit } from 'pages/Navigation/hooks';
import { useSettings } from 'pages/Settings/hooks';

const Branches = ({ onExit }:{ onExit: Function }) => {
  const hotelData = useAppSelector(selectHotelData);
  const logsChecked = useAppSelector(selectLogsChecked);
  const userEmail = useAppSelector(selectUserEmail);
  const permission = useAppSelector(selectPermission);
  const currency = useAppSelector(selectCurrency);
  const [selectedBranch, setSelectedBranch] = useState<string>('');
  const [header, setHeader] = useState<string>('Select branch');
  const [isLoading, setIsLoading1] = useState<boolean>(false);
  const [isMoreClosed, setIsMoreClosed] = useState<boolean>(true);
  const [isShowDeleteBranch, setIsShowDeleteBranch] = useState<boolean>(false);
  const [isSetDirectAmount, setIsSetDirectAmount] = useState<string>('');
  const [newBranchName, setNewBranchName] = useState<string>('');
  const [newBranchNameError, setNewBranchNameError] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [amountError, setAmountError] = useState<string>('');
  const [isShowCalendar, setIsShowCalendar] = useState<boolean>(false);
  const [auditDate, setAuditDate] = useState<Date>(new Date());
  const [auditDetails, setAuditDetails] = useState<string>('');
  const [auditDetailsError, setAuditDetailsError] = useState<string>('');
  const [showPopup, setShowPopup] = useState<ModalParts | null>();
  const [isShowSelectStaff, setIsShowSelectStaff] = useState<boolean>(false);
  const [isShowTablesAdded, setIsShowTablesAdded1] = useState<boolean>(false);
  const { users: u0 } = useSettings();
  const { logGroups } = useLogs();
  const users = u0?.filter(
    (u: User) => (Number(u.permission) || 0) < (permission || 0)
  );
  const location = useLocation();
  const socketEmit = useSocketEmit();

  const {
    updateLocalBranch, deleteLocalBranch, deleteLocalBranchFiles, deleteLocalLogType
  } = useLogs();

  const dispatch = useAppDispatch();

  const showNewBranchSetter = () => {
    const branchesCont = document.getElementById('branchesCont');
    const logTSetterCont = document.getElementById('logTSetterCont');
    if (branchesCont && logTSetterCont) {
      setNewBranchNameError('');
      setNewBranchName('');
      branchesCont.style.height = '0px';
      branchesCont.style.opacity = '0';
      logTSetterCont.style.transition = 'none';
      logTSetterCont.style.height = '0px';
      logTSetterCont.style.opacity = '1';
      logTSetterCont.style.transition = 'all ease-out 350ms';
      logTSetterCont.style.height = '170px';
      setHeader('New branch');
    }
  };

  const showDefaultAddBranch = () => {
    const branchesCont = document.getElementById('branchesCont');
    const logTSetterCont = document.getElementById('logTSetterCont');
    if (branchesCont && logTSetterCont) {
      logTSetterCont.style.transition = 'all ease-out 200ms';
      logTSetterCont.style.opacity = '0';
      logTSetterCont.style.height = '0px';
      setTimeout(() => {
        branchesCont.style.height = 'auto';
        branchesCont.style.opacity = '1';
      }, 201);
      setHeader('Select branch');
    }
  };

  const showHideMainCont = (action: 'show' | 'hide') => {
    const mainAddLogCont = document.getElementById('mainAddLogCont');
    if (mainAddLogCont) {
      if (action === 'show') {
        mainAddLogCont.style.opacity = '1';
        mainAddLogCont.style.position = 'relative';
      } else {
        mainAddLogCont.style.opacity = '0';
        mainAddLogCont.style.position = 'absolute';
      }
    }
  };

  const setIsShowTablesAdded = (bool: boolean) => {
    if (bool) {
      showHideMainCont('hide');
    }
    setIsShowTablesAdded1(bool);
  };

  const setIsLoading = (bool: boolean) => {
    if (bool) {
      showHideMainCont('hide');
    }
    setIsLoading1(bool);
  };

  const onSubmitNewBranch = async () => {
    if (newBranchNameError) {
      return;
    }

    const addBranches = hotelData?.branches.map((b) => b.name);
    const allBranches = [...(addBranches || [])].map((t) => t.trim().toLowerCase());

    const allGroups: string[] = ['others', 'name', 'date'];
    Object.entries(logGroups).forEach(([, v]) => {
      v.forEach((logType) => allGroups.push(logType.trim().toLowerCase()));
    });

    setNewBranchNameError('');

    if (allBranches.length > 15) {
      setNewBranchNameError('Only 15 branches allowed');
      return;
    }
    if (!newBranchName.trim()) {
      setNewBranchNameError('Name required');
      return;
    }
    if (allGroups.includes(newBranchName.trim().toLowerCase())) {
      setNewBranchNameError('This entity exists or is in use.');
      return;
    }

    showHideMainCont('hide');
    setIsLoading(true);

    let newBranchNameSave = JSON.parse(JSON.stringify([...newBranchName.trim().toLowerCase()]));
    newBranchNameSave[0] = newBranchNameSave[0].toUpperCase();
    newBranchNameSave = newBranchNameSave.join('');

    const newBranch: BranchType = {
      name: newBranchNameSave,
      staff: []
    };
    const newBranches = [...(hotelData?.branches || []), newBranch];

    const res = await dispatch(setBranches(JSON.stringify(newBranches)));

    setIsLoading(false);
    showHideMainCont('show');
    if (res.status === 'success') {
      socketEmit('update_branch', { branch: newBranch });
      updateLocalBranch(newBranch);
      toast('New branch added', { type: 'success' });
      showDefaultAddBranch();
      return;
    }

    toast(res.data, { type: 'error' });
  };

  const deleteBranchExit = (endFunction?: Function) => {
    const deleteBranchCont = document.getElementById('deleteBranchCont');
    const deleteBranchCont1 = document.getElementById('deleteBranchCont1');
    if (deleteBranchCont && deleteBranchCont1) {
      deleteBranchCont.style.display = 'flex';
      deleteBranchCont.style.width = '100px';
      deleteBranchCont.style.height = '0px';
      deleteBranchCont1.style.opacity = '0';
    }
    setTimeout(() => {
      setIsShowDeleteBranch(false);
      endFunction?.();
      setIsMoreClosed(true);
      showHideMainCont('show');
    }, 231);
  };

  const deleteBranch = async () => {
    const newBranches = [...(hotelData?.branches || []).filter(
      (b) => b.name !== selectedBranch
    )];

    const deleteBranchCont = document.getElementById('deleteBranchCont');
    if (deleteBranchCont) {
      deleteBranchCont.style.display = 'none';
    }

    setIsLoading(true);

    const res0 = await dispatch(deleteLogType({ type: selectedBranch }));

    if (res0.status !== 'success') {
      setIsLoading(false);
      toast(res0.data, { type: 'error' });
      return;
    }

    const res1 = await dispatch(deleteBranchFiles(selectedBranch));

    if (res1.status !== 'success') {
      setIsLoading(false);
      toast(res1.data, { type: 'error' });
      return;
    }

    const res = await dispatch(setBranches(JSON.stringify(newBranches)));

    // Bug exists here, deleteBranchExit(...) does not transition after an await
    // Comment after setIsLoading(false) and uncomment below code
    // await new Promise(resolve => setTimeout(resolve, 1000));

    if (res.status === 'success') {
      deleteLocalBranch(selectedBranch);
      deleteLocalBranchFiles(selectedBranch);
      socketEmit('delete_branch', { branch: selectedBranch });
      socketEmit('update_branchFiles', {});
      setTimeout(() => {
        deleteLocalLogType(selectedBranch);
        socketEmit('delete_log_type', { type: selectedBranch });
        deleteBranchExit(() => setSelectedBranch(''));
        setIsLoading(false);
        toast('Branch deleted successfully!', { type: 'success' });
      }, 300);
      return;
    }
    setIsLoading(false);
    toast(res.data, { type: 'error' });
  };

  const onAddAudit = async () => {
    setAmountError('');
    setAuditDetailsError('');
    if (!amount) {
      setAmountError('Amount is needed');
      return;
    }
    if (!auditDetails) {
      setAuditDetailsError('Description is needed');
      return;
    }

    setIsLoading(true);

    const logEntry = {
      type: selectedBranch,
      value: amount,
      message: auditDetails,
      date: auditDate
    };

    const res = await dispatch(addBranchLog(logEntry));
    await new Promise((resolve) => { setTimeout(resolve, 500); });

    setIsLoading(false);

    if (res.status === 'success') {
      const newChecked = [...logsChecked.filter((c: string) => c !== selectedBranch),
        selectedBranch];
      dispatch(setLogsChecked(newChecked));
      if (location.pathname === '/logs') {
        dispatch(setRecentLog(res.data));
      }
      toast('Added audit successfilly!', { type: 'success' });
      onExit();
      return;
    }
    toast(res.data, { type: 'error' });
  };

  useEffect(() => {
    setAmount('');
    setIsMoreClosed(true);
    setIsSetDirectAmount('');
    setAmountError('');
  }, [selectedBranch]);

  useEffect(() => {
    if (selectedBranch) {
      if (isSetDirectAmount) {
        setHeader(`${selectedBranch} - Direct amount entry`);
      } else {
        setHeader(`${selectedBranch} audit`);
      }
    } else {
      setHeader('Select branch');
    }
  }, [isSetDirectAmount, selectedBranch]);

  useEffect(() => {
    const deleteBranchCont = document.getElementById('deleteBranchCont');
    const deleteBranchCont1 = document.getElementById('deleteBranchCont1');
    if (deleteBranchCont && isShowDeleteBranch && deleteBranchCont1) {
      showHideMainCont('hide');
      deleteBranchCont.style.width = '400px';
      deleteBranchCont.style.height = '500px';
      deleteBranchCont1.style.opacity = '1';
    }
  }, [isShowDeleteBranch]);

  useLayoutEffect(() => {
    if (isShowCalendar || isLoading || showPopup || isShowSelectStaff || isShowTablesAdded) {
      showHideMainCont('hide');
    } else {
      showHideMainCont('show');
    }
  }, [isShowCalendar, isLoading, showPopup, isShowSelectStaff, isShowTablesAdded]);

  const setIsShowSelectStaff1 = (showSS: boolean) => {
    if (showSS) {
      showHideMainCont('hide');
    }
    setIsShowSelectStaff(showSS);
  };

  const displayBranches = [...(hotelData?.branches || []).filter((b) => (permission || 0) >= 3 ?
    true : b.staff.includes(userEmail)).map((b) => b.name)];

  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  return (
    <>
      {isLoading && <Loader skipSideBar isSticky />}
      {
        isShowCalendar ? (
          <CalendarModal label="Set new audit date" onExit={() => setIsShowCalendar(false)}>
            <DatePicker
              value={auditDate}
              maxDate={new Date()}
              minDate={oneYearAgo}
              onChange={(v: any) => {
                setAuditDate(v);
                setIsShowCalendar(false);
              }}
            />
          </CalendarModal>
        ) : (null)
      }
      {
        showPopup ? (
          showPopup.component
        ) : (null)
      }
      {
        isShowSelectStaff ? (
          <SelectStaff
            selectedBranch={selectedBranch}
            onExit={() => setIsShowSelectStaff1(false)}
            users={users || null}
          />
        ) : (null)
      }
      {
        isShowTablesAdded ? (
          <TablesAdded
            selectedBranch={selectedBranch}
            onExit={() => setIsShowTablesAdded(false)}
            setIsLoading={setIsLoading}
          />
        ) : (null)
      }
      {
        isShowDeleteBranch && (
          <S.DeleteTypeCont id="deleteBranchCont">
            <S.DTCont1 id="deleteBranchCont1">
              <EscapeButton onClick={() => deleteBranchExit()} />
              <S.DTHeader>Delete branch</S.DTHeader>
              <S.DTBranch>{selectedBranch}</S.DTBranch>
              <S.DTLabel>Are you sure you want to delete this branch?</S.DTLabel>
              <S.DTNote>
                <strong>{'NOTE '}</strong>
                This also deletes all associated audits
              </S.DTNote>
              <S.DTButtonsCont>
                <S.DTButton1 onClick={() => deleteBranchExit()}>
                  Cancel
                </S.DTButton1>
                <S.DTButton0 onClick={() => deleteBranch()}>
                  DELETE
                </S.DTButton0>
              </S.DTButtonsCont>
            </S.DTCont1>
          </S.DeleteTypeCont>
        )
      }
      <S.MainContainer id="mainAddLogCont">
        <S.Container>
          <EscapeButton onClick={() => onExit()} />
          <S.Header>Branches</S.Header>
          <S.Header1>
            {(selectedBranch && selectedBranch !== '&') ? `${selectedBranch} audit` : header}
          </S.Header1>
          <S.BranchesSetterCont id="logTSetterCont">
            <S.Header2>
              { newBranchNameError ? <S.Info isError>{newBranchNameError}</S.Info> :
                (<S.Info>Enter name of branch</S.Info>)}
            </S.Header2>
            <S.Input
              value={newBranchName}
              isError={Boolean(newBranchNameError)}
              onChange={(e) => {
                setNewBranchNameError('');
                const sChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
                if (sChars.test(e.target.value)) {
                  setNewBranchNameError('Special characters are not allowed');
                  return;
                }
                if (e.target.value.length >= 18) {
                  setNewBranchNameError('Only 18 characters allowed');
                  return;
                }
                setNewBranchName(e.target.value);
              }}
            />
            <S.ButtonsCont>
              <S.Button1 onClick={() => showDefaultAddBranch()}>
                Cancel
              </S.Button1>
              <S.Button0 onClick={() => onSubmitNewBranch()}>
                Submit
              </S.Button0>
            </S.ButtonsCont>
          </S.BranchesSetterCont>
          <S.BranchesCont0 id="branchesCont">
            <S.AddThisCont0>
              {
                (selectedBranch === '&' || !selectedBranch) && <S.NoBranch>No branch selected</S.NoBranch>
              }
              <Branch
                isShow={Boolean(selectedBranch) && !isSetDirectAmount}
                selectedBranch={selectedBranch}
                isMoreClosed={isMoreClosed}
                setIsMoreClosed={setIsMoreClosed}
                setIsShowDeleteBranch={setIsShowDeleteBranch}
                setSelectedBranch={setSelectedBranch}
                setIsSetDirectAmount={setIsSetDirectAmount}
                setShowPopup={setShowPopup}
                setIsLoading={setIsLoading}
                setIsShowSelectStaff={setIsShowSelectStaff1}
                setIsShowTablesAdded={setIsShowTablesAdded}
              />
              <S.AddThisCont key={selectedBranch} animate={isSetDirectAmount}>
                <S.HeaderPart>
                  <S.Header3>{selectedBranch === '&' ? '' : selectedBranch}</S.Header3>
                  <S.IconCont2
                    onClick={() => {
                      setIsSetDirectAmount('');
                    }}
                  >
                    <Close fontSize="medium" sx={{ scale: '0.8' }} />
                  </S.IconCont2>
                </S.HeaderPart>
                <S.DetailsPart>
                  { auditDetailsError ? <S.Info1 isError>{auditDetailsError}</S.Info1> :
                    (<S.Info1>Enter description</S.Info1>)}
                  <S.Input01
                    isError={!!auditDetailsError}
                    value={auditDetails}
                    onChange={(e) => {
                      setAuditDetailsError('');
                      const { value } = e.target;
                      if (value.length >= 25) {
                        setAuditDetailsError('Only 25 characters allowed');
                        return;
                      }
                      const sChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|<>/?~]/;
                      if (sChars.test(value)) {
                        setAuditDetailsError('Special characters are not allowed');
                        return;
                      }
                      setAuditDetails(value);
                    }}
                  />
                  <S.DatePart>
                    <S.Label>Audit date</S.Label>
                    <S.Date>{convertDate23(auditDate)}</S.Date>
                  </S.DatePart>
                  <S.IconCont onClick={() => setIsShowCalendar(true)}>
                    <Calendar fontSize="large" />
                  </S.IconCont>
                </S.DetailsPart>
                <S.AmountPart>
                  { amountError ? <S.Info1 isError>{amountError}</S.Info1> :
                    (<S.Info1>Enter amount</S.Info1>)}
                  <S.Input1
                    value={
                      amount ? amount === '-' ? amount : `${currency}${Number(amount).toLocaleString()}` : ''
                    }
                    amount={Number(amount)}
                    isError={Boolean(amountError)}
                    onChange={(e) => {
                      setAmountError('');
                      const formatted = e.target.value.replaceAll(',', '').replaceAll(currency, '');
                      if (formatted[0] === '-') {
                        if (
                          (formatted.length >= 2 && formatted.slice(1, formatted.length - 1).includes('-')) ||
                          (/\D+/.test(formatted.slice(1, formatted.length)))
                        ) {
                          setAmountError('Not a valid entry');
                          return;
                        }
                      } else {
                        if (formatted.includes('-') || /\D+/.test(formatted)) {
                          setAmountError('Not a valid entry');
                          return;
                        }
                      }
                      setAmount(formatted);
                    }}
                  />
                  <S.Button onClick={() => onAddAudit()}>
                    Add Audit
                  </S.Button>
                </S.AmountPart>
              </S.AddThisCont>
            </S.AddThisCont0>
            <S.BranchesCont id="branchesCont">
              {
                displayBranches.map((b, i) => {
                  return (
                    <S.Branch
                      isSelected={selectedBranch === b}
                      onClick={() => setSelectedBranch(b)}
                      key={`addLog_${b}_${i}`}
                    >
                      {b}
                    </S.Branch>
                  );
                })
              }
            </S.BranchesCont>
            <S.BottomCover>
              <S.BottomCover2 />
            </S.BottomCover>
            {
              (permission || 0) >= 3 ? (
                <S.BranchesCont1>
                  <S.Branch1
                    onClick={() => {
                      setSelectedBranch('');
                      showNewBranchSetter();
                    }}
                  >
                    New branch
                  </S.Branch1>
                </S.BranchesCont1>
              ) : (null)
            }
          </S.BranchesCont0>
        </S.Container>
      </S.MainContainer>
    </>
  );
};

export default Branches;
