import React, { useState } from 'react';
import * as S from './ImportTypes.styled';
import {
  ImportType,
  ImportedData, getDataFromCSV, getDataFromExcel, importOptions
} from './utils';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import TableSelector from './TableSelector';

const ImportTypes = ({
  onExit,
  setIsBranchesLoading,
  selectedBranch,
} : {
  onExit: Function,
  setIsBranchesLoading: Function,
  selectedBranch: string,
}) => {
  const [importedData, setImportedData] = useState<ImportedData[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dialogues = {
    CSV: {
      action: () => {
        const fileUploader = document.getElementById('uploadSheet1');
        if (fileUploader) {
          fileUploader.click();
        }
      },
    },
    EXCEL: {
      action: () => {
        const fileUploader = document.getElementById('uploadSheet');
        if (fileUploader) {
          fileUploader.click();
        }
      },
    }
  };

  const setImportedData1 = (d: ImportedData[] | null) => {
    setIsLoading(false);
    setIsBranchesLoading(false);
    setImportedData(d);
  };

  const afterSelectFileAction: any = {
    CSV: (files?: any) => {
      setIsLoading(true);
      setIsBranchesLoading(true);
      getDataFromCSV(files, setImportedData1);
    },
    EXCEL: (files?: any) => {
      setIsLoading(true);
      setIsBranchesLoading(true);
      getDataFromExcel(files, setImportedData1);
    },
  };

  return (
    <>
      {
        (importedData || isLoading) ? (
          <>
            {
              isLoading ? (null) : (
                <TableSelector
                  importedData={importedData || []}
                  onExit={() => onExit()}
                  selectedBranch={selectedBranch}
                  setImportedData={setImportedData}
                />
              )
            }
          </>
        ) : (
          <S.Container>
            <EscapeButton onClick={() => onExit()} isNoBorder />
            <S.Header>
              SELECT FILE TYPE
            </S.Header>
            <S.Input
              type="file"
              id="uploadSheet"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => {
                afterSelectFileAction.EXCEL(e.target.files);
                e.target.value = '';
              }}
            />
            <S.Input
              type="file"
              id="uploadSheet1"
              accept=".csv"
              onChange={(e) => {
                afterSelectFileAction.CSV(e.target.files);
                e.target.value = '';
              }}
            />
            <S.IconCont>
              {
                importOptions.map((option, index) => (
                  <S.IconCont2
                    onClick={() => {
                      dialogues[option.uploadType as ImportType].action();
                    }}
                    key={`importoption_${index}`}
                  >
                    <S.IconContMain>
                      <S.IconDiv color={option.color} biggerX={index < 2}>
                        {option.icon}
                      </S.IconDiv>
                      <S.IconDesc>{option.uploadType}</S.IconDesc>
                    </S.IconContMain>
                  </S.IconCont2>
                ))
                }
            </S.IconCont>
          </S.Container>
        )
      }
    </>
  );
};

export default ImportTypes;
