import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { CallArgs, IResponse } from '../types';
import { toast } from 'react-toastify';
import { setRooms, setUserEmail } from 'pages/Hospitality/redux';
import { setBranchFiles, setLogs } from 'pages/Logs/redux';
import { HotelData, setHotelData } from 'pages/redux';
import { useState } from 'react';
import { usePath } from 'pages/hooks';
import { setUsers } from 'pages/Settings/redux';

export interface GlobalState {
  token: string;
  permission: number | null;
  username: string | null,
}

const initialState: GlobalState = {
  token: localStorage.getItem('token') || '',
  permission: localStorage.getItem('permission') === '0' ? 0 : Number(localStorage.getItem('permission')) || null,
  username: localStorage.getItem('username') || '',
};

export const counterSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem('token', action.payload);
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
      localStorage.setItem('username', action.payload);
    },
    setPermission: (state, action: PayloadAction<number | null>) => {
      state.permission = action.payload;
      if (action.payload?.toString()) {
        localStorage.setItem('permission', action.payload.toString());
      } else {
        localStorage.removeItem('permission');
      }
    },
  },
});

export const { setToken, setUsername, setPermission } = counterSlice.actions;

export const selectToken = (state: RootState) => state.global.token;
export const selectPermission = (state: RootState) => state.global.permission;
export const selectUsername = (state: RootState) => state.global.username;

export default counterSlice.reducer;
// type IDispatch = (action: any) => {};

export const callEndpoint = ({
  api, body, type = 'json', method = 'GET', contentType, noStringify, noContentType, checkLimit
}: CallArgs) => async (dispatch: Function, getState: Function): Promise<IResponse> => {
  const state = getState();
  const { token } = state.global;
  const { qtConfig } = state.app;
  const { isSocketConnected } = state.views;
  // eslint-disable-next-line prefer-destructuring
  const hotelData: HotelData | undefined = state.views.hotelData;

  if (!isSocketConnected) {
    return { status: 'error', data: 'Socket sync failure. Please try again or reload page' };
  }

  const hotelDataHeader = {
    hDId: hotelData?.id,
    hDName: hotelData?.name,
    hDAddress: hotelData?.address,
    hDEmail: hotelData?.email,
    // Below '' needed as you cant pass null/undefined in header => it will be interpreted as string
    hDLinkedin: hotelData?.linkedin || '',
    hDFacebook: hotelData?.facebook || '',
    hDInstagram: hotelData?.instagram || '',
    hDTwitter: hotelData?.twitter || '',
    hDPhoneNumber: hotelData?.phoneNumber || '',
    hDLogo: hotelData?.logo || '',
    hDCurrency: encodeURIComponent(hotelData?.currency || '') || '$',
    hDCoId: hotelData?.coId || '',
    hDSuffix: hotelData?.suffix || '',
    hDBranch: hotelData?.branch || ''
  };

  const options: any = {
    method,
    headers: {
      'Content-Type': contentType ?? 'application/json',
      token,
      ...hotelDataHeader,
      checkLimit,
    },
    body: !noStringify ? JSON.stringify({ ...body, ...(qtConfig || {}) }) : body,
  };

  if (noContentType) delete options.headers['Content-Type'];
  if (method.toUpperCase() === 'GET') delete options.body;

  try {
    const url = window.location.hostname === (process.env.REACT_APP_MOBILE_APP_URL || '').split(':8200')
      .join('').split('http://').join('').split('/').join('') ?
      process.env.REACT_APP_MOBILE_APP_URL : process.env.REACT_APP_API;
    // console.log('calling ...... ', `${url}${api}`);

    const response = await fetch(
      `${url}${api}`,
      options,
    );

    // console.log('response', response);
    if (!response) {
      return { status: 'error', data: 'Internet connection is not detected' } as IResponse;
    }

    let dataFromEndPoint: any = JSON.stringify(
      { status: 'error', data: 'Error connecting with server. Please try again' }
    );
    if (type === 'json') {
      const dataFromEndPoint1 = await response.json();
      dataFromEndPoint = dataFromEndPoint1 || dataFromEndPoint;
    }
    return JSON.parse(dataFromEndPoint) as IResponse;
  } catch (e: any) {
    const message = e.message.toLowerCase() === 'failed to fetch' ?
      'Please check your connection.' : e.message;
    return { status: 'error', data: message } as IResponse;
  }
};

type ClientsBody = {
  email: string
  password: string
  hotelId?: string
}

export const refreshToken = () => async (dispatch: Function): Promise<IResponse> => {
  const response: IResponse = await dispatch(
    callEndpoint({ api: 'refresh', method: 'GET' }),
  );
  return response;
};

export const useCreds = () => {
  const [expireCredsTimeout, setExpireCredsTimeout] = useState<any>();
  const { navigateToHome } = usePath();

  const logout = (isDontLogOutTabs?: true) => async (dispatch: Function): Promise<void> => {
    dispatch(setToken(''));
    dispatch(setPermission(null));
    dispatch(setUsername(''));
    dispatch(setRooms(null));
    dispatch(setLogs(null));
    dispatch(setBranchFiles(null));
    dispatch(setHotelData(null));
    dispatch(setBranchFiles(null));
    dispatch(setUserEmail(''));
    dispatch(setUsers(null));
    clearTimeout(expireCredsTimeout);
    setExpireCredsTimeout(null);

    if (!isDontLogOutTabs) localStorage.setItem('logout_time', new Date().toISOString());
  };

  const expireCreds = (expiryTime?: number) => async (dispatch: Function): Promise<void> => {
    let timeout = (60000 * 10) - 60000;
    if (expiryTime) {
      timeout = (new Date(expiryTime * 1000)).getTime() - new Date().getTime();
      if (timeout <= 60000) {
        timeout = 1;
      } else {
        timeout -= 60000;
      }
    }
    clearTimeout(expireCredsTimeout);
    setExpireCredsTimeout(null);
    setExpireCredsTimeout(setTimeout(async () => {
      const res = await dispatch(refreshToken());
      if (res.status === 'success') {
        dispatch(setToken(res.data));
        dispatch(expireCreds());
        return;
      }
      toast('Authorization failed', { type: 'error' });
      dispatch(logout());
    }, timeout));
  };

  const otherTabLogin = () => (dispatch: Function): void => {
    dispatch(setToken(localStorage.getItem('token') || ''));
    const permStorage = localStorage.getItem('permission');
    const permission = permStorage ? +permStorage : null;
    dispatch(setPermission(permission));
    dispatch(setUsername(localStorage.getItem('username') || ''));
    dispatch(expireCreds());
    navigateToHome(permission);
  };

  const login = (body: ClientsBody) => async (dispatch: Function): Promise<string> => {
    const res: IResponse = await dispatch(
      callEndpoint({ api: 'auth', body, method: 'POST' }),
    );
    if (res.status === 'success') {
      dispatch(setToken(res.data.token));
      dispatch(setPermission(Number(res.data.permission)));
      dispatch(setUsername(res.data.username));
      dispatch(expireCreds());
      localStorage.setItem('hotel_data_id', res.data.hotelId.toString());
      localStorage.setItem('login_time', new Date().toISOString());
      return res.data.hotelId.toString();
    }
    toast(res.data, { type: 'error' });
    return '';
  };

  return {
    expireCreds, logout, login, otherTabLogin
  };
};
