import { LogKey, logGroupsRaw } from 'pages/Logs/Logs';
import { selectLogsChecked, setLogsChecked } from 'pages/Logs/redux';
import { selectHotelData, selectMetricTime } from 'pages/redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  MetricLog, selectMetricChecked, selectMetricLogs, selectMetricsFlowType
} from './redux';
import { useCallback } from 'react';

export const metrics = {
  Balances: ['Active audits', 'Hospitality', 'Branches', 'All balances'] as const,
  Counts: ['Active audits2', 'Walk-in', 'All audits'] as const // 'Online visitor'
};

export type MetricsKey = typeof metrics['Balances'][number] | typeof metrics['Counts'][number];

export const useMetrics = () => {
  const checked = useAppSelector(selectMetricChecked);
  const logsChecked = useAppSelector(selectLogsChecked);
  const metricTime = useAppSelector(selectMetricTime);
  const flowType = useAppSelector(selectMetricsFlowType);
  const metricLogs = useAppSelector(selectMetricLogs);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const hotelData = useAppSelector(selectHotelData);
  const location = useLocation();

  const { startDate, endDate } = metricTime || {};

  const { branches } = hotelData || {};
  const logGroups: Record<LogKey, string[]> = { ...logGroupsRaw };
  const addBranches = branches?.map((b) => b.name);
  logGroups.Branches = [...(addBranches || []), ...logGroups.Branches];
  const allAudits: string[] = [...Object.entries(logGroups).map(([k]) => k), 'Show all'];
  Object.entries(logGroups).forEach(([, v]) => allAudits.push(...v));
  const dateFilteredLogs = metricLogs.filter((log) => (!startDate || !endDate)
    || (startDate && endDate && +new Date(log.date) >= +new Date(startDate)
    && +new Date(log.date) <= +new Date(endDate)));

  const getTotal = (logsChecked1: string[]) => {
    let total = 0;
    logsChecked1.forEach((c) => {
      const thisTotal = dateFilteredLogs.reduce((a, b) => {
        const t = flowType === '-' && Number(b.value) < 0 ? Number(b.value) :
          flowType === '+' && Number(b.value) > 0 ? Number(b.value) :
            flowType === 'both' ? Number(b.value) || 0 : 0;
        return a + ((c.toLowerCase() === b.type.toLowerCase()) ? t : 0);
      }, 0);
      total += thisTotal;
    });
    return total;
  };

  const getCount = (logsChecked1: string[]) => {
    let total = 0;
    logsChecked1.forEach((bA) => {
      const thisTotal = dateFilteredLogs.reduce((a, b) => a + (bA.includes(b.type)
        ? b.count : 0), 0);
      total += thisTotal;
    });
    return total;
  };

  type TimeMetric = {
    total: () => number,
    checked: string[],
    onClick: Function,
    name: MetricsKey,
  }

  const onClickViewMetricAudits = () => {
    flowType === '-' ? history.push('/logs/svg1ere2xxyv') :
      flowType === '+' ? history.push('/logs/svg1ere1xxyv') :
        flowType === 'both' ? history.push('/logs/svg1ere3xxyv') :
          history.push('/logs/svg1ere4xxyv');
  };

  const allBalances = [...logGroups.Branches, ...logGroups.Hospitality, 'Hospitality', 'Branches'];
  const activeAuditsBalances = logsChecked.filter((c) => allBalances.includes(c));

  const timeMetrics: Record<MetricsKey, TimeMetric> = {
    'Active audits': {
      name: 'Active audits',
      total: () => getTotal(activeAuditsBalances),
      checked: activeAuditsBalances,
      onClick: () => {
        onClickViewMetricAudits();
      }
    },
    Hospitality: {
      name: 'Hospitality',
      total: () => getTotal(logGroups.Hospitality),
      checked: logGroups.Hospitality,
      onClick: () => {
        dispatch(setLogsChecked(['Hospitality', ...logGroups.Hospitality]));
        onClickViewMetricAudits();
      }
    },
    Branches: {
      name: 'Branches',
      total: () => getTotal([...logGroups.Branches]),
      checked: [...logGroups.Branches],
      onClick: () => {
        dispatch(setLogsChecked(['Branches', ...logGroups.Branches]));
        history.push('/logs/svg1ere1xxyv');
      }
    },
    'All balances': {
      name: 'All balances',
      total: () => getTotal(allBalances),
      checked: allBalances,
      onClick: () => {
        dispatch(setLogsChecked(allBalances));
        onClickViewMetricAudits();
      }
    },
    'Active audits2': {
      name: 'Active audits2',
      total: () => getCount(logsChecked),
      checked: logsChecked,
      onClick: () => {
        onClickViewMetricAudits();
      }
    },
    // 'Online visitor': {
    //   name: 'Online visitor',
    //   total: getCount(['Online visitor']),
    //   checked: ['Online visitor'],
    //   onClick: () => {
    //     dispatch(setLogsChecked(['Online visitor']));
    //     onClickViewMetricAudits();
    //   }
    // },
    'Walk-in': {
      name: 'Walk-in',
      total: () => getCount(['Walk in']),
      checked: ['Walk in'],
      onClick: () => {
        dispatch(setLogsChecked(['Walk in']));
        onClickViewMetricAudits();
      }
    },
    'All audits': {
      name: 'All audits',
      total: () => getCount(allAudits),
      checked: allAudits,
      onClick: () => {
        dispatch(setLogsChecked(allAudits));
        onClickViewMetricAudits();
      }
    },
  };

  const checkedMetric = timeMetrics[checked as MetricsKey];

  const isDashBoard = location.pathname.includes('dashboard');

  const sortedLogs = metricLogs.filter((l) =>
    checkedMetric.checked.includes(l.type)).filter((log) => !endDate
      || (endDate && +new Date(log.date) <= +(endDate || 0)))
    .sort((a, b) => +new Date(b.date) - +new Date(a.date));
  const maxLog = sortedLogs[1200] || [...sortedLogs].pop();

  const checkFilteredLogs = dateFilteredLogs.filter((l) =>
    checkedMetric.checked.includes(l.type) && (isDashBoard
      || +new Date(l.date) >= +new Date(maxLog.date || 0))).sort((a, b) => +new Date(a.date)
      - +new Date(b.date));

  // Not use in dashboard(below)
  const metricTimeLogs = sortedLogs.filter((log) => !maxLog.date
    || +new Date(log.date) >= +new Date(maxLog.date))
    .sort((a, b) => +new Date(a.date) - +new Date(b.date));

  const getFlowTypeValue = (log: MetricLog) => {
    const { value, count } = log;

    return flowType === '+' ? (Number(value) || 0) > 0 ? Number(value) : 0 :
      flowType === '-' ? (Number(value) || 0) < 0 ? Number(value) : 0 :
        flowType === 'both' ? (Number(value) || 0) : count;
  };

  // eslint-disable-next-line no-unused-vars
  const getMetricValue = useCallback((criterea: (log: MetricLog) => boolean, logToUse?: string) => {
    const l = logToUse === 'logs' ? metricLogs : checkFilteredLogs;
    return (l || []).reduce((a, b) => {
      return a + (criterea(b) ? getFlowTypeValue(b) : 0);
    }, 0);
  }, [checkFilteredLogs, metricLogs, flowType]);

  return {
    logGroups,
    addBranches,
    timeMetrics,
    checked,
    checkedMetric,
    dateFilteredLogs,
    checkFilteredLogs,
    metricTimeLogs,
    metricTime,
    getMetricValue,
    getFlowTypeValue,
    getCount,
    allBalances,
    flowType,
    allAudits,
    metricChecked: checked,
    metricLogs
  };
};

export const useMetricLogs = () => {
  return {};
};
