import Color from 'color';
import { lFColor } from 'pages/components/LFHeader/LFHeader.styled';
import { topBarColor } from 'pages/styles';
import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  border-radius: 3px;
  padding-top: 12px;
  width: calc(100vw - 10px);
  max-width: 350px;
  color: #525252;
`;

export const Header = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 0 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid lightgrey;
`;

export const FlexCont = styled.div`
  display: flex;
  min-height: 200px;
  border-bottom: 1px solid lightgrey;
`;

export const StaffCont = styled.div`
  height: 100%;
  max-height: 250px;
  padding: 15px;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

export const Staff = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 7px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export const Input = styled.input`
  min-height: 17px;
  min-width: 17px;
  margin: 0px;
  cursor: pointer;
`;

export const StaffName = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const Role = styled.div`
  font-size: 12px;
`;

export const NoStaffCont = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 200px;
  border-bottom: 1px solid lightgrey;
  align-items: center;
  justify-content: center;
`;

export const NoStaff = styled.div`
  border: 1px solid grey;
  padding: 8px 14px;
  font-size: 13px;
  color: #525252;
  border-radius: 3px;
`;

export const ButtonSect = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 15px;
`;

export const Info = styled.div`
  color: ${topBarColor};
  overflow: hidden;
  white-space: nowrap;
  max-width: 60%;
  font-size: 12px;
`;

export const MainButton = styled.div`
  margin-left: auto;
  color: ${Color(lFColor).lighten(0.05).toString()};
  background: ${Color(lFColor).lighten(1.8).toString()};
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; 
  box-sizing: border-box;
  padding: 8.5px 18px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;
  width: max-content;
  height: max-content;
  cursor: pointer;
  &:hover {
    background: ${Color(lFColor).lighten(0.55).toString()};
    color: ${Color(lFColor).darken(0.35).toString()};
  }
`;
