import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import viewsReducer from '../pages/redux';
import appReducer from 'pages/Hospitality/redux';
import homepageReducer from 'pages/Homepage/redux';
import logsReducer from 'pages/Logs/redux';
import metricsReducer from 'pages/Metrics/redux';
import settingsReducer from 'pages/Settings/redux';
import reserveRoomsReducer from 'pages/ReserveRooms/redux';
import globalReducer, { callEndpoint } from './global';

export const store = configureStore({
  reducer: {
    homepage: homepageReducer,
    views: viewsReducer,
    global: globalReducer,
    app: appReducer,
    logs: logsReducer,
    metrics: metricsReducer,
    settings: settingsReducer,
    reserveRooms: reserveRoomsReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: { callEndpoint },
    },
    serializableCheck: false,
  }),
});

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
