/* eslint-disable react/jsx-no-bind */
import React from 'react';
import {
  PieChart as PieChartRe, Pie, Cell, Tooltip,
} from 'recharts';
import * as S from './PieChart.styled';
import Color from 'color';
import { useMetrics } from 'pages/Metrics/hooks';
import { useWindowSize } from 'pages/Logs/hooks';
import { redColor } from 'pages/Logs/components/LogTable.styled';
import { lFColor0 } from '../LFHeader/LFHeader.styled';
import { chartRes1, chartRes2 } from 'pages/Metrics/components/Charts.styled';
import { PieChartLogData, useChart, useChartData } from './hooks';

export const greenColor = '#00C49F';
export const othersColor = '#f16bf1';
export const COLORS = [S.purpleColor, greenColor, '#82e7af', '#FFBB28',
  Color(S.purpleColor).lighten(0.2).toString(), Color('lightgrey').darken(0.275).toString(),
  '#639fd3', othersColor];
export const COLORS2 = [S.purpleColor, '#FF8042', '#7ba9d2', '#ec8a8a', Color('lightgrey').darken(0.275).toString(),
  '#FFBB28', Color(S.purpleColor).lighten(0.2).toString(), othersColor];

const PieChart = ({ charge, chartId }:{ charge?: '+' | '-', chartId: number }) => {
  const { flowType } = useMetrics();
  const { width: wWidth } = useWindowSize();
  const { getPieLabel, customPieToolTip } = useChart();
  const { getPieChartData } = useChartData();

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = function renderCustomizedLabel({
    cx, cy, midAngle, innerRadius, outerRadius, percent, ind
  }:{ cx: any, cy: any, midAngle: any, innerRadius: any,
      outerRadius: any, percent: any, ind: number | undefined, name: string, value: number }) {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if ((ind || 0) > 0 || ind === undefined) return null;

    return (
      <>
        <text
          x={x + 5}
          y={y + 10}
          fill={[flowType, charge].includes('-') ? Color(redColor).lighten(0.325).toString()
            : Color(COLORS[0]).lighten(0.39).toString()}
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          fontWeight={700}
          fontSize={22}
          strokeWidth={0.5}
          stroke={[flowType, charge].includes('-') ? Color(lFColor0).darken(0).toString()
            : undefined}
          pointerEvents="none"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      </>
    );
  };

  const pCData: PieChartLogData[] = getPieChartData(charge);
  const isNeg = charge === '-' || flowType === '-';

  const customLabel = (props: any) => {
    return (
      <>
        {getPieLabel({
          props, pieData: pCData, chartId, charge: charge || '+'
        })}
        {renderCustomizedLabel(props)}
      </>
    );
  };

  const outerRadius = (wWidth || 0) <= Number(chartRes2.replace('px', '')) ? 63 : isNeg ? 43 : 45;

  return (
    <S.Container>
      {pCData.length === 0 ? <S.EmptyPie /> : null}
      {isNeg && pCData.length ? <S.NegativePie /> : null}
      <PieChartRe width={500} height={500}>
        <Pie
          data={pCData}
          cx="50%"
          cy="50%"
          labelLine
          label={customLabel}
          outerRadius={`${outerRadius}%`}
          fill="#25011a"
          dataKey="value"
          nameKey="name"
          animationBegin={0}
          animationEasing="ease-out"
          animationDuration={425}
          strokeWidth={4}
          minAngle={2}
        >
          {pCData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={isNeg ? COLORS2[entry.ind === undefined ? COLORS.length - 1 : entry.ind] || 'grey'
                : COLORS[entry.ind === undefined ? COLORS.length - 1 : entry.ind] || 'grey'}
              stroke={undefined}
              onClick={() => {
                // console.log(entry.name);
              }}
            />
          ))}
        </Pie>
        <Tooltip
          content={(props) => customPieToolTip(props, { charge: charge || '+' })}
          reverseDirection={{ y: true }}
          position={{ y: 175, x: (wWidth || 0) < Number(chartRes1.replace('px', '')) ? 0 : 300 }}
        />
      </PieChartRe>
    </S.Container>
  );
};

export default React.memo(PieChart);
