import React, { useState } from 'react';
import * as S from './Walkin.styled';
import Walk from '@mui/icons-material/DirectionsWalk';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import Loader from 'pages/components/Loader/Loader';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addLog, selectLogsChecked, setLogsChecked } from 'pages/Logs/redux';
import { toast } from 'react-toastify';
import { selectUsername } from 'redux/global';

const Walkin = ({
  onExit
} : {
  onExit: Function
}) => {
  const [reason, setReason] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reasonError, setReasonError] = useState<string>('');
  const logsChecked = useAppSelector(selectLogsChecked);
  const username = useAppSelector(selectUsername);

  const dispatch = useAppDispatch();

  const onReasonInputChange = (value: string) => {
    setReasonError('');
    if (value.length > 120) {
      setReasonError('Only 120 characters allowed');
      return;
    }
    setReason(value);
  };

  const onClickSave = async () => {
    setReasonError('');
    if (!reason) {
      setReasonError('Walk in summary needed');
      return;
    }
    setIsLoading(true);

    let reason1 = JSON.parse(JSON.stringify([...reason]));
    reason1[0] = reason1[0].toUpperCase();
    reason1 = reason1.join('');

    const logToAdd = {
      type: 'Walk in',
      message: `|${username}| registered walk in. &${reason1}&`,
      value: 'N/A',
      updatedAsOf: new Date().toISOString()
    };

    const res = await dispatch(addLog(logToAdd));

    setIsLoading(false);
    if (res.status === 'success') {
      const newChecked = [...logsChecked.filter((c: string) => c !== 'Walk in'),
        'Walk in'];
      dispatch(setLogsChecked(newChecked));
      toast('Successfully registered walk-in', { type: 'success' });
      onExit();
      return;
    }
    toast(res.data, { type: 'error' });
  };

  return (
    <>
      {
        isLoading ? <Loader isSticky /> : (
          <S.Container>
            <EscapeButton onClick={() => onExit()} isRedBg />
            <S.HeaderPart>
              <S.HeaderItems>
                <S.Icon>
                  <Walk fontSize="large" sx={{ scale: '1.2' }} />
                </S.Icon>
                <S.TextPart>
                  <S.Customer>CUSTOMER</S.Customer>
                  <S.Walkin>Walk-in</S.Walkin>
                </S.TextPart>
              </S.HeaderItems>
            </S.HeaderPart>
            <S.ActionPart>
              <S.Label>
                Walk in summary
                {reasonError ? <S.Info isError>{reasonError}</S.Info> : <S.Info>Enter here</S.Info>}
              </S.Label>
              <S.InputArea
                value={reason}
                isError={Boolean(reasonError)}
                onChange={(e) => {
                  const sChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|<>/?~]/;
                  if (sChars.test(e.target.value)) {
                    setReasonError('Special characters are not allowed');
                    return;
                  }
                  onReasonInputChange(e.target.value);
                }}
              />
              <S.ButtonsCont>
                <S.Button1 onClick={() => onExit()}>Cancel</S.Button1>
                <S.Button0 onClick={() => onClickSave()}>SAVE</S.Button0>
              </S.ButtonsCont>
            </S.ActionPart>
          </S.Container>
        )
      }
    </>
  );
};

export default Walkin;
