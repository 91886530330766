import React, { useEffect, useState } from 'react';
import * as S from './Navigation.styled';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectIsStaySignedIn,
  selectPassword,
  selectUserEmail
} from 'pages/Hospitality/redux';
import { toast } from 'react-toastify';
import {
  selectToken,
  useCreds,
} from 'redux/global';
import Loader from 'pages/components/Loader/Loader';
import { getHotel, verifyAuth } from './redux';
import { moveSideBar } from './utils';
import {
  selectDebugText,
  selectHotelData,
  selectIsSyncCORooms,
  selectModalParts,
  selectSocket,
  setHotelData,
  setIsSyncCORooms,
} from 'pages/redux';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import Modal from 'pages/components/Modal/Modal';
import Footer from 'pages/Homepage/Footer/Footer';
import { lFColor2, mobileRes } from 'pages/components/LFHeader/LFHeader.styled';
import { useFetchLogs, useWindowSize } from 'pages/Logs/hooks';
import { useCX, usePath } from 'pages/hooks';
import WillExpire from './components/WillExpire';
import { useParentEffects } from './hooks';
import SignInModal from 'pages/Homepage/modals/SignInModal';
import ExtendSubscription from 'pages/Settings/components/ExtendSubscription';
import Subscribe from '../Settings/components/Subscribe';
import { useFetchSettings, useSettings } from 'pages/Settings/hooks';
import LFHeader from 'pages/components/LFHeader/LFHeader';
import { useFetchRooms } from 'pages/Hospitality/hooks';

const Navigation = function Navigation({ children }:{ children: any}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isShowSignIn, setIsShowSignIn] = useState<boolean>(false);
  const [currentClient, setCurrentClient] = useState<string | number>('');
  const [expiryTimer, setExpiryTimer] = useState<any>();
  const isSyncCORooms = useAppSelector(selectIsSyncCORooms);
  const isStaySignedIn = useAppSelector(selectIsStaySignedIn);
  const [hasSyncedUnSynced, setHasSyncedUnSynced] = useState(false);
  const email = useAppSelector(selectUserEmail);
  const password = useAppSelector(selectPassword);
  const token = useAppSelector(selectToken);
  const debugText = useAppSelector(selectDebugText);
  const modalParts = useAppSelector(selectModalParts);
  const hotelData = useAppSelector(selectHotelData);
  const socket = useAppSelector(selectSocket);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  useFetchLogs();
  useFetchRooms();
  useFetchSettings();
  const { isHomePath } = usePath();
  const {
    logout, expireCreds, login, otherTabLogin
  } = useCreds();
  const { COSync, CORoomSync } = useCX();
  const { rates, roomTypes } = useSettings();
  const { width: wWidth } = useWindowSize();
  useParentEffects();

  const authorizeAndLogin = () => {
    const loginUser = async () => {
      setLoading(true);
      const hotelId = await dispatch(login({ email: email.toLowerCase(), password }));
      if (hotelId) {
        const res = await dispatch(getHotel(hotelId));
        setLoading(false);
        if (res.status === 'success') {
          dispatch(setHotelData(res.data[0]));
          return;
        }
        toast(res.data, { type: 'error' });
        setIsShowSignIn(true);
        return;
      }
      setLoading(false);
      toast('Could not log in', { type: 'error' });
      setIsShowSignIn(true);
    };

    const fetchHotelAndExpireCreds = async (credsExpiry: number) => {
      setLoading(true);

      const res = await dispatch(getHotel(localStorage.getItem('hotel_data_id') || ''));

      setLoading(false);
      if (res.status === 'success') {
        dispatch(setHotelData(res.data[0]));
        dispatch(expireCreds(credsExpiry));
        return;
      }
      dispatch(logout());
      if (!isStaySignedIn) {
        toast(res.data, { type: 'error' });
        setIsShowSignIn(true);
      }
    };

    const authorize = async () => {
      setLoading(true);
      const res = await dispatch(verifyAuth());
      setLoading(false);
      if (res.status === 'success') {
        fetchHotelAndExpireCreds(res.data as number);
        return;
      }
      if (isStaySignedIn) {
        loginUser();
      } else {
        dispatch(logout());
        setIsShowSignIn(true);
      }
    };

    if (localStorage.getItem('token')) {
      authorize();
    } else {
      dispatch(logout());
      setIsShowSignIn(true);
    }
  };

  useEffect(() => {
    if (hotelData && !expiryTimer) {
      const diff = +new Date(hotelData?.expires || 0) - +new Date();
      clearTimeout(expiryTimer);
      if (diff > 0 && diff < (24 * 60 * 60 * 1000)) {
        setExpiryTimer(setTimeout(() => {
          dispatch(logout());
        }, diff + 5));
      }
    }
    return () => {
      clearTimeout(expiryTimer);
      setExpiryTimer(null);
    };
  }, [hotelData]);

  useEffect(() => {
    if (isSyncCORooms) {
      dispatch(setIsSyncCORooms(false));
      CORoomSync?.();
    }
  }, [hotelData?.coId]);

  useEffect(() => {
    if (hotelData && roomTypes.length && rates.length && !hasSyncedUnSynced) {
      setHasSyncedUnSynced(true);
      COSync(true);
    }
  }, [hotelData, roomTypes, rates]);

  const startNewSession = () => {
    if (localStorage.getItem('hotel_data_id') && currentClient !== localStorage.getItem('hotel_data_id')
      && socket) {
      socket.emit('join_room', `room${localStorage.getItem('hotel_data_id')}`);
      setCurrentClient(localStorage.getItem('hotel_data_id') || '');
      if (!hotelData) {
        authorizeAndLogin();
      }
    }
  };

  useEffect(() => {
    if (!isShowSignIn && !isHomePath) {
      startNewSession();
    }
  }, [isShowSignIn, hotelData, socket, currentClient]);

  useEffect(() => {
    if (!token) {
      setIsShowSignIn(true);
    }
  }, [token]);

  useEffect(() => {
    localStorage.setItem('room_search', '');
    localStorage.setItem('book_search', '');

    const otherTabOperations = (e: any) => {
      if (e.key === 'logout_time') {
        dispatch(logout(true));
        history.push('/');
      }
      if (e.key === 'login_time') {
        dispatch(otherTabLogin());
        setIsShowSignIn(false);
        setCurrentClient('');
      }
    };
    window.addEventListener('storage', otherTabOperations);

    return () => {
      window.removeEventListener('storage', otherTabOperations);
    };
  }, []);

  const isFitScreen = (location.pathname.includes('logs') || location.pathname.includes('metrics') ||
    location.pathname.includes('/settings') || location.pathname.includes('rooms')
    || location.pathname.includes('reserve') || location.pathname.includes('reservations'));

  const dayDiff0 = new Date(hotelData?.expires || '').getTime() - new Date().getTime();
  const monthDiff0 = 12 - Math.ceil(dayDiff0 / (60 * 60 * 24 * 30 * 1000));
  const monthDiff = monthDiff0 < 12 ? monthDiff0 < 0 ? 0 : monthDiff0 : 12;

  const isExpired = !!hotelData && !isHomePath && monthDiff >= 12;
  const isShowSignInMain = isShowSignIn && !isHomePath && !isExpired;

  return (
    <S.MainContainer>
      <Helmet>
        <meta name="theme-color" content={lFColor2} />
        <meta name="msapplication-navbutton-color" content={lFColor2} />
        <meta name="apple-mobile-web-app-status-bar-style" content={lFColor2} />
      </Helmet>
      {
        modalParts && (
          <Modal
            component={modalParts.component}
            exitOnBgClick={modalParts.exitOnBgClick}
            noBg={modalParts.noBg}
            skipSideBar={modalParts.skipSideBar}
          />
        )
      }
      {isHomePath && <LFHeader />}
      {loading && <Loader />}
      <>
        {
          (isShowSignInMain || isExpired) ? (
            <>
              {
                isExpired ? (
                  <>
                    {
                      hotelData?.maxRooms ? (
                        <Modal
                          component={(
                            <ExtendSubscription
                              onExit={() => history.push('/')}
                            />
                          )}
                        />
                      ) : (
                        <Modal
                          component={(
                            <Subscribe
                              onExit={() => history.push('/')}
                            />
                          )}
                        />
                      )
                    }
                  </>
                ) : (
                  <Modal
                    component={(
                      <SignInModal onExit={() => setIsShowSignIn(false)} />
                    )}
                  />
                )
              }
            </>
          ) : (
            <>
              <WillExpire />
              {(wWidth || 1051) <= +mobileRes.replace('px', '') && !isHomePath && <LFHeader />}
              <S.Container id="main_cont" isFitScreen={isFitScreen}>
                <S.Page>
                  <S.DebugBg id="debug_bg">
                    {
                      debugText.map((d, i) => <div key={`debug_${i}`}>{d}</div>)
                    }
                  </S.DebugBg>
                  <S.Bg
                    id="main_bg"
                    onClick={() => {
                      moveSideBar(true);
                    }}
                  />
                  <S.BottomRightPanel isFitScreen={isFitScreen || isHomePath}>
                    {children}
                  </S.BottomRightPanel>
                </S.Page>
                {
                  (isHomePath) && <Footer />
                }
              </S.Container>
            </>
          )
        }
      </>
    </S.MainContainer>
  );
};

export default Navigation;
