import React from 'react';
import * as S from './RoomOptions.styled';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import { useAppSelector } from 'redux/hooks';
import { selectRooms } from '../redux';
import { selectPermission } from 'redux/global';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { useHistory } from 'react-router-dom';
import Color from 'color';
import { ModalParts } from 'pages/redux';
import DeleteRoom from './DeleteRoom';
import { lFColor2 } from 'pages/components/LFHeader/LFHeader.styled';

export const redColor = '#eb364b';

const RoomOptions = (
  {
    onExit, selectedRoomId, setModal,
  }:
  {
    onExit: Function,
    selectedRoomId: string,
    // eslint-disable-next-line no-unused-vars
    setModal: (modalParts: ModalParts | null) => void
  },
) => {
  const rooms = useAppSelector(selectRooms);
  const room = (rooms || []).find((r) => r.id === selectedRoomId);
  const permission = useAppSelector(selectPermission);
  const history = useHistory();

  const icons = [
    {
      icon: <Edit fontSize="medium" />,
      disabled: (permission || 0) <= 1,
      color: lFColor2,
      text: 'Edit',
      onClick: () => {
        history.push(`/settings/editroom/${selectedRoomId}`);
      },
    },
    {
      icon: <Delete fontSize="medium" />,
      disabled: (permission || 0) <= 2,
      color: Color('#eb364b').lighten(0.1).toString(),
      text: 'Delete',
      onClick: () => setModal({
        component: (
          <DeleteRoom
            onExit={() => setModal(null)}
            selectedRoomId={selectedRoomId}
            setModal={setModal}
          />
        )
      }),
    },
  ];

  return (
    <S.Container>
      <EscapeButton onClick={() => onExit()} />
      <S.MainLabel>Options</S.MainLabel>
      <S.Label>{room?.name}</S.Label>
      <S.ButtonsSect>
        {
          icons.map((icon, i) => {
            return (
              <S.IconCont
                color={icon.color}
                onClick={() => icon.onClick()}
                disabled={icon.disabled}
                blocked={Boolean(i === 0 && room?.onHold)}
                key={`opIcon_${icon.text}_${i}`}
              >
                <S.Icon>{icon.icon}</S.Icon>
                <S.Text>{icon.text}</S.Text>
              </S.IconCont>
            );
          })
        }
      </S.ButtonsSect>
    </S.Container>
  );
};

export default RoomOptions;
