import styled from 'styled-components';

export const Container = styled.div<any>`
  width: max-content; // ${(props) => props.width};
  display: flex;
  align-items: center;
  margin: auto;
`;

export const Image = styled.img<any>`
  width: ${(props) => props.width || '40px'};
  height: auto;
`;
