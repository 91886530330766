import { useCallback, useEffect, useState } from 'react';
import {
  getRooms,
  selectDisplayRooms,
  selectRooms,
  setRooms,
  setSearch,
  setSort,
  setRoomsNoDisplay,
  setDisplayRooms,
  selectPauseRunningDate,
} from './redux';
import { Room, houseKeepingTime } from 'types';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toast } from 'react-toastify';
import { selectHotelData } from 'pages/redux';

export const useLocalRoomUpdate = () => {
  const dispatch = useAppDispatch();
  const rooms = useAppSelector(selectRooms);
  const displayRooms = useAppSelector(selectDisplayRooms);

  const addLocalRoom = (room: Room) => {
    const newRooms2 = [...(displayRooms || []), room];
    dispatch(setDisplayRooms(newRooms2));
    const newRooms = [...(rooms || []), room];
    dispatch(setRoomsNoDisplay(newRooms));
  };

  const addLocalRooms = (roomsT: Room[]) => {
    const newRooms2 = [...(displayRooms || []), ...roomsT];
    dispatch(setDisplayRooms(newRooms2));
    const newRooms = [...(rooms || []), ...roomsT];
    dispatch(setRoomsNoDisplay(newRooms));
  };

  const getEditedRooms = (roomsHere: Room[], editRooms: Room[]) => {
    const newRooms = [...(roomsHere || [])];
    editRooms.forEach((room) => {
      const id = room.id.toString() || '-1';
      let rId = -1;
      (roomsHere || []).forEach((r: any, i: number) => {
        if (r.id.toString() === id) rId = i;
      });
      if (rId > -1) {
        newRooms[rId] = { ...newRooms[rId], ...room };
      }
    });
    return newRooms;
  };

  const editLocalRoom = (editRooms: Room[]) => {
    dispatch(setRooms(getEditedRooms(rooms || [], editRooms)));
  };

  const deleteLocalRooms = useCallback((ids: string[]) => {
    const newRooms2 = [...(displayRooms || [])]
      .filter((r) => !ids.includes(r.id.toString()));
    dispatch(setDisplayRooms([...newRooms2]));
    const newRooms = [...(rooms || [])]
      .filter((r) => !ids.includes(r.id.toString()));
    dispatch(setRoomsNoDisplay([...newRooms]));
  }, [rooms]);

  return {
    addLocalRoom, addLocalRooms, getEditedRooms, editLocalRoom, deleteLocalRooms
  };
};

export const useRooms = () => {
  const rooms = useAppSelector(selectRooms);

  return { rooms };
};

export const useFetchRooms = () => {
  const dispatch = useAppDispatch();
  const rooms = useAppSelector(selectRooms);
  const hotelData = useAppSelector(selectHotelData);

  useEffect(() => {
    const fetchRooms = async () => {
      const res = await dispatch(getRooms());
      dispatch(setRooms([]));

      if (res.status === 'success') {
        dispatch(setRooms(res.data));
        return;
      }
      toast(res.data, { type: 'error' });
      dispatch(setSearch(''));
      dispatch(setSort('Last updated'));
    };

    if (!rooms && hotelData) {
      fetchRooms();
    }
  }, [hotelData]);

  return { rooms };
};

export const useRoomsData = () => {
  const rooms = useAppSelector(selectRooms);
  const [booksToggle, setBooksToggle] = useState<boolean>(false);
  const [booksTimeout, setBooksTimeout] = useState<any[][]>([[]]);

  const getAllBooks = (id: string) => {
    const room = (rooms || []).find((r) => {
      return r.id.toString() === id.toString();
    });
    if (!room || !room.books) {
      return [];
    }

    const { books: b0 } = room;
    // Remove books that have passed
    const books = [...b0].filter((b) => +new Date(b.endDate) >= +new Date());
    books.sort((a, b) => +new Date(a.startDate) - +new Date(b.startDate));
    return [...books];
  };

  const getIsTimeCheckError = (
    roomId: string,
    date: Date,
    addNights: number
  ) => {
    const books0 = getAllBooks(roomId.toString());
    const books = books0.sort((a, b) => +new Date(a.startDate) - +new Date(b.startDate));

    let freeInd = -1;
    let bookedInd = -1;

    for (let i = 0; i < books.length; i += 1) {
      const book = books[i];
      const formattedEDate = new Date(+new Date(book.endDate) + houseKeepingTime);
      if (+date >= +new Date(book.startDate) && +date <= +formattedEDate) {
        bookedInd = i;
      }
    }

    for (let i = 0; i < books.length; i += 1) {
      const book = books[i];
      if (+date <= +new Date(book.startDate)) {
        freeInd = i;
        break;
      }
    }

    const nextBookingDate = books[freeInd]?.startDate;
    const addNights1 = addNights * (24 * 60 * 60 * 1000);
    const nextBookingDateC = new Date(+new Date(nextBookingDate || '') - (
      addNights1 + houseKeepingTime));
    const isBeforeNextBooking = !nextBookingDate || +nextBookingDateC >= +date;

    const lastBookingDate = books[bookedInd]?.endDate;
    const lastBookingDateC = new Date(+new Date(lastBookingDate || '') + houseKeepingTime);
    const isAfterLastBooking = !lastBookingDate || +date >= +lastBookingDateC;
    return !isBeforeNextBooking ? 'isBefore' : !isAfterLastBooking ? 'isAfter' : '';
  };

  const getBooks = (id: string) => {
    const books = getAllBooks(id).filter((b) => +new Date(b.startDate) <= +new Date());

    return [...books];
  };

  const getCurrentBooking = (id: string) => {
    return [...getBooks(id)].pop();
  };

  const getRoomFreeBy = useCallback((id: string) => {
    return getCurrentBooking(id)?.endDate || new Date(0).toISOString();
  }, [booksToggle, rooms]);

  useEffect(() => {
    const bTime = [...booksTimeout];
    bTime.forEach((t) => {
      t?.forEach((t2) => {
        clearTimeout(t2);
        t2 = null;
      });
    });
    setBooksTimeout(bTime);

    rooms?.forEach((r, i) => {
      const books = getAllBooks(r.id.toString());
      books?.forEach((b, j) => {
        const diff = +new Date(b.startDate) - +new Date();
        if (!bTime[i]) bTime[i] = [];
        if (!bTime[i][j] && diff >= -3) {
          bTime[i][j] = setTimeout(() => {
            setBooksToggle(!booksToggle);
            bTime[i][j] = null;
          }, diff + 10);
        }
      });
    });
    setBooksTimeout(bTime);
  }, [rooms]);

  return {
    getAllBooks, getRoomFreeBy, getCurrentBooking, getIsTimeCheckError
  };
};

export const useRoomsDateData = () => {
  const [runningDate, setRunningDate] = useState<Date>(new Date());
  const pauseRunningDate = useAppSelector(selectPauseRunningDate);
  const { getAllBooks } = useRoomsData();

  const [dateInterval, setDateInterval] = useState<any>();

  useEffect(() => {
    clearInterval(dateInterval);
    setDateInterval(null);
    if (!pauseRunningDate) {
      setDateInterval(setInterval(() => {
        setRunningDate(new Date());
      }, (1000)));
    }
    return () => {
      clearInterval(dateInterval);
      setDateInterval(null);
    };
  }, [pauseRunningDate]);

  const getTodayTimeDifference = (roomId: string) => {
    const books = getAllBooks(roomId);
    let index = -1;
    for (let i = 0; i < books.length; i += 1) {
      const book = books[i];
      if (+runningDate <= +new Date(book.startDate)) {
        index = i;
        break;
      }
    }
    if (index === -1) {
      return 0;
    }
    const diff = +new Date(books[index].startDate) - +runningDate;

    return diff;
  };

  return {
    getTodayTimeDifference, runningDate
  };
};
