import styled from 'styled-components';

export const Bg = styled.div`
  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.01;
  z-index: 99;
  transition: opacity ease-in 250ms;
`;

export const Content = styled.div`
  position: fixed;
  right: 50%;
  bottom: calc(0% - 100svh);
  transform: translate(50%, 0%);
  z-index: 100000;
  opacity: 0;
  transition: all ease-out 250ms;
`;
