import Color from 'color';
import { orange } from 'pages/Hospitality/components/Rooms.styled';
import { topBarColor, topBarColor2 } from 'pages/styles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 45px;
  transition: height ease-out 250ms;
  background: ${Color(topBarColor).lighten(0.85).toString()};
  border-bottom: 1px solid ${Color(topBarColor).lighten(0.7).toString()};
  display: flex;
  align-items: center;
  padding: 0px 10px;
  padding-top: 4px;
  transition: all ease-out 250ms;
  color: ${topBarColor2};
`;

export const IconCont0 = styled.div`
  margin-left: auto;
  color: ${orange};
`;

export const IconCont = styled.div`
  cursor: pointer;
  &:hover{
    color: ${orange};
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-left: 10px;
  margin-right: 20px;
  margin-top: -2px;
`;

export const Extend = styled.div`
  border: 1px solid ${topBarColor2};
  padding: 1.5px 5px;
  padding-top: 0px;
  font-size: 13px;
  margin-left: 7px;
  color: ${topBarColor2};
  border-radius: 3px;
  width: max-content;
  &:hover {
    cursor: pointer;
    border-color: ${orange};
    color: ${orange};
  }
`;
