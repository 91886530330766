import React, { useEffect, useState } from 'react';
import * as S from './MetricsTime.styled';
import { convertDate22 } from 'utils';
import Loader from 'pages/components/Loader/Loader';
import moment from 'moment';
import { useAppSelector } from 'redux/hooks';
import { selectCurrency, selectMetricTime, setMetricTime } from 'pages/redux';
import Modal from 'pages/components/Modal/Modal';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Calendar from '@mui/icons-material/DateRange';
import DatePicker from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useDispatch } from 'react-redux';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import ArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import './style.css';
import {
  MoveType,
  selectJointMove,
  selectMoveType,
  setJointMove,
  setMoveType,
} from '../redux';
import MediaQuery from 'react-responsive';
import Color from 'color';
import { metrics, useMetrics } from '../hooks';
import { Tooltip } from 'react-tooltip';
import { oneYearAgo } from 'pages/Logs/hooks';

export const CalendarModal = ({
  label, children, onExit, smallPad
} : {
  label: string, children: any, onExit: Function, smallPad?: true
}) => {
  return (
    <S.CalendarCont smallPad={smallPad} id="calendar">
      {!smallPad ? <EscapeButton onClick={() => onExit()} /> : null}
      <S.CalendarLabel smallPad={smallPad}>{label}</S.CalendarLabel>
      {children}
      <S.MetricsButton1 smallPad={smallPad} onClick={() => onExit()}>
        Done
      </S.MetricsButton1>
    </S.CalendarCont>
  );
};

export const getDayDifferenceText = (startD: Date, endD: Date) => {
  const start = moment(startD, 'M/D/YYYY');
  const end = moment(endD, 'M/D/YYYY');
  const diff0 = moment.duration(end.diff(start));
  const days = (diff0.days() + 1) >= 29 ? 0 : diff0.days() + 1;
  const monthsTmp = days === 0 ? diff0.months() + 1 : diff0.months();
  const years = (monthsTmp === 12) ? diff0.years() + 1 : diff0.years();
  const months = (monthsTmp < 12) ? monthsTmp : 0;
  const result = `${years ? ` ${years} year${years === 1 ? '' : 's'}` : ''}
    ${months ? `${months} month${months === 1 ? '' : 's'}` : ''}
    ${days ? `${days} day${days === 1 ? '' : 's'}` : ''}`;
  return result;
};

const MetricsTime = ({
  checked
} : {
  checked: string
}) => {
  const metricTime = useAppSelector(selectMetricTime);
  const { checkedMetric, metricTimeLogs: logs } = useMetrics();
  const [startD, setStartDTmp] = useState<Date | string>(new Date());
  const [endD, setEndDTmp] = useState<Date | string>(new Date());
  const [startDate, setStartDate] = useState<string>(convertDate22(new Date(startD)));
  const [endDate, setEndDate] = useState<string>(convertDate22(new Date(endD)));
  const [isPickStartDate, setIsPickStartDate] = useState<boolean>(false);
  const [isPickEndDate, setIsPickEndDate] = useState<boolean>(false);
  const [isTimeExpanded, setIsTimeExpanded] = useState<boolean>(true);
  const [hasSetStartDate, setHasSetStartDate] = useState<boolean>(false);
  const jointMove = useAppSelector(selectJointMove) === 'yes';
  const moveType = useAppSelector(selectMoveType);
  const currency = useAppSelector(selectCurrency);
  const dispatch = useDispatch();

  const result = getDayDifferenceText(new Date(startD), new Date(endD));

  useEffect(() => {
    if (hasSetStartDate) {
      if (startD) setStartDate(convertDate22(new Date(startD)));
      if (endD) setEndDate(convertDate22(new Date(endD)));
      dispatch(setMetricTime({ startDate: new Date(startD), endDate: new Date(endD) }));
    }
  }, [startD, endD]);

  const setStartD = (startDHere: string | Date) => {
    const sDTmp = new Date(startDHere);
    sDTmp.setHours(0, 0, 0, 0);
    const sDate = document.getElementById('s_date');
    if (sDate && +new Date(startD) !== +new Date(sDTmp)) {
      sDate.style.transition = 'background ease-out 200ms';
      sDate.style.background = Color('#1685ec').lighten(0.8).toString();
      setTimeout(() => {
        sDate.style.transition = 'background ease-in 150ms';
        sDate.style.background = 'inherit';
      }, 201);
    }
    setStartDTmp(sDTmp);
    setStartDate(convertDate22(sDTmp));
  };

  useEffect(() => {
    const earliestLogDate = logs[0]?.date ? new Date(logs[0].date) : new Date();

    if (+(metricTime?.startDate || new Date()) < +earliestLogDate && hasSetStartDate) {
      setStartD(earliestLogDate);
      const endDate1 = +(metricTime?.endDate || 0) < +earliestLogDate ?
        earliestLogDate : metricTime?.endDate || new Date();
      endDate1.setHours(23, 59, 59, 999);
      dispatch(setMetricTime({
        startDate: earliestLogDate,
        endDate: endDate1
      }));
      setEndDate(convertDate22(endDate1));
      setEndD(endDate1);
    }
  }, [checked]);

  useEffect(() => {
    if (logs.length && !hasSetStartDate) {
      const lateEndToday = new Date();
      lateEndToday.setHours(23, 59, 59, 999);
      const earliestLogDate = new Date(logs[0].date);

      if (+(metricTime?.startDate || new Date()) < +earliestLogDate) {
        setStartD(earliestLogDate);
        setEndDate(convertDate22(lateEndToday));
        setEndD(lateEndToday);
        dispatch(setMetricTime({
          startDate: earliestLogDate,
          endDate: lateEndToday
        }));
      } else {
        setStartD(metricTime?.startDate || new Date());
        setEndDate(convertDate22(metricTime?.endDate || new Date()));
        setEndD(metricTime?.endDate || new Date());
      }
      setTimeout(() => setHasSetStartDate(true), 300);
    }
  }, [logs]);

  const earlyEndD = new Date(endD);
  earlyEndD.setHours(0, 0, 0, 0);
  const setEndD = (endDHere: string | Date) => {
    const { endDTmp1 } = JSON.parse(JSON.stringify({ endDTmp1: endDHere }));
    const endDTmp = new Date(endDTmp1);
    endDTmp.setHours(23, 59, 59, 999);
    setEndDTmp(endDTmp);
    const eDate = document.getElementById('e_date');
    if (eDate && new Date(earlyEndD).getTime() !== new Date(endDHere).getTime()) {
      eDate.style.transition = 'background ease-out 200ms';
      eDate.style.background = Color('#1685ec').lighten(0.8).toString();
      setTimeout(() => {
        eDate.style.transition = 'background ease-in 150ms';
        eDate.style.background = 'inherit';
      }, 201);
    }
  };

  const calendarExit = () => {
    setIsPickEndDate(false);
    setIsPickStartDate(false);
  };

  const earlyLogDateSD = new Date(logs?.[0]?.date || '');
  earlyLogDateSD.setHours(0, 0, 0, 0);
  let aBFunctionED: Function;
  let weekABFunctionED: Function;
  const dayTime = 60 * 60 * 24 * 1000;
  const aBDisabledSD = new Date(startD).getTime() <= earlyLogDateSD.getTime();

  const aBFunctionSD = (noJointMove?: boolean) => {
    if (aBDisabledSD) return;
    const date = new Date(startD);
    const date1 = new Date(earlyEndD);
    if (noJointMove && (date1.getTime() - date.getTime()) / dayTime >= 1) {
      date1.setDate(date1.getDate() - 1);
      setStartD(date1);
    } else {
      date.setDate(date.getDate() - 1);
      setStartD(date);
    }
    if (jointMove && !noJointMove) {
      aBFunctionED(true);
    }
  };

  const earlyLogEndDate = new Date();
  earlyLogEndDate.setHours(0, 0, 0, 0);

  const weekABFunctionSD = (noJointMove?: boolean) => {
    if (aBDisabledSD && !noJointMove) return;
    const date1 = new Date(earlyEndD);
    const isEDLessThanAWeekApart = date1.getTime() - new Date(startD).getTime()
      < 6 * dayTime;
    if (noJointMove && !isEDLessThanAWeekApart) {
      date1.setDate(date1.getDate() - 13);
      const date = date1.getTime() < earlyLogDateSD.getTime() ? earlyLogDateSD : date1;
      setStartD(date);
    } else {
      if (isEDLessThanAWeekApart && jointMove) {
        const date = new Date(earlyEndD);
        date.setDate(date.getDate() - 6);
        const date11 = date.getTime() > earlyLogDateSD.getTime() ? date : earlyLogDateSD;
        setStartD(date11);
      } else {
        const date = new Date(startD);
        const diff1 = date.getTime() - earlyLogDateSD.getTime();
        const rem = Math.floor(diff1 % dayTime) === 0 ? 0 : 1;
        const diff = diff1 < 7 * dayTime ? Math.trunc(diff1 / dayTime) + rem : 7;
        date.setDate(date.getDate() - diff);
        setStartD(date);
      }
    }
    if (jointMove && !noJointMove) {
      weekABFunctionED(true);
    }
  };

  const isSDateAtEDate = new Date(startD).getTime() >= new Date(earlyEndD).getTime();
  const aFDisabledSD = new Date(startD).getTime() >= earlyLogEndDate.getTime();

  let aFFunctionED: Function;
  let weekAFFunctionED: Function;

  const aFFunctionSD = (noJointMove?: boolean) => {
    if (aFDisabledSD) return;
    const date = new Date(startD);
    const date1 = new Date(earlyEndD);
    if (noJointMove && (date1.getTime() - date.getTime()) / dayTime >= 1) {
      date1.setDate(date1.getDate() + 1);
      setStartD(date1);
    } else {
      date.setDate(date.getDate() + 1);
      setStartD(date);
    }
    if ((isSDateAtEDate || jointMove) && !noJointMove) {
      aFFunctionED(true);
    }
  };

  const weekAFFunctionSD = (noJointMove?: boolean) => {
    if (aFDisabledSD) return;
    const date = new Date(startD);
    const date1 = new Date(earlyEndD);
    const isEDLessThanAWeekApart = new Date(earlyEndD).getTime() - new Date(startD).getTime()
      < 6 * dayTime;
    if (isEDLessThanAWeekApart || noJointMove) {
      if (!isEDLessThanAWeekApart) {
        date1.setDate(date1.getDate() + 1);
        if (date1.getTime() <= earlyLogEndDate.getTime()) {
          setStartD(date1);
        } else {
          setStartD(earlyEndD);
        }
      } else {
        if (date1.getTime() >= earlyLogEndDate.getTime()) {
          setStartD(earlyLogEndDate);
        }
      }
    } else {
      const date2 = isSDateAtEDate || jointMove ? earlyLogEndDate : earlyEndD;
      const diff1 = date2.getTime() - date.getTime();
      const rem = Math.floor(diff1 % dayTime) === 0 ? 0 : 1;
      const diff = diff1 < 7 * dayTime ? Math.trunc(diff1 / dayTime) + rem : 7;
      date.setDate(date.getDate() + diff);
      setStartD(date);
    }
    if ((isEDLessThanAWeekApart || jointMove) && !noJointMove) {
      weekAFFunctionED(true);
    }
  };

  let monthABFunctionED: Function;

  const monthABFunctionSD = (noJointMove?: boolean) => {
    if (aBDisabledSD) return;
    const monthBackDateSD = noJointMove ? new Date(earlyEndD) : new Date(startD);
    monthBackDateSD.setDate(1);
    monthBackDateSD.setMonth(monthBackDateSD.getMonth() - 1);
    let date: Date = monthBackDateSD;
    if (date.getTime() < earlyLogDateSD.getTime()) {
      date = earlyLogDateSD;
    }
    setStartD(date);
    if (jointMove && !noJointMove) {
      monthABFunctionED(true);
    }
  };

  let monthAFFunctionED: Function;

  const monthAFFunctionSD = (noJointMove?: boolean) => {
    if (aFDisabledSD) return;
    const monthForwardDateSD = noJointMove ? new Date(earlyEndD) : new Date(startD);
    monthForwardDateSD.setDate(1);
    monthForwardDateSD.setMonth(monthForwardDateSD.getMonth() + 1);
    let date: Date = monthForwardDateSD;
    if (date.getTime() > earlyLogEndDate.getTime()) {
      date = earlyLogEndDate;
    }
    setStartD(date);
    const isEndDateCrossed = date.getTime() > new Date(earlyEndD).getTime();
    if ((isEndDateCrossed || jointMove) && !noJointMove) {
      monthAFFunctionED(true);
    }
  };

  const isEDateAtSDate = new Date(earlyEndD).getTime() <= new Date(startD).getTime();
  const aBDisabledED = new Date(earlyEndD).getTime() <= earlyLogDateSD.getTime();

  aBFunctionED = (noJointMove?: boolean) => {
    if (aBDisabledED) return;
    const date = new Date(earlyEndD);
    const date1 = new Date(startD);
    if (noJointMove && (date.getTime() - date1.getTime()) / dayTime >= 1) {
      date1.setDate(date1.getDate() - 1);
      setEndD(date1);
    } else {
      date.setDate(date.getDate() - 1);
      setEndD(date);
    }
    if ((isEDateAtSDate || jointMove) && !noJointMove) {
      aBFunctionSD(true);
    }
  };

  weekABFunctionED = (noJointMove?: boolean) => {
    if (aBDisabledED) return;
    const date = new Date(earlyEndD);
    const date1 = new Date(startD);
    const isSDMoreThanOneWeekBehind = date.getTime() - date1.getTime() > 5 * dayTime;
    if (noJointMove) {
      if (isSDMoreThanOneWeekBehind) {
        date1.setDate(date1.getDate() - 1);
        if (date1.getTime() >= earlyLogDateSD.getTime()) {
          setEndD(date1);
        } else {
          setEndD(new Date(startD));
        }
      }
    } else {
      if (isSDMoreThanOneWeekBehind) {
        const date2 = earlyLogDateSD;
        const diff1 = date.getTime() - date2.getTime();
        const rem = Math.floor(diff1 % dayTime) === 0 ? 0 : 1;
        const diff = diff1 < 7 * dayTime ? Math.trunc(diff1 / dayTime) + rem : 7;
        date.setDate(date.getDate() - diff);
        setEndD(date);
      } else {
        if (date1.getTime() <= earlyLogDateSD.getTime()) {
          setEndD(date1);
        }
      }
    }
    if ((!isSDMoreThanOneWeekBehind || jointMove) && !noJointMove) {
      weekABFunctionSD(true);
    }
  };

  const aFDisabledED = new Date(earlyEndD).getTime() >= earlyLogEndDate.getTime();

  aFFunctionED = (noJointMove?: boolean) => {
    if (aFDisabledED) return;
    const date1 = new Date(startD);
    const date = new Date(earlyEndD);
    if (noJointMove && (date.getTime() - date1.getTime()) / dayTime >= 1) {
      date1.setDate(date1.getDate() + 1);
      setEndD(date1);
    } else {
      date.setDate(date.getDate() + 1);
      setEndD(date);
    }
    if (jointMove && !noJointMove) {
      aFFunctionSD(true);
    }
  };

  weekAFFunctionED = (noJointMove?: boolean) => {
    if (aFDisabledED && !noJointMove) return;
    const date = new Date(earlyEndD);
    const date1 = new Date(startD);
    const isSDMoreThanOneWeekBehind = date.getTime() - date1.getTime() > 5 * dayTime;
    if (noJointMove && isSDMoreThanOneWeekBehind) {
      date1.setDate(date1.getDate() + 13);
      const date2 = date1.getTime() > earlyLogEndDate.getTime() ? earlyLogEndDate : date1;
      setEndD(date2);
    } else {
      if (!isSDMoreThanOneWeekBehind && jointMove) {
        date1.setDate(date1.getDate() + 6);
        const date11 = date1.getTime() < earlyLogEndDate.getTime() ? date1 : earlyLogEndDate;
        setEndD(date11);
      } else {
        const diff1 = earlyLogEndDate.getTime() - date.getTime();
        const rem = Math.floor(diff1 % dayTime) === 0 ? 0 : 1;
        const diff = diff1 < 7 * dayTime ? Math.trunc(diff1 / dayTime) + rem : 7;
        date.setDate(date.getDate() + diff);
        setEndD(date);
      }
    }
    if (jointMove && !noJointMove) {
      weekAFFunctionSD(true);
    }
  };

  monthABFunctionED = (noJointMove?: boolean) => {
    if (aBDisabledED) return;
    const monthBackDateED = new Date(earlyEndD);
    monthBackDateED.setDate(0);
    let date: Date = monthBackDateED;
    if (monthBackDateED.getTime() < new Date(earlyLogDateSD).getTime()) {
      date = new Date(earlyLogDateSD);
    }
    setEndD(date);
    const isStartDateCrossed = date.getTime() < new Date(startD).getTime();
    if ((isStartDateCrossed || jointMove) && !noJointMove) {
      monthABFunctionSD(true);
    }
  };

  monthAFFunctionED = (noJointMove?: boolean) => {
    if (aFDisabledED) return;
    const monthForwardDateED = noJointMove ? new Date(startD) : new Date(earlyEndD);
    monthForwardDateED.setDate(1);
    monthForwardDateED.setMonth(monthForwardDateED.getMonth() + 2);
    monthForwardDateED.setDate(0);
    let date: Date = monthForwardDateED;
    const latestLogDate = new Date();
    if (monthForwardDateED.getTime() > latestLogDate.getTime()) {
      date = latestLogDate;
    }
    setEndD(date);
    if (jointMove && !noJointMove) {
      monthAFFunctionSD(true);
    }
  };

  const actionsSD = {
    back: {
      month: monthABFunctionSD,
      week: weekABFunctionSD,
      day: aBFunctionSD
    },
    forward: {
      month: monthAFFunctionSD,
      week: weekAFFunctionSD,
      day: aFFunctionSD
    },
  };

  const actionsED = {
    back: {
      month: monthABFunctionED,
      week: weekABFunctionED,
      day: aBFunctionED
    },
    forward: {
      month: monthAFFunctionED,
      week: weekAFFunctionED,
      day: aFFunctionED
    },
  };

  const toggleShowTimeSetter = () => {
    const mainCont = document.getElementById('mainCont');
    const topCont = document.getElementById('topCont');
    if (mainCont && topCont) {
      const height = topCont.offsetHeight;
      if (isTimeExpanded) {
        const mHeight = mainCont.offsetHeight;
        mainCont.style.transition = 'none';
        mainCont.style.height = `${mHeight - height - 7.5}px`;
        mainCont.style.transition = 'all ease-in 250ms';
        mainCont.style.transform = `translateY(calc(-${height}px - 7.5px))`;
        setIsTimeExpanded(false);
      } else {
        const mHeight = mainCont.offsetHeight;
        mainCont.style.transition = 'none';
        mainCont.style.height = `${mHeight + height - 7.5}px`;
        mainCont.style.transition = 'all ease-in 250ms';
        mainCont.style.transform = 'translateY(0px)';
        setIsTimeExpanded(true);
      }
    }
  };

  return (
    <S.Container>
      { !logs && <Loader />}
      { isPickStartDate && (
        <Modal
          component={(
            <CalendarModal label="Set start date" onExit={() => calendarExit()}>
              <DatePicker
                value={new Date(startD)}
                minDate={new Date(logs?.[0]?.date || oneYearAgo)}
                maxDate={new Date(new Date(endD || '').setDate(new Date(endD || '').getDate() - 0))}
                onChange={(v: any) => {
                  const vTmp = new Date(v);
                  vTmp.setHours(0, 0, 0, 0);
                  setStartD(new Date(vTmp));
                }}
              />
            </CalendarModal>
          )}
          exitOnBgClick={() => setIsPickStartDate(false)}
        />
      )}
      { isPickEndDate && (
        <Modal
          component={(
            <CalendarModal label="Set end date" onExit={() => calendarExit()}>
              <DatePicker
                value={new Date(endD)}
                maxDate={new Date()}
                minDate={new Date(new Date(startD || '').setDate(new Date(startD || '').getDate() + 0))}
                onChange={(v: any) => {
                  const vTmp = new Date(v);
                  vTmp.setHours(23, 59, 59, 999);
                  setEndD(new Date(vTmp));
                }}
              />
            </CalendarModal>
          )}
          exitOnBgClick={() => setIsPickEndDate(false)}
        />
      )}
      <S.MetricCont>
        <S.TopCont id="topCont">
          <S.BlockCont>
            <S.MoveCont>
              <S.Today
                onClick={() => {
                  setEndD(new Date());
                  setStartD(new Date());
                }}
                data-tooltip-id="today"
                data-tooltip-content="Show metrics for today"
              >
                Today
              </S.Today>
              <S.Today
                onClick={() => {
                  setEndD(earlyLogEndDate);
                  setStartD(earlyLogDateSD);
                }}
                data-tooltip-id="allTime"
                data-tooltip-content="Show metrics for 50K audits or less"
              >
                <MediaQuery minWidth={Number(S.metricsTimeRes2.replace('px', '')) + 0.1}>
                  All time
                </MediaQuery>
                <MediaQuery maxWidth={Number(S.metricsTimeRes2.replace('px', ''))}>
                  Life
                </MediaQuery>
              </S.Today>
              <S.Select1
                onChange={(e) => {
                  dispatch(setMoveType(e.target.value.replace('Move ', '') as MoveType));
                }}
                data-tooltip-id="moveTime"
                data-tooltip-content={`Move dates by ${moveType} when arrow keys are pressed`}
              >
                {
                  ['day', 'week', 'month'].map((moveT) => (
                    <>
                      <MediaQuery minWidth={Number(S.moveTypeRes.replace('px', '')) + 0.1}>
                        <option
                          style={{ fontSize: '12px', color: '#525252' }}
                          selected={moveType === moveT}
                          key={`moveType_${moveT}`}
                        >
                          {`Move ${moveT}`}
                        </option>
                      </MediaQuery>
                      <MediaQuery maxWidth={Number(S.moveTypeRes.replace('px', ''))}>
                        <option
                          style={{ fontSize: '12px', color: '#525252' }}
                          selected={moveType === moveT}
                          key={`moveType_${moveT}`}
                        >
                          {moveT}
                        </option>
                      </MediaQuery>
                    </>
                  ))
                }
              </S.Select1>
              <S.MoveCont1
                onClick={() => {
                  dispatch(setJointMove(jointMove ? 'no' : 'yes'));
                }}
                data-tooltip-id="bothMove"
                data-tooltip-content="Move both Start date and End date when arrow keys are moved"
              >
                <S.JointMoveText>
                  <MediaQuery minWidth={Number(S.metricsTimeRes2.replace('px', '')) + 0.1}>
                    Both move
                  </MediaQuery>
                  <MediaQuery maxWidth={Number(S.metricsTimeRes2.replace('px', ''))}>
                    Joint
                  </MediaQuery>
                </S.JointMoveText>
                <S.Select
                  type="checkbox"
                  checked={jointMove}
                  readOnly
                />
              </S.MoveCont1>
            </S.MoveCont>
            <S.DateCont>
              <S.DateSelectCont>
                <S.DateSect>
                  <S.IconCont>
                    <S.Icon2 isDisabled={aBDisabledSD} onClick={() => actionsSD.back[moveType]()}>
                      <ArrowLeft fontSize="small" sx={{ scale: '1.35' }} />
                    </S.Icon2>
                    <S.Date id="s_date">
                      <S.Label>Start date</S.Label>
                      <S.Label2>
                        {startDate}
                      </S.Label2>
                    </S.Date>
                    <S.Icon1 onClick={() => setIsPickStartDate(true)}>
                      <Calendar fontSize="small" style={{ scale: '1.05' }} />
                    </S.Icon1>
                    <S.Icon
                      isDisabled={aFDisabledSD}
                      onClick={() => actionsSD.forward[moveType]()}
                    >
                      <ArrowRight fontSize="small" sx={{ scale: '1.35' }} />
                    </S.Icon>
                  </S.IconCont>
                </S.DateSect>
                <S.Hyphen>-</S.Hyphen>
                <S.Hyphen1>|</S.Hyphen1>
                <S.DateSect>
                  <S.IconCont>
                    <S.Icon2 isDisabled={aBDisabledED} onClick={() => actionsED.back[moveType]()}>
                      <ArrowLeft fontSize="small" sx={{ scale: '1.35' }} />
                    </S.Icon2>
                    <S.Date id="e_date">
                      <S.Label>End date</S.Label>
                      <S.Label2>
                        {endDate}
                      </S.Label2>
                    </S.Date>
                    <S.Icon1 onClick={() => setIsPickEndDate(true)}>
                      <Calendar fontSize="small" style={{ scale: '1.05' }} />
                    </S.Icon1>
                    <S.Icon
                      isDisabled={aFDisabledED}
                      onClick={() => actionsED.forward[moveType]()}
                    >
                      <ArrowRight fontSize="small" sx={{ scale: '1.35' }} />
                    </S.Icon>
                  </S.IconCont>
                </S.DateSect>
              </S.DateSelectCont>
            </S.DateCont>
          </S.BlockCont>
          <S.RightPartCont>
            <S.Result>
              <S.Result1>
                Date range set is
                {result}
              </S.Result1>
              <S.AbsPart>
                <S.MetricsButton
                  onClick={() => checkedMetric.onClick()}
                >
                  View audits
                </S.MetricsButton>
              </S.AbsPart>
            </S.Result>
          </S.RightPartCont>
        </S.TopCont>
        <S.TotalCont>
          <S.TotalTextPart>
            <S.TotalBlockPart>
              <S.TotalText>
                TOTAL
              </S.TotalText>
            </S.TotalBlockPart>
          </S.TotalTextPart>
          <S.Total
            isNegative={
              metrics.Counts.includes(checked as any) ? null : checked === 'Cash-out' ||
              checkedMetric.total() < 0
            }
          >
            {
              metrics.Counts.includes(checked as any) ? checkedMetric.total() :
                `${currency}${checkedMetric.total().toLocaleString()}`
            }
          </S.Total>
          <S.ExpandIcon onClick={() => toggleShowTimeSetter()}>
            {
              (isTimeExpanded) ? <ExpandLess fontSize="small" sx={{ scale: '1.3' }} /> :
              <ExpandMore fontSize="small" sx={{ scale: '1.3' }} />
            }
          </S.ExpandIcon>
        </S.TotalCont>
      </S.MetricCont>
      <Tooltip id="today" delayShow={650} closeEvents={{ click: true, mouseleave: true, blur: true }} />
      <Tooltip id="allTime" delayShow={650} closeEvents={{ click: true, mouseleave: true, blur: true }} />
      <Tooltip id="moveTime" delayShow={650} closeEvents={{ click: true, mouseleave: true, blur: true }} />
      <Tooltip id="bothMove" delayShow={650} closeEvents={{ click: true, mouseleave: true, blur: true }} />
    </S.Container>
  );
};

export default React.memo(MetricsTime);
