import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Rate, RoomType } from 'pages/Settings/redux';
import { RootState } from 'redux/store';

export type ReserveDates = {
  start?: Date,
  end?: Date
}

type SelectedBookRoom = {
  roomType: RoomType | undefined,
  ratePlan: Rate | undefined,
  amount: number,
  isNew: boolean,
}

export interface AppState {
  reserveDates: ReserveDates,
  selectedBookRooms: SelectedBookRoom[]
}

const initialState: AppState = {
  reserveDates: {},
  selectedBookRooms: []
};

export const counterSlice = createSlice({
  name: 'reserveRooms',
  initialState,
  reducers: {
    setReserveDates: (state, action: PayloadAction<ReserveDates>) => {
      state.reserveDates = action.payload;
    },
    setSelectedBookRooms: (state, action: PayloadAction<SelectedBookRoom[]>) => {
      state.selectedBookRooms = action.payload;
    }
  },
});

export const {
  setReserveDates,
  setSelectedBookRooms
} = counterSlice.actions;

export const selectReserveDates = (state: RootState) => state.reserveRooms.reserveDates;
export const selectSelectedBookRooms = (state: RootState) => state.reserveRooms.selectedBookRooms;

export default counterSlice.reducer;
