import Color from 'color';
import { topBarColor2 } from 'pages/styles';
import styled from 'styled-components';
import { redColor } from '../components/LogTable.styled';

const orange = Color('#d6a615').darken(0).toString();

export const Container = styled.div`
  background: white;
  padding: 20px;
  pointer-events: auto;
  width: 95vw;
  max-width: 400px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  color: #525252;
  transition: all ease-out 250ms;
`;

export const Header = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding-top: 5px;
  background: white;
  color: ${Color('#525252').darken(0.2).toString()};
`;

export const Entry = styled.div`
  display: inline-block;
  font-weight: 700;
  color: #525252;
  border-radius: 3px;
  margin-right: 10px;
  font-size: 20px;
  letter-spacing: 0.5;
  margin-top: 40px;
`;

export const Label = styled.div`
  margin-top: 7px;
  font-size: 14px;
`;

export const ButtonsCont = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 30px;
`;

export const Button0 = styled.div`
  padding: 9px 14px;
  background: ${redColor};
  color: white;
  border-radius: 3px;
  font-weight: 700;
  font-size: 13px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background: ${orange};
    color: white;
  }
`;

export const Button1 = styled(Button0)`
  padding: 9px 0px;
  background: none;
  color: ${topBarColor2};
  &:hover {
    background: none;
    color: ${orange};
  }
`;
