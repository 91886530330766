import {
  bgColor,
} from 'pages/styles';
import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div<{ isFitScreen: boolean }>`
  position: relative;
  z-index: 2;
  flex: 1;
  display: flex;
  flex-direction: ${({ isFitScreen }) => !isFitScreen && 'column'};
  overflow-y: auto;
`;

export const Page = styled.div`
  position: relative;
  display: flex;
  z-index: 13;
  flex: 1;
  width: 100%;
`;

export const DebugBg = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: black;
  color: white;
  font-size: 20px;
  font-weight: 500;
  max-height: 35vh;
  overflow: auto;
`;

export const Bg = styled.div`
  height: 100%;
  width: 100vw;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;
  background: black;
  opacity: 0;
  display: none;
  transition: all ease-in 250ms;
`;

export const BottomRightPanel = styled.div<{ isFitScreen: boolean }>`
  width: 100%;
  flex: 1;
  overflow: hidden;
  z-index: 2;
  display: flex;
  background: ${bgColor};
  ${({ isFitScreen }) => !isFitScreen && 'min-height: 65svh'};
  ${({ isFitScreen }) => !isFitScreen && 'height: max-content'};
`;
