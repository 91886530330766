import React, { ReactElement } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Options = {
  [key: string]: string | ReactElement;
  uploadType: string,
  icon: ReactElement;
  color: string,
}

export const importOptions: Array<Options> = [
  {
    uploadType: 'CSV',
    icon: <FontAwesomeIcon icon={faFileCsv as IconProp} size="2x" />,
    color: '#1ba13d',
  },
  {
    uploadType: 'EXCEL',
    icon: <FontAwesomeIcon icon={faFileExcel as IconProp} size="2x" />,
    color: '#1ba13d',
  },
  // {
  //   uploadType: 'Database',
  //   icon: <FontAwesomeIcon icon={faServer} size="3x" />,
  //   color: '#6488ec',
  // },
];

export type ImportType = 'CSV' | 'EXCEL';

export const formatExcelData = (excelData: any, getHeaders?: boolean) => {
  // const acceptedHeaders: any = [];
  const headers = excelData[0].map((data: any) => {
    // while (acceptedHeaders.includes(data)) {
    //   data = `${data}2`;
    // }
    // acceptedHeaders.push(data);
    return data;
  });

  if (getHeaders) {
    return headers;
  } else {
    const formattedData: any = [];
    for (let i = 1; i < excelData.length; i += 1) {
      const entry: any = {};
      for (let j = 0; j < excelData[i].length; j += 1) {
        if (excelData[i][j]) entry[headers[j]] = excelData[i][j];
      }
      formattedData.push(entry);
    }
    return formattedData;
  }
};

export const getDataFromCSV = (
  files: any,
  setImportedData: Function,
) => {
  // dispatch(setShowPopup({ component: <LoadingDialogue text="Parsing CSV" /> }));
  Papa.parse(files[0], {
    complete: (result: any) => {
      const formattedSheetData = [{
        type: 'CSV' as ImportType,
        name: '',
        sheet: result.data,
        headers: Object.keys(result.data[0]),
      }];
      // console.log(result.data, formattedSheetData);
      setImportedData(formattedSheetData);
    },
    header: true,
    skipEmptyLines: true,
  });
};

export type ImportedData = {
  type: ImportType,
  name: string,
  sheet: Record<string, string | number >[],
  headers: string[]
}

export const getDataFromExcel = (
  files: any,
  // eslint-disable-next-line no-unused-vars
  setImportedData: (e: ImportedData[] | null) => void,
) => {
  // dispatch(setShowPopup({ component: <LoadingDialogue text="Parsing Excel" /> }));
  const reader = new FileReader();
  reader.readAsArrayBuffer(files[0]);
  reader.onload = () => {
    const excelData = new Uint8Array(reader.result as ArrayBufferLike);
    const workBook = XLSX.read(excelData, { type: 'array' });
    const sheetNames = workBook.SheetNames;
    const sheetData = sheetNames.map((name: any) => (
      { name, sheet: XLSX.utils.sheet_to_json(workBook.Sheets[name], { header: 1 }) }
    ));
    const formattedSheetData = sheetData.map((data: any) => ({
      type: 'EXCEL' as ImportType,
      name: data.name,
      sheet: formatExcelData(data.sheet),
      headers: formatExcelData(data.sheet, true),
    }));
    setImportedData(formattedSheetData);
  };
};
