import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { MetricsKey } from './hooks';
import { Log } from 'pages/Logs/hooks';

export type MoveType = 'month' | 'week' | 'day';
export type MetricsFlowType = '+' | '-' | 'both' | 'count';
export type MetricLog = Log & { count: number };

interface MetricsState {
  jointMove: string,
  moveType: MoveType,
  metricChecked: MetricsKey,
  metricsFlowType: MetricsFlowType,
  metricLogs: MetricLog[]
}

const initialState: MetricsState = {
  jointMove: localStorage.getItem('joint_move') || '',
  moveType: localStorage.getItem('move_type') as MoveType || 'day',
  // This setting of Hospitatlity is temporary, check Navigation hooks
  metricChecked: 'Hospitality',
  metricsFlowType: 'both',
  metricLogs: []
};

export const counterSlice = createSlice({
  name: 'views',
  initialState,
  reducers: {
    setJointMove: (state, action: PayloadAction<string>) => {
      state.jointMove = action.payload;
      localStorage.setItem('joint_move', action.payload);
    },
    setMoveType: (state, action: PayloadAction<MoveType>) => {
      state.moveType = action.payload;
      localStorage.setItem('move_type', action.payload);
    },
    setMetricChecked: (state, action: PayloadAction<MetricsKey>) => {
      state.metricChecked = action.payload;
    },
    setMetricsFlowType: (state, action: PayloadAction<MetricsFlowType>) => {
      state.metricsFlowType = action.payload;
    },
    setMetricLogs: (state, action: PayloadAction<MetricLog[]>) => {
      state.metricLogs = action.payload;
    }
  },
});

export const {
  setJointMove,
  setMoveType,
  setMetricChecked,
  setMetricsFlowType,
  setMetricLogs
} = counterSlice.actions;

export const selectJointMove = (state: RootState) => state.metrics.jointMove;
export const selectMoveType = (state: RootState) => state.metrics.moveType;
export const selectMetricChecked = (state: RootState) => state.metrics.metricChecked;
export const selectMetricsFlowType = (state: RootState) => state.metrics.metricsFlowType;
export const selectMetricLogs = (state: RootState) => state.metrics.metricLogs;

export default counterSlice.reducer;
