import Color from 'color';
import { cashColor, redColor } from 'pages/Logs/components/LogTable.styled';
import {
  borderColor,
  mobileRes2,
  topBarColor2,
} from 'pages/styles';
import styled from 'styled-components';
import { orange } from 'pages/Hospitality/components/Rooms.styled';
import { lFColor0 } from 'pages/components/LFHeader/LFHeader.styled';

const mobileSmallRes = '379px';
const metricsTimeRes1 = '863px';
export const mobileMiniRes = '558px';
const mobileMiniRes1 = '400px';
export const moveTypeRes = '342px';
export const metricsTimeRes2 = '401px';
const metricsTimeRes4 = '293px';
const metricsTimeRes3 = '286px';

export const Container = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 7px;
  padding-bottom: 0px;
  max-width: 100%;
  position: sticky;
  top: 0;
  z-index: 20;
`;

export const CalendarCont = styled.div<{ smallPad?: true }>`
  width: max-content;
  max-width: 95vw;
  border-radius: 3px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  background: white;
  margin-top: ${({ smallPad }) => smallPad && '25px'};
`;

export const CalendarLabel = styled.div<{ smallPad?: true }>`
  padding: ${({ smallPad }) => smallPad ? '5px 0px' : '11px 16px'};
  padding-top: ${({ smallPad }) => smallPad ? '0px' : '45px'};
  padding-bottom: ${({ smallPad }) => smallPad && '11px'};
  font-size: 17px;
  font-weight: 500;
`;

export const CalendarCont1 = styled.div`
  overflow-x: auto;
  max-width: 100%;
`;

export const MetricCont = styled.div`
  max-width: 100%;
  color: ${Color('#525252').lighten(0.7).toString()};
`;

export const TopCont = styled.div`
  display: flex;
  gap: 7.5px;
  @media(max-width: ${metricsTimeRes1}) {
    flex-direction: column;
    gap: 0px;
    box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
    -webkit-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
    -moz-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  }
`;

export const BlockCont = styled.div`
  width: 70%;
  max-width: 700px;
  background: white;
  box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  -webkit-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  -moz-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  border-radius: 2px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  @media(max-width: ${metricsTimeRes1}) {
    min-width: 0px;
    width: 100%;
    max-width: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const MoveCont = styled.div`
  padding: 13px 15px;
  padding-bottom: 0px;
  display: flex;
  gap: 10px;
`;

export const Today = styled.div`
  padding: 0px 11px;
  padding-top: 2px;
  color: ${Color('lightgrey').darken(0.4).toString()};  
  background: ${Color('lightgrey').lighten(0.125).toString()};
  width: max-content;
  height: 29.5px;
  border-radius: 3px;
  font-size: 10.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: ${Color('lightgrey').lighten(0.05).toString()};
  }
  @media(max-width: ${mobileRes2}) {
  }
`;

export const Select1 = styled.select`
  width: 98px;
  height: 29px;
  padding-left: 7px;
  padding-top: 1px;
  margin: 0;
  border: none;
  font-family: Poppins;
  background: ${Color('lightgrey').lighten(0.125).toString()};
  font-size: 10.5px;
  letter-spacing: 0.35px;
  border-radius: 3px;
  font-weight: 400;
  color: ${Color('lightgrey').darken(0.4).toString()};
  cursor: pointer;
  @media(max-width: ${moveTypeRes}) {
    width: 63px;
  }
  &:hover {
    background: ${Color('lightgrey').lighten(0.05).toString()};
  }
`;

export const MoveCont1 = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Color('lightgrey').lighten(0.125).toString()};
  color: ${Color(borderColor).darken(0.1).toString()};  
  height: 29px;
  padding: 0 11px;
  padding-right: 8px;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    background: ${Color('lightgrey').lighten(0.05).toString()};
  }
`;

export const JointMoveText = styled.div`
  font-size: 10.5px;
  margin-top: 2px;
  pointer-events: none;
`;

export const Select = styled.input`
  height: 14px;
  width: 14px;
  margin: 0;
  margin-left: 6px;
  pointer-events: none;
  transform: translateY(0px);
`;

export const DateCont = styled.div`
  display: flex;
`;

export const DateSelectCont = styled.div`
  width: 100%;
  border-top-left-radius: 4px;
  padding: 15px 15px;
  padding-top: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 14.5px;
  color: ${Color('#525252').lighten(0.35).toString()};
  @media(max-width: ${mobileSmallRes}) {
    padding-top: 20px;
    gap: 9px;
  }
  @media(max-width: ${mobileMiniRes}) {
    width: 100%;
    border-top-right-radius: 4px;
    gap: 4px;
    flex-direction: column;
  }
`;

export const DateSect = styled.div`
  width: calc(50% - 15px);
  @media(max-width: ${mobileSmallRes}) {
    width: calc(50% - 10px);
  }
  @media(max-width: ${mobileMiniRes}) {
    width: 100%;
  }
`;

export const RightPartCont = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  -webkit-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  -moz-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  border-radius: 2px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  max-width: 100%;
  overflow: hidden;
  @media(max-width: ${metricsTimeRes1}) {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const IconCont = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${Color('#525252').lighten(1.15).toString()};
  border-radius: 3px;
  width: 100%;
  height: 45px;
  transition: background ease-out 200ms;
`;

export const Icon0 = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7.5px 13px;
  padding-bottom: 8px;
  height: 100%;
  color: ${Color('#525252').lighten(0.7).toString()};
  width: max-content;
  opacity: ${({ isDisabled }) => isDisabled && 0.125};
  cursor: pointer;
  &:hover {
    background: ${Color('lightgrey').lighten(0.1).toString()};
  }
`;

export const Icon = styled(Icon0)`
`;

export const Icon2 = styled(Icon0)`
  border-right: 1px solid ${Color('#525252').lighten(1.15).toString()};
`;

export const Date = styled.div`
  font-size: 15px;
  font-weight: 500;
  flex: 1;
  padding: 7px 10px;
  padding-left: 7px;
  color: ${Color('#525252').lighten(0.1).toString()};
`;

export const Label = styled.div`
  font-size: 10px;
  width: max-content;
  transform: translateY(-2px);
  font-weight: 300;
  height: 19px;
  color: ${Color('grey').lighten(0.2).toString()};
  @media(max-width: ${metricsTimeRes3}) {
    font-size: 9px;
  }
`;

export const Label2 = styled.div`
  margin-top: -6px;
  font-size: 13px;
  line-height: 1.1;
  @media(max-width: ${metricsTimeRes3}) {
    font-size: 10.5px;
  }
`;

export const Icon1 = styled(Icon0)`
  border-right: 1px solid ${Color('#525252').lighten(1.15).toString()};
  border-left: 1px solid ${Color('#525252').lighten(1.15).toString()};
`;

export const Hyphen = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: ${Color('#525252').lighten(1.1).toString()};
  @media(max-width: ${mobileMiniRes}) {
    display: none;
  }
`;

export const Hyphen1 = styled.div`
  font-size: 5px;
  font-weight: 400;
  color: ${Color('#525252').lighten(1.1).toString()};
  padding: 6px 0px;
  display: none;
  @media(max-width: ${mobileMiniRes}) {
    display: block;
  }
`;

export const Result = styled.div`
  flex: 1;
  padding: 10px 12px;
  font-size: 11px;
  letter-spacing: 0.15px;
  color: ${Color('#525252').lighten(0.4).toString()};
  display: flex;
  align-items: flex-end;
  text-align: right;
  position: relative;
  overflow: hidden;
  @media(max-width: ${metricsTimeRes1}) {
    padding: 15px 15px;
    padding-top: 0px;
  }
`;

export const FlexPart = styled.div`
  max-width: calc(100% - 120px);
  font-weight: 300;
  display: block;
  margin-left: auto;
`;

export const Result1 = styled.div`
  font-weight: 400;
  margin: 0px 0px;
  margin-top: 4.5px;
  border: 1px solid ${Color('#525252').lighten(0.85).toString()};
  color: 1px solid ${Color('#525252').lighten(0.85).toString()};
  padding: 9.5px 14px;
  border-radius: 2px;
  width: max-content;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin-left: auto;
  @media(max-width: ${metricsTimeRes1}) {
    max-width: calc(100% - 100px - 15px);
  }
`;

export const AbsPart = styled.div`
  position: absolute;
  left: 15px;
  top: 13px;
  @media(max-width: ${metricsTimeRes1}) {
    top: 4px;
    bottom: 13px;
  }
`;

export const MetricsButton = styled.div`
  padding: 9px 16px;
  font-size: 13px;
  width: max-content;
  border-radius: 4px;
  background: ${Color(lFColor0).darken(0.1).toString()};
  color: white;
  font-weight: 500;
  height: max-content;
  cursor: pointer;
  margin-right: auto;
  transform: translateY(0px);
  &:hover {
    background: ${orange};
    color: ${Color(orange).darken(0.6).toString()};
  }
`;

export const MetricsButton1 = styled(MetricsButton)<{ smallPad?: true }>`
  transform: translateY(0px);
  background: ${Color(topBarColor2).lighten(2.45).toString()};
  color: ${Color(topBarColor2).lighten(0.8).toString()};
  margin-left: ${({ smallPad }) => smallPad ? '0px' : '15px'};
  margin-top: 16px;
`;

const totalBg = Color('#eadaf0').lighten(0.05).toString();
export const TotalCont = styled.div`
  border: 0;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  -webkit-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  -moz-box-shadow: 0px 0px 1px 0.5px ${Color('lightgrey').darken(0.1).toString()};
  border-bottom: 1px solid ${Color(borderColor).lighten(0.65).toString()};
  border-top: 1px solid ${Color(borderColor).lighten(0.65).toString()};
  background: ${totalBg};
`;

export const TotalTextPart = styled.div`
  font-weight: 700;
  width: max-content;
  display: flex;
  align-items: center;
  color: ${Color('#525252').lighten(0.4).toString()};
  border-right: 1px solid ${Color('#eadaf0').darken(0.025).toString()};
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
`;

export const TotalBlockPart = styled.div`
  margin-left: auto;
  text-align: right;
  height: 100%;
`;

export const TotalText = styled.div`
  font-size: 11px;
  padding-right: 15px;
  padding-left: 35px;
  height: 100%;
  width: max-content;
  display: flex;
  margin-left: auto;
  align-items: center;
  @media(max-width: ${mobileMiniRes1}) {
    padding-left: 0px;
  }
`;

export const MetricName = styled.div`
  font-weight: 400;
  height: 40%;
  font-size: 9.5px;
  color: ${Color(totalBg).darken(0.25).toString()};
  background: ${Color(totalBg).darken(0.07).toString()};
  max-width: 100px;
  line-height: 1.1;
  padding: 2px 9px;
  padding-right: 15px;
  padding-top: 5px;
  border-top-left-radius: 2px;
  @media(max-width: ${mobileRes2}) {
    margin-top: 0.5px;
  }
  @media(max-width: ${metricsTimeRes3}) {
    font-size: 9px;
  }
`;

const totalColor1 = Color('#525252').lighten(0.1).toString();

export const Total = styled.div<{ isNegative: boolean | null }>`
  color: ${({ isNegative }) => isNegative === null ? totalColor1 : isNegative ? redColor : cashColor};
  font-weight: 700;
  font-size: 21px;
  letter-spacing: 0.35px;
  height: max-content;
  padding: 12px 20px;
  padding-left: 15px;
  display: block;
  background: white;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  width: max-content;
  min-width: 21vw;
  max-width: calc(100% - 87px);
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-right: 1px solid ${Color('#eadaf0').darken(0.025).toString()};
  border-left: 1px solid ${Color('#eadaf0').darken(0.025).toString()};
  height: 100%;
  display: flex;
  align-items: center;
  @media(max-width: ${metricsTimeRes4}) {
    padding-right: 10px;
  }
`;

export const ExpandIcon = styled.div`
  color: ${Color(totalBg).darken(0.25).toString()};
  border: 1px solid ${Color(totalBg).darken(0.25).toString()};
  display: flex;
  padding: 0.5px 1.5px;
  border-radius: 3px;
  margin-left: 15px;
  height: max-content;
  margin-left: auto;
  cursor: pointer;
  &:hover {
    color: ${orange};
    border-color: ${orange};
  }
`;
