import React from 'react';
import * as S from './Layout.styled';
import { useAppSelector } from 'redux/hooks';
import { selectPermission } from 'redux/global';
import AdminButtons from './components/AdminButtons';
import Navigation from 'pages/Navigation/Navigation';
import { usePath } from 'pages/hooks';

const Layout = ({ children }:{ children: any }) => {
  const permission = useAppSelector(selectPermission);
  const { isHomePath } = usePath();

  return (
    <S.Container>
      {
        (permission !== null && !isHomePath) && (
          <S.LeftPanel id="sidebar">
            <AdminButtons />
          </S.LeftPanel>
        )
      }
      <S.RightPanel>
        <Navigation>
          {children}
        </Navigation>
      </S.RightPanel>
    </S.Container>
  );
};

export default Layout;
