import styled from 'styled-components';

export const Bg = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99;
  background: black;
  opacity: 0;
  display: none;
  transition: all ease-in 250ms;
`;

export const Container = styled.div`
  position: fixed;
  top: 0px;
  left: -200%;
  width: max-content;
  height: 100svh;
  z-index: 100;
  display: flex;
`;
