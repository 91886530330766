import styled from 'styled-components';
import { topBarColor } from 'pages/styles';

export const Container = styled.div`
  width: 85vw;
  max-width: 340px;
  padding: 20px 15px;
  padding-bottom: 15px;
  background: white;
  border-radius: 4px;
`;

export const MainLabel = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const RoomArea = styled.div`
  background: ${topBarColor};
  width: calc(100% + 30px);
  margin-left: -15px;
  padding: 5px 15px;
  margin-bottom: 5px;
`;

export const MainLabel2 = styled.div`
  font-size: 30px;
  font-weight: 500;
  color: lightgrey;
`;

export const Info = styled.div`
  font-size: 13.5px;
  margin-top: 7px;
  color: #525252;
`;

export const Info1 = styled.div`
  font-size: 13.5px;
  margin-top: 60px;
  color: #525252;
`;

export const Button1 = styled.div`
  color: #eb364b;
  margin-top: 10px;
  width: max-content;
  width: 100%;
  display: flex;
  gap: 15px;
`;
