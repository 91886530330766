import styled from 'styled-components';
import Color from 'color';
import { borderColor2, topBarColor } from 'pages/styles';
import { redColor } from '../modals/RoomOptions';

export const bDAvailableColor = Color(topBarColor).lighten(0.875).toString();
export const bDAvailableColor2 = Color(topBarColor).darken(0.8).toString();
const bDRes1 = '975px';
const orange = Color('#d6a615').darken(0).toString();
const greenColor = '#56d651';

export const Container = styled.div<{ height: string }>`
  border-bottom: ${({ height }) => height === '0px' && `1px solid ${borderColor2}`};
  background: ${Color('lightgrey').lighten(0.175).toString()};
  flex: 1;
  display: flex;
  max-height: ${({ height }) => height};
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  transition: all ease-out ${({ height }) => height === '0px' ? '150ms' : '200ms'};
  z-index: 2;
`;

export const Cont1 = styled.div`
  flex: 1;
  display: flex;
`;

export const InfoCont = styled.div`
  background: ${bDAvailableColor};
  border: 1px dashed ${bDAvailableColor2};
  width: 900px;
  border-left: none;
  height: 100%;
  min-width: max-content;
  display: flex;
  border-radius: 2px;
  @media(max-width: ${bDRes1}) {
    flex: 1;
    width: 100%;
  }
`;

export const BlockCont0 = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  width: max-content;
  margin: auto 18px 14px 14px;
`;

export const RoomSelected = styled.div`
  font-size: 12.5px;
  color: #525252;
  font-weight: 500;
`;

export const FlexCont0 = styled.div`
  display: flex;
  gap: 7px;
  margin-top: 4px;
`;

export const Option = styled.div`
  font-size: 10px;
  font-weight: 700;
  border-radius: 3px;
  background: ${Color(bDAvailableColor).darken(0.075).toString()};
  color: ${Color(bDAvailableColor).darken(0.45).toString()};
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background: ${orange};
    color: ${Color(orange).darken(0.6).toString()};
  }
`;

export const FlexCont = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 20px;
  margin-left: 15px;
  margin: auto;
  max-width: max-content;
`;

export const IconCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 3px;
  background: ${Color(bDAvailableColor).darken(0.075).toString()};
  color: ${Color(bDAvailableColor).darken(0.55).toString()};
  border: 1px solid ${Color(bDAvailableColor).darken(0.15).toString()};
  cursor: pointer;
  &:hover {
    background: ${orange};
    color: ${Color(orange).darken(0.6).toString()};
    border: 0;
  }
`;

export const BlockCont = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: max-content;
`;

export const BlockCont1 = styled.div`
  margin: auto 0 auto auto;
`;

export const CheckIn = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: grey;
  margin-left: auto;
  width: max-content;
  margin-top: 1px;
`;

export const Date = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${Color('#525252').darken(0.15).toString()};
  margin-left: auto;
  width: max-content;
  margin-top: -1px;
`;

export const Time = styled(Date)`
  font-size: 15px;
  margin-left: auto;
  width: max-content;
  margin-top: -3px;
  font-weight: 500;
`;

export const CheckOut = styled(CheckIn)`
  margin-left: 0;
  margin-right: auto;
`;

export const Date1 = styled(Date)`
  margin-left: 0;
  margin-right: auto;
`;

export const Time1 = styled(Time)`
  margin-left: 0;
  margin-right: auto;
`;

export const ButtonCont = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconCont1 = styled(IconCont)`
  width: 17px;
  height: 17px;
  background: inherit;
  margin-left: auto;
  margin-right: 6px;
  margin-top: 6px;
  border: 1px solid ${Color(bDAvailableColor).darken(0.45).toString()};
  color: ${Color(bDAvailableColor).darken(0.45).toString()};
  &:hover {
    background: inherit;
    color: ${redColor};
    border-color: ${redColor};
  }
`;

export const ButtonDiv = styled.div`
  margin: auto 14px 14px 25px;
  width: max-content;
`;

export const Nights = styled.div`
  color: ${Color(topBarColor).darken(0.4).toString()};
  font-size: 13px;
  font-weight: 600;
  width: max-content;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 120px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const ArrowCont = styled.div`
  color: ${Color(topBarColor).darken(0.4).toString()};
  height: 20px;
  width: 20px;
`;

export const BookButton = styled.div`
  padding: 7px 14px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  color: ${bDAvailableColor2};
  border: 1px solid ${bDAvailableColor2};
  width: max-content;
  &:hover {
    background: ${greenColor};
    border-color: ${greenColor};
    color: ${Color(greenColor).darken(0.6).toString()};
  }
`;

export const Empty = styled.div<{ height: string }>`
  border-bottom: ${({ height }) => height !== '0px' && '1px solid #999999'};
  border-top: ${({ height }) => height !== '0px' && '1px solid #999999'};
  flex: 1;
  @media(max-width: ${bDRes1}) {
    display: none;
  }
`;
