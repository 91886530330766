import Color from 'color';
import {
  chartRes2, chartRes3, pieHeight1, pieHeight2
} from 'pages/Metrics/components/Charts.styled';
import { redColor2 } from 'pages/styles';
import styled from 'styled-components';

export const purpleColor = '#8884d8';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  position: relative;
  @media(max-width: ${chartRes2}) {
    width: ${pieHeight1};
    height: ${pieHeight1};
    max-width: calc(100vw - 28px);
    max-height: calc(100vw - 28px);
  }
  @media(max-width: ${chartRes3}) {
    width: ${pieHeight2};
    height: ${pieHeight2};
  }
`;

export const EmptyPie = styled.div`
  width: 50%;
  height: 50%;
  border-radius: 50%;
  border: 3px dashed ${Color('lightgrey').lighten(0.1).toString()};
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
`;

export const NegativePie = styled.div`
  width: calc(45.5% + 0px);
  height: calc(45.5% + 0px);
  border-radius: 50%;
  border: 3px solid ${Color(redColor2).lighten(0.075).toString()};
  background: ${Color(purpleColor).lighten(0.25).toString()};
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  @media(max-width: ${chartRes2}) {
    width: 65.5%;
    height: 65.5%;
  }
`;
