import React from 'react';
import * as S from './Logo.styled';
import { LodgeFirst } from 'pages/components/LFHeader/LFHeader';

const Logo = function Logo({ width }:{ width?: string }) {
  return (
    <S.Container width={width}>
      <LodgeFirst />
    </S.Container>
  );
};

export default Logo;
