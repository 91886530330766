import { cashColor, redColor } from 'pages/Logs/components/LogTable.styled';
import styled from 'styled-components';

const graphRes1 = '500px';
export const Container = styled.div`
  background: white;
  border: 1px solid #525252;
  border-radius: 3px;
  padding: 14.5px 20px;
  color: #525252;
  gap: 7px;
  width: max-content;
  max-width: 90vw;
  @media(max-width: ${graphRes1}) {
    padding: 10.5px 10px;
  }
`;

export const FlexCont = styled.div<{ fill?: string }>`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${({ fill }) => fill && fill};
`;

export const Value = styled.div<{ flowType: string }>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ flowType }) => flowType === '-' ? redColor
    : flowType === 'count' ? '#525252' : cashColor};
  @media(max-width: ${graphRes1}) {
    font-size: 11px;
  }
`;

export const Name = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: #525252;
  transform: translateY(0px);
  @media(max-width: ${graphRes1}) {
    font-size: 12px;
  }
`;

export const Bold = styled.div`
  font-weight: 700;
  font-size: 17px;
  @media(max-width: ${graphRes1}) {
    font-size: 14px;
  }
`;

export const AreaChartItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 35px;
`;

export const Value2 = styled(Value)`
  font-size: 13px;
  display: block;
  @media(max-width: ${graphRes1}) {
    font-size: 10px;
  }
`;

export const FlexCont2 = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 15px;
`;

export const Bold2 = styled.div`
  font-weight: 700;
  font-size: 13px;
  @media(max-width: ${graphRes1}) {
    font-size: 10px;
  }
`;

export const Name4 = styled.div`
  margin-bottom: 18px;
  font-weight: 500;
`;

export const Value3 = styled(Value)<{ fill: string }>`
  margin-top: 10px;
  color: ${({ fill }) => fill && fill};
  gap: 10px;
`;

export const Value4 = styled(Value)`
  margin-top: 13px;
  gap: 7px;
`;

export const Circle = styled.div<{ fill: string }>`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: ${({ fill }) => fill && fill};
`;

export const Name3 = styled.div<{ fill: string }>`
  font-weight: 100;
  transform: translateY(0px);
  color: ${({ fill }) => fill && fill};
  font-weight: 200;
  @media(max-width: ${graphRes1}) {
    font-size: 11px;
  }
`;

export const Name5 = styled.div<{ fill: string }>`
  font-size: 13px;
  color: ${({ fill }) => fill && fill};
  font-weight: 200;
  @media(max-width: ${graphRes1}) {
    font-size: 10px;
  }
`;

export const Name6 = styled.div`
  font-size: 14px;
  color: #525252;
  font-weight: 400;
  @media(max-width: ${graphRes1}) {
    font-size: 11px;
  }
`;

export const Bold3 = styled.div`
  font-weight: 700;
  font-size: 16px;
  @media(max-width: ${graphRes1}) {
    font-size: 13px;
  }
`;
