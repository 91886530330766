import React from 'react';
import * as S from './EscapeButton.styled';
import Close from '@mui/icons-material/Close';

const EscapeButton = function EscapeButton({
  onClick,
  isRedBg,
  isNoBorder,
  isNoBg,
} : {
  onClick?: Function
  isRedBg?: boolean
  isNoBorder?: boolean
  isNoBg?: boolean
}) {
  return (
    <S.Container
      onClick={() => onClick?.()}
      isRedBg={isRedBg || false}
      isNoBorder={isNoBorder || false}
      isNoBg={isNoBg || false}
    >
      <S.Cont2><Close fontSize="medium" /></S.Cont2>
    </S.Container>
  );
};

export default EscapeButton;
