import { borderColor } from 'pages/styles';
import styled from 'styled-components';

export const Container = styled.div<{ isRedBg: boolean, isNoBorder: boolean, isNoBg: boolean }>`
  position: absolute;
  top: 0px;
  right: 0px;
  border: 1px solid ${({ isRedBg }) => isRedBg ? 'inherit' : borderColor};
  border: ${({ isNoBorder }) => isNoBorder && 'none'};
  padding: 10px;
  border-right: none;
  border-top: none;
  background: inherit;
  background: ${({ isNoBg }) => isNoBg && 'none'};
  border-bottom-left-radius: 5px;
  border-top-right-radius: 3px;
  color: ${({ isRedBg }) => isRedBg ? 'white' : '#525252'};
  cursor: pointer;
  &:hover{
    border-color: ${({ isRedBg }) => !isRedBg && '#eb364b'};
    color: ${({ isRedBg }) => !isRedBg && '#eb364b'};
    background: ${({ isRedBg }) => isRedBg && '#eb364b'};
  }
`;

export const Cont2 = styled.div`
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
