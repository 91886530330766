import styled from 'styled-components';
import Color from 'color';

export const headerColor = '#212121';
export const textColor2 = '#ababab';
export const textColor = '#ababab';

const mobileRes = '1010px';
export const FooterContainer = styled.footer`
  padding: 4.5em 0px;
  padding-bottom: 12em;
  width: 100%;
  background: ${Color('#7914cc').darken(0.85).toString()};
  color: ${Color(textColor2).lighten(0).toString()};
  position: relative;
  z-index: 12;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
`;

export const Me = styled.div`
  font-size: 11px;
  color: lightgrey;
  color: ${Color(textColor2).darken(0.2).toString()};
  padding-bottom: 3px;
`;

export const FooterSection1 = styled.div<any>`
  text-align: left;
  display: block;
  text-align: center;
  width: max-content;
  overflow: hidden;
  margin: auto;
`;

export const JoinUs2 = styled.div`
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: center;
`;

export const Copyright = styled.div`
  font-size: 13px;
  min-width: 0;
  color: ${textColor};
  display: block;
  font-weight: 400;
  width: max-content;
  max-width: 90%;
  text-align: center;
  bottom: 35px;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  @media(max-width: ${mobileRes}) {
    position: relative;
    margin: auto;
    right: 0;
    transform: translateX(0%);
    margin-top: 45px;
    width: max-content;
  }
`;
