import React, { useState } from 'react';
import * as S from './TablesAdded.styled';
import * as X from '../ImportTypes/TableSelector.styled';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import Table from './Table1';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { LogSheetEntry, deleteBranchFile, selectBranchFiles } from 'pages/Logs/redux';
import More from '@mui/icons-material/MoreVert';
import Close from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { useLogs } from 'pages/Logs/hooks';
import { useSocketEmit } from 'pages/Navigation/hooks';

const TablesAdded = ({
  onExit,
  selectedBranch,
  setIsLoading,
} : {
  onExit: Function,
  selectedBranch: string,
  setIsLoading: Function
}) => {
  const [isLoading, setIsLoading1] = useState<boolean>(false);
  const [selectedSheet, setSelectedSheet] = useState<LogSheetEntry[]>();
  const branchFiles = useAppSelector(selectBranchFiles);
  const thisBranchFiles = branchFiles?.[selectedBranch] || {};
  const [isShowClose, setIsShowClose] = useState<boolean[]>(
    Object.entries(thisBranchFiles).map(() => false)
  );
  const dispatch = useAppDispatch();
  const { deleteLocalBranchFile } = useLogs();
  const socketEmit = useSocketEmit();

  const toggleShowClose = (ind: number, isShowCloseHere: boolean) => {
    isShowClose.forEach((b, i) => {
      const iconCont1 = document.getElementById(`iconCont1${i}`);
      if (iconCont1 && ind !== i) {
        iconCont1.style.width = '0px';
      }
    });

    const iconCont1 = document.getElementById(`iconCont1${ind}`);
    if (iconCont1) {
      if (isShowCloseHere) {
        iconCont1.style.width = '50px';
      } else {
        iconCont1.style.width = '0px';
      }
    }
  };

  const deleteBranchFile1 = async (deleteKey: string) => {
    setIsLoading(true);
    setIsLoading1(true);

    const res = await dispatch(deleteBranchFile(selectedBranch, deleteKey));

    setIsLoading(false);
    setIsLoading1(false);
    if (res.status === 'success') {
      deleteLocalBranchFile(selectedBranch, deleteKey);
      socketEmit('update_branchFiles', {});
      toast('Successfully deleted Table', { type: 'success' });
      return;
    }
    toast(res.data, { type: 'error' });
  };

  return (
    <>
      {
        isLoading ? (null) : (
          <S.Container>
            <EscapeButton onClick={() => onExit()} isNoBg isNoBorder />
            <X.BorderContainer>
              <X.FlexCont>
                <X.SelectTable>
                  <X.Header>Added tables</X.Header>
                  <S.RowsContainer>
                    {
                      Object.entries(thisBranchFiles).map(([k, v], i) => {
                        return (
                          <S.TableCont key={`branchF_${k}`}>
                            <S.Table
                              onClick={() => setSelectedSheet(v)}
                            >
                              {k}
                            </S.Table>
                            <S.IconCont1 id={`iconCont1${i}`} onClick={() => deleteBranchFile1(k)}>
                              <Close fontSize="small" />
                            </S.IconCont1>
                            <S.IconCont
                              onClick={() => {
                                const thisIsShowClose = isShowClose[i];
                                const newIsShowClose = isShowClose.map(() => false);
                                newIsShowClose[i] = !thisIsShowClose;
                                setIsShowClose(newIsShowClose);
                                toggleShowClose(i, newIsShowClose[i]);
                              }}
                            >
                              <More fontSize="small" />
                            </S.IconCont>
                          </S.TableCont>
                        );
                      })
                    }
                  </S.RowsContainer>
                </X.SelectTable>
              </X.FlexCont>
              <X.TablePreview>
                <X.Header2>
                  Table preview
                </X.Header2>
                <Table selectedSheet={selectedSheet || []} />
              </X.TablePreview>
            </X.BorderContainer>
          </S.Container>
        )
        }
    </>
  );
};

export default TablesAdded;
