import React from 'react';
import * as S from './Table.styled';
import TableBody from './TableBody';
import { ImportedData } from './utils';

const Table = function Table({
  importedData,
} : {
  importedData: ImportedData,
}) {
  const { headers } = importedData;

  return (
    <S.Container id="tableCont">
      <S.TableDiv>
        <S.Table>
          <thead>
            <tr>
              { (headers || []).map((key) => (
                <S.TH key={`tmptableheader_${key}`}>
                  <S.THText>{key}</S.THText>
                </S.TH>
              ))}
            </tr>
          </thead>
          <TableBody importedData={importedData} />
        </S.Table>
      </S.TableDiv>
    </S.Container>
  );
};

export default Table;
