import { callEndpoint } from 'redux/global';
import { IResponse } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { Log } from './hooks';
import { Metric } from 'pages/redux';

export type LogSheetEntry = {
  id: number,
  message: string,
  value: number,
  date: string,
  updatedBy: string,
  updatedAsOf: string,
  sheetName: string,
}

export type BranchFile = Record<string, LogSheetEntry[]>;
export type BranchFiles = Record<string, BranchFile>

export const logTypes0 = ['Desk reservation', 'Reservation cancelled', 'Room added', 'Staff logged in',
  'Settings change', 'Online reservation', 'Room change', 'Reservation change',
  'Staff added', 'Staff removed', 'Staff change', 'Rate Plan change', 'Room deleted', 'Audit deleted',
  'Audit change', 'Walk in', 'Meal delivered', 'Key card access', 'Check in', 'Check out'] as const;

export const logKeys0 = ['Hospitality', 'Branches', 'Actions', 'Systems', 'Show all'];

const defaultChecked = JSON.stringify([...logTypes0, ...logKeys0]);

export interface LogsState {
  logs: Log[] | null,
  nonBranchLogs: Log[] | null,
  singleLogTime: Metric | null,
  checked: string[],
  recentLog: string,
  branchFiles: BranchFiles | null,
  logSearch: string,
  isLoadingLogs: boolean
}

const initialState: LogsState = {
  logs: null,
  nonBranchLogs: null,
  singleLogTime: null,
  checked: JSON.parse(localStorage.getItem('logs_checked') || defaultChecked),
  recentLog: '',
  branchFiles: null,
  logSearch: '',
  isLoadingLogs: true
};

export const counterSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    setLogs: (state, action: PayloadAction<Log[] | null>) => {
      state.logs = action.payload;
    },
    setSingleLogTime: (state, action: PayloadAction<Metric | null>) => {
      state.singleLogTime = action.payload;
    },
    setLogsChecked: (state, action: PayloadAction<string[]>) => {
      state.checked = action.payload;
      localStorage.setItem('logs_checked', JSON.stringify(action.payload));
    },
    setRecentLog: (state, action: PayloadAction<string>) => {
      state.recentLog = action.payload;
    },
    setBranchFiles: (state, action: PayloadAction<BranchFiles | null>) => {
      state.branchFiles = action.payload;
    },
    setNonBranchLogs: (state, action: PayloadAction<Log[] | null>) => {
      state.nonBranchLogs = action.payload;
    },
    setLogSearch: (state, action: PayloadAction<string>) => {
      state.logSearch = action.payload;
    },
    setIsLoadingLogs: (state, action: PayloadAction<boolean>) => {
      state.isLoadingLogs = action.payload;
    }
  },
});

export const {
  setLogs, setSingleLogTime, setLogsChecked, setRecentLog,
  setBranchFiles,
  setNonBranchLogs,
  setLogSearch,
  setIsLoadingLogs
} = counterSlice.actions;

export const selectLogs = (state: RootState) => state.logs.logs;
export const selectNonBranchLogs = (state: RootState) => state.logs.nonBranchLogs;
export const selectSingleLogTime = (state: RootState) => state.logs.singleLogTime;
export const selectLogsChecked = (state: RootState) => state.logs.checked;
export const selectRecentLog = (state: RootState) => state.logs.recentLog;
export const selectBranchFiles = (state: RootState) => state.logs.branchFiles;
export const selectLogSearch = (state: RootState) => state.logs.logSearch;
export const selectIsLoadingLogs = (state: RootState) => state.logs.isLoadingLogs;

export default counterSlice.reducer;

export const getLogs = () => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'getlogs',
      method: 'GET',
    }),
  );
  return res;
};

type AddBranchLog = {
  type: string,
  value: string,
  message: string,
  date: Date,
}

export const addBranchLog = (logEntry: AddBranchLog) => async (dispatch: Function)
  : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'addbranchlog',
      method: 'POST',
      body: { ...logEntry }
    }),
  );
  return res;
};

type DeleteLog = {
  dId: string,
  type: string,
  value: string,
}

export const deleteLog = (logToDelete: DeleteLog) => async (dispatch: Function)
  : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'deletelog',
      method: 'POST',
      body: { ...logToDelete }
    }),
  );
  return res;
};

type EditLog = {
  editId: string,
  addLogMessage: string,
  value: string,
}

export const editLog = (logToEdit: EditLog) => async (dispatch: Function)
  : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'editlog',
      method: 'POST',
      body: { ...logToEdit }
    }),
  );
  return res;
};

export const addLog = (log: Omit<Omit<Log, 'id'>, 'date'>) => async (dispatch: Function)
  : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'addlog',
      method: 'POST',
      body: { log }
    }),
  );
  return res;
};

export const getBranchFiles = () => async (dispatch: Function)
  : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'getbranchfiles',
      method: 'GET',
    }),
  );
  return res;
};

export const addBranchFile = (branchFile: BranchFiles) => async (dispatch: Function)
  : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'addbranchfile',
      method: 'POST',
      body: { branchFile }
    }),
  );
  return res;
};

export const deleteBranchFile = (branch: string, sheetName: string) => async (dispatch: Function)
  : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'deletebranchfile',
      method: 'POST',
      body: { branch, sheetName }
    }),
  );
  return res;
};

export const deleteBranchFiles = (branch: string) => async (dispatch: Function)
  : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'deletebranchfiles',
      method: 'POST',
      body: { branch }
    }),
  );
  return res;
};

type DeleteLogType = {
  type: string,
}

export const deleteLogType = (logTypeToDelete: DeleteLogType) => async (dispatch: Function)
  : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'deletelogtype',
      method: 'POST',
      body: { ...logTypeToDelete }
    }),
  );
  return res;
};
