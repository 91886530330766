import React from 'react';
import * as S from './TmpPanel.styled';
import { moveTmpPanel } from 'pages/Navigation/utils';

const TmpPanel = ({ component, id }:{ component: React.JSX.Element, id: string }) => {
  return (
    <>
      <S.Bg
        id={`${id}_bg`}
        onClick={() => {
          moveTmpPanel(id);
        }}
      />
      <S.Container id={id}>
        {component}
      </S.Container>
    </>
  );
};

export default TmpPanel;
