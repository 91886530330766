import React, { useState } from 'react';
import * as S from './DeleteRoom.styled';
import Loader from 'pages/components/Loader/Loader';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  deleteRooms, selectRooms,
} from '../redux';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { ModalParts } from 'pages/redux';
import { useLocalRoomUpdate } from '../hooks';
import { useSocketEmit } from 'pages/Navigation/hooks';
import RoomOptions from './RoomOptions';
import { useSettings } from 'pages/Settings/hooks';
import { useCX } from 'pages/hooks';

const DeleteRoom = function DeleteRoom(
  {
    onExit, selectedRoomId, setModal,
  }:
  // eslint-disable-next-line no-unused-vars
  { onExit: Function, selectedRoomId: string, setModal: (modalParts: ModalParts | null) => void },
) {
  const [loading, setLoading] = useState<boolean>(false);
  const rooms = useAppSelector(selectRooms);
  const room = (rooms || []).find((r) => r.id === selectedRoomId);
  const dispatch = useAppDispatch();
  const { deleteLocalRooms } = useLocalRoomUpdate();
  const socketEmit = useSocketEmit();
  const { roomTypes, getFullAvailability } = useSettings();
  const { COUpdateRoomType, COUpdateAvailability } = useCX();

  const deleteRoomHere = async () => {
    setLoading(true);

    const id = room?.id || '';

    const roomType = roomTypes.find((t) => t.id === room?.roomTypeId);
    const roomCount = (rooms || []).filter((r) => r.id !== room?.id).reduce(
      (a, b) => a + (b.roomTypeId === roomType?.id ? 1 : 0),
      0
    ) || 1;

    if (roomType) {
      const updateRoomTypeCOData = {
        roomCount,
        roomType
      };

      const res = await COUpdateRoomType?.(updateRoomTypeCOData);

      if (res && res.status !== 'success') {
        setLoading(false);
        toast(res.data, { type: 'error' });
        return;
      }

      const availabilityCO = getFullAvailability(roomType, undefined, id);

      const res3 = await COUpdateAvailability?.(availabilityCO);

      if (res3 && res3.status !== 'success') {
        setLoading(false);
        toast(res3.data, { type: 'error' });
        return;
      }
    }

    const res = await dispatch(deleteRooms([id.toString() || '']));

    setLoading(false);
    if (res.status === 'success') {
      deleteLocalRooms([id.toString()]);
      socketEmit('delete_rooms', { ids: [id.toString() || ''] });
      toast('Successfully deleted room', { type: 'success' });
      onExit();
      return;
    }
    toast(res.data, { type: 'error' });
  };

  return (
    <>
      { loading ? <Loader isSticky />
        : (
          <S.Container>
            <EscapeButton onClick={() => onExit()} />
            <S.MainLabel>Delete room</S.MainLabel>
            <S.RoomArea>
              <S.MainLabel2>{room?.name}</S.MainLabel2>
            </S.RoomArea>
            <S.Info>
              Are you sure you want to delete this room?
            </S.Info>
            <S.Button1>
              <div style={{ color: '#1685ec' }}>
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{ borderWidth: '1px' }}
                  onClick={() => setModal({
                    component: (
                      <RoomOptions
                        onExit={() => setModal(null)}
                        selectedRoomId={selectedRoomId}
                        setModal={setModal}
                      />
                    )
                  })}
                >
                  Back
                </Button>
              </div>
              <Button
                variant="outlined"
                color="inherit"
                sx={{ borderWidth: '1px' }}
                onClick={() => deleteRoomHere()}
              >
                Delete
              </Button>
            </S.Button1>
          </S.Container>
        )}
    </>
  );
};

export default DeleteRoom;
