import styled from 'styled-components';

export const Container = styled.div`
  width: 90vw;
  height: max-content;
  max-width: 260px;
  padding: 20px;
  background: white;
  border-radius: 3px;
  font-family: Poppins;
`;

export const MainLabel = styled.div`
  font-size: 25px;
  margin-bottom: 20px;
  font-weight: 700;
`;

export const Label = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export const Label2 = styled.div`
  margin-top: 2px;
  font-size: 15px;
  color: #525252;
  padding-left: 5px;
`;

export const ButtonsSect = styled.div`
  margin-top: 40px;
  width: max-content;
  display: flex;
  gap: 15px;
  width: 100%;
`;

export const IconCont = styled.div<{ color: string, disabled: boolean, blocked: boolean }>`
  width: calc(50% - 7.5px);
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  color: white;
  background: ${({ color }) => color};
  display: ${({ disabled }) => disabled && 'none'};
  opacity: ${({ blocked }) => blocked && '0.2'};
  border-radius: 5px;
  cursor: ${({ blocked }) => blocked ? 'default' : 'pointer'};
  &:hover {
    background: ${({ blocked }) => !blocked && '#1685ec'};
  }
`;

export const Icon = styled.div`
`;

export const Text = styled.div`
  font-size: 14px;
  text-align: center;
  font-weight: 600;
`;
