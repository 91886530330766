import { setIsStaff } from 'pages/Homepage/redux';
import { useLocalRoomUpdate } from 'pages/Hospitality/hooks';
import { selectRooms } from 'pages/Hospitality/redux';
import { useLogs } from 'pages/Logs/hooks';
import { selectLogs, setIsLoadingLogs, setLogs } from 'pages/Logs/redux';
import {
  MetricLog, setMetricLogs
} from 'pages/Metrics/redux';
import { useUpdateSettings } from 'pages/Settings/hooks';
import { getDayEnd, getDayStart } from 'pages/components/Graphs/hooks';
import {
  HotelData, selectHotelData, selectSocket, setHotelData, setIsSocketConnected,
  setSocket
} from 'pages/redux';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectUsername, useCreds } from 'redux/global';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { store } from 'redux/store';
import * as io from 'socket.io-client';

const socket = io.connect(
  window.location.hostname === (process.env.REACT_APP_MOBILE_APP_URL || '').split(':8200').join('')
    .split('http://').join('').split('/').join('') ? process.env.REACT_APP_MOBILE_APP_URL || '' :
    (process.env.REACT_APP_API || ''),
  { transports: ['polling'] }
);

export const toggleWidget = (hide: boolean) => {
  if (hide) {
    if ((window as any).Tawk_API?.hideWidget) {
      (window as any).Tawk_API.hideWidget();
    }
  } else {
    if ((window as any).Tawk_API?.showWidget) {
      (window as any).Tawk_API.showWidget();
    }
  }
};

export const useHotel = () => {
  const hotelData = useAppSelector(selectHotelData);
  const dispatch = useAppDispatch();

  const updateHotel = (hotelData1: HotelData) => {
    if (hotelData) {
      dispatch(setHotelData({ ...hotelData, ...hotelData1 }));
    }
  };
  return { updateHotel };
};

export const useSocketEmit = () => {
  const hotelData = useAppSelector(selectHotelData);
  const socket1 = useAppSelector(selectSocket);

  const socketEmit = (type: string, data: Record<string, any>) => {
    socket1.emit(type, { roomId: `room${hotelData?.id}`, ...data });
  };

  return socketEmit;
};

export const useSocketTypeEmit = () => {
  const hotelData = useAppSelector(selectHotelData);
  const socket1 = useAppSelector(selectSocket);

  const settingsEmit = (part: Record<string, any>) => {
    socket1.emit('update_settings', { roomId: `room${hotelData?.id}`, settings: { ...part } });
  };

  return { settingsEmit };
};

export const useParentEffects = () => {
  const location = useLocation();
  const logs = useAppSelector(selectLogs);
  const dispatch = useAppDispatch();
  const hotelData = useAppSelector(selectHotelData);
  const rooms = useAppSelector(selectRooms);
  const savedUsername = useAppSelector(selectUsername);
  const { addLocalRoom, editLocalRoom, deleteLocalRooms } = useLocalRoomUpdate();
  const {
    deleteLocalLog, updateLocalLog, fetchBranchFiles, updateLocalBranch, deleteLocalBranch,
    deleteLocalLogType, logGroups
  } = useLogs();
  const { updateHotel } = useHotel();
  const { updateLocalSettings } = useUpdateSettings();
  const { logout } = useCreds();

  useEffect(() => {
    socket.on('connect_error', () => {
      dispatch(setIsSocketConnected(false));
    });

    socket.on('connect', () => {
      dispatch(setSocket(socket));
      dispatch(setIsSocketConnected(true));
    });

    socket.on('get_added_room', (room) => {
      addLocalRoom(room);
    });

    socket.on('get_edited_room', (rooms1) => {
      editLocalRoom(rooms1);
    });

    socket.on('get_deleted_rooms', (ids) => {
      deleteLocalRooms(ids);
    });

    socket.on('get_revoked_staff', (username) => {
      if (savedUsername?.replace(/\s+/g, '') === username.replace(/\s+/g, '')) {
        toast('Your access is denied', { type: 'error' });
        dispatch(setIsStaff(false));
        dispatch(logout());
      }
    });

    socket.on('get_added_log', (logEntry) => {
      if (logs && !logs.find((log) => log.id.toString() === logEntry.id.toString())) {
        const { logs: logsH } = store.getState().logs;
        const newLogs = [...(logsH || []), logEntry];
        dispatch(setLogs([...newLogs]));
      }
    });

    socket.on('get_deleted_log', (logId) => {
      if (logs) {
        deleteLocalLog(logId);
      }
    });

    socket.on('get_deleted_log_type', (type) => {
      if (logs) {
        deleteLocalLogType(type);
      }
    });

    socket.on('get_updated_log', (log) => {
      if (logs) {
        updateLocalLog(log);
      }
    });

    socket.on('get_updated_branchFiles', () => {
      if (logs) {
        fetchBranchFiles(true);
      }
    });

    socket.on('get_updated_branch', (branch) => {
      updateLocalBranch(branch);
    });

    socket.on('get_deleted_branch', (branch) => {
      deleteLocalBranch(branch);
    });

    socket.on('get_updated_hotel', (hotelData1) => {
      updateHotel(hotelData1);
    });

    socket.on('get_updated_settings', (settings) => {
      updateLocalSettings(settings);
    });

    return () => {
      socket.off();
    };
  }, [socket, rooms, logs, hotelData]);

  useEffect(() => {
    setTimeout(() => {
      toggleWidget(location.pathname.includes('logs') || location.pathname.includes('reserve'));
    }, 500);
    setTimeout(() => {
      toggleWidget(location.pathname.includes('logs') || location.pathname.includes('reserve'));
    }, 2500);
  }, []);

  useEffect(() => {
    toggleWidget(location.pathname.includes('logs') || location.pathname.includes('reserve'));
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes('logs') || location.pathname.includes('reserve')) {
      toggleWidget(true);
    }
  });

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('main_cont')?.scrollTo({ top: 0, behavior: 'smooth' });
    }, 140);
  }, [location]);

  const generateMetricLogs = useCallback(() => {
    dispatch(setIsLoadingLogs(true));

    const newLogs: MetricLog[] = [];
    const dates: Record<string, number> = {};
    (logs || [])?.forEach((l) => {
      dates[l.id] = +new Date(l.date);
    });
    [...(logs || [])]?.sort((a, b) => +new Date(a.date) - +new Date(b.date)).forEach((log) => {
      newLogs.sort((a, b) => +new Date(a.date) - +new Date(b.date));
      const ind = newLogs.findIndex((l) => dates[log.id] <= +getDayEnd(new Date(l.date))
        && dates[log.id] >= +getDayStart(new Date(l.date)) && l.type === log.type);
      const value = (+log.value ? +log.value : 0).toString();

      if (ind > -1) {
        newLogs[ind] = {
          ...newLogs[ind],
          date: log.date,
          value: (+(newLogs[ind].value) + +value).toString(),
          count: newLogs[ind].count + 1
        };
      } else {
        newLogs.push({
          ...log,
          value,
          count: 1
        });
      }
    });

    const addLogs: MetricLog[] = [];

    const addMax = 0;

    [...Array(addMax).keys()].forEach((day, p) => {
      const date = new Date(newLogs[0]?.date || new Date());
      date.setDate((date.getDate() - addMax) + day);
      Object.entries(logGroups).forEach(([, v], i) => {
        v.forEach((logType, j) => {
          addLogs.push({
            id: `auto${p}${i}${j}`,
            type: logType,
            message: '',
            date: date.toString(),
            value: logType === 'Reservation cancelled' ? (Math.floor(Math.random() * 10500000) - 10000000)
              .toString() : logType === 'Reservation change' ? (Math.floor(Math.random() * 20250000) - 10000000)
              .toString() : Math.floor(Math.random() * 10000000).toString(),
            updatedAsOf: '',
            count: Math.floor(Math.random() * 30),
          });
        });
      });
    });

    dispatch(setMetricLogs([...addLogs, ...newLogs]));
    dispatch(setIsLoadingLogs(false));
  }, [logs]);

  useEffect(() => {
    if (logs) {
      generateMetricLogs();
    }
  }, [logs]);
};
