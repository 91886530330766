import { faBuilding, faEnvelope, faPhoneFlip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as S from './Location.styled';
import { toast } from 'react-toastify';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const Location = function Location() {
  return (
    <S.MainCont>
      <S.Container>
        <S.LogoCont1>
          <S.LogoCont>
            <S.Logo><FontAwesomeIcon icon={faBuilding as IconProp} size="2x" /></S.Logo>
          </S.LogoCont>
        </S.LogoCont1>
        <S.Address>
          Plot 33, Road 1, Close 1, F.H.E. Woji, PH. Nigeria.
        </S.Address>
      </S.Container>
      <S.Container2>
        <S.LogoCont1>
          <S.LogoCont>
            <S.Logo2><FontAwesomeIcon icon={faPhoneFlip as IconProp} size="2x" /></S.Logo2>
          </S.LogoCont>
        </S.LogoCont1>
        <S.Number
          onClick={() => {
            navigator.clipboard.writeText(process.env.REACT_APP_PHONE_NUMBER || '');
            toast('Copied', { type: 'success' });
          }}
        >
          08130746341
        </S.Number>
      </S.Container2>
      <S.Container2>
        <S.LogoCont1>
          <S.LogoCont>
            <S.Logo2><FontAwesomeIcon icon={faEnvelope as IconProp} size="2x" /></S.Logo2>
          </S.LogoCont>
        </S.LogoCont1>
        <S.Number onClick={() => window.open(`mailto:${process.env.REACT_APP_EMAIL}`)}>
          ceo@lodgefirst.com
        </S.Number>
      </S.Container2>
    </S.MainCont>
  );
};

export default Location;
